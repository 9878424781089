import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DetailsListCardModel} from '../details-list-card/details-list-card.component';


@Component({
    selector: 'app-preview-dialog',
    templateUrl: './preview-dialog.component.html',
    styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<PreviewDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DetailsListCardModel[]) {
    }

    ngOnInit(): void {
    }

}
