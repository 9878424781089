import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { WalletRoutingModule } from "./wallet-routing.module";
import { TransactionHistoryComponent } from "./transaction-history/transaction-history.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { WalletMainComponent } from "./wallet-main/wallet-main.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { SharedModule } from "../../shared/shared.module";
import { MerchantWalletDetailsComponent } from "./merchant-wallet-details/merchant-wallet-details.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WalletAllTxnsComponent } from './wallet-all-txns/wallet-all-txns.component';
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReversalTxnDialogComponent } from './reversal-txn-dialog/reversal-txn-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import {MatBadgeModule} from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { ReversalIncidentListComponent } from './reversal-incident-list/reversal-incident-list.component';
import { ReportIncidentDialogComponent } from './report-incident-dialog/report-incident-dialog.component';
import { ClosedIncidentListComponent } from './closed-incident-list/closed-incident-list.component';
@NgModule({
  declarations: [
    TransactionHistoryComponent,
    InvoicesComponent,
    WalletMainComponent,
    MerchantWalletDetailsComponent,
    WalletAllTxnsComponent,
    ReversalTxnDialogComponent,
    ReversalIncidentListComponent,
    ReportIncidentDialogComponent,
    ClosedIncidentListComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    WalletRoutingModule,
    MatTabsModule,
    MatIconModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatTableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatBadgeModule
  ],
  exports: [ReversalTxnDialogComponent]
})
export class WalletModule { }