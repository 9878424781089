import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private notifyService: NotificationService,
        private router: Router, private auth: AuthServices) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const spinner = this.injector.get(NgxSpinnerService);
        spinner.show();
        if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== "" && sessionStorage.getItem('token') !== undefined) {
            request = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                }
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                }
            });
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event && event.status !== 200 && event.status !== 201) {
                        spinner.hide();
                        return throwError(event);
                    } else if (event && event.body.statusCode === 403) {
                        this.auth.logout();
                        this.router.navigateByUrl('/login');
                    } else if (event && event.body.status === false
                        && event.body.errorMessage) {
                    } else if (event && event.body && event.body.success &&
                        (event.body.success.type === false) && event.body.success.message) {
                    } else if (event && event.body && event.body.success &&
                        (event.body.success.type === false) && event.body.err.length > 0) {
                        event.body.err.forEach(error => {
                        });
                    }
                    spinner.hide();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('HttpErrorResponse error ', error);
                let message: any = '';
                if (error instanceof HttpErrorResponse) {
                    if (!navigator.onLine) {
                        message = 'Internet connection not detected. Check connection and retry';
                        this.notificationMessage(message);
                    } else {
                        message = 'Internal Server Error';
                    }
                } else {
                    message = 'Runtime error encountered';
                    this.notificationMessage(message);
                }
                spinner.hide();
                return throwError(error);
            }),
            finalize(() => {
            })
        );
    }

    apiService(error) {
        const servicelist = error.url.split('/');
        const serviceName = servicelist[servicelist.length - 1];
        return serviceName;
    }

    notificationMessage(message) {
        this.notifyService.showError(message, '');
    }


}
