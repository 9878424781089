<!-- <h5 mat-dialog-title>{{title}}</h5> -->
<!-- <div class="mb-3 title">Transaction Reference : {{data.rowData.paymentRefCode}}</div>
<div *ngIf="!isReportNeeded">
        <div mat-dialog-content class="sub-title">
            <p>{{message}}</p>
        </div>
        <div mat-dialog-actions class="d-flex align-items-center justify-content-end">
            <button class="btn btn-md red mr-4" mat-button color="warn" (click)="onDismiss()">No</button>
            <button class="btn btn-md green" mat-raised-button color="warn" (click)="onConfirm()">Yes</button>
        </div>
</div>

<div class="col-md-12" *ngIf="isReportNeeded">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Explain the issue</mat-label>
            <textarea matInput [(ngModel)]="issueText" placeholder=""></textarea>
        </mat-form-field>
    <div mat-dialog-actions class="d-flex align-items-center justify-content-end">
        <button class="btn btn-md red mr-4" mat-button color="warn" (click)="onDismiss()">Close</button>
        <button class="btn btn-md green" mat-raised-button color="warn" (click)="onSubmit()">Submit</button>
    </div>
</div> -->

<form [formGroup]="reportTransactionForm">
    <div style="display: flex;place-content: flex-end;">
        <mat-icon class="button-close" data-toggle="tooltip" data-placement="top" title="Close" style="color: #e01515;
        cursor: pointer;
        border: 1px solid red !important;
        border-radius: 15px;
        font-size: 16px;
        display: flex;
        place-content: center;
        align-items: center;" (click)="onDismiss()">close</mat-icon>
    </div>
    <div class="mb-3 title">Transaction Reference : {{data.rowData.paymentRefCode}}</div>
    <!-- <div *ngIf="!isReportNeeded">
    <mat-card>
        <div mat-dialog-content class="mt-3">
            <p class="sub-title">{{message}}</p>
        </div>
        <div mat-dialog-actions class="d-flex align-items-center justify-content-end">
            <button class="btn btn-md red mr-4" mat-button color="warn" (click)="onDismiss()">No</button>
            <button class="btn btn-md green" mat-raised-button color="warn" (click)="onConfirm()">Yes</button>
        </div>
    </mat-card>
</div> -->

    <div class="col-md-12">

        <!-- <mat-card> -->
        <mat-form-field class="col-md-12 p-0" appearance="fill">
            <mat-label>Modify Reason</mat-label>
            <mat-select formControlName="modifyReason" autocomplete="off">
                <mat-option (onSelectionChange)="onChange(adminNum)" *ngFor="let reason of modifyReasonList"
                    [value]="reason.Id">
                    {{reason.ReasonType}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field class="col-md-12 p-0" appearance="fill">
            <mat-label>Enter Admin Mobile Number</mat-label>
            <input matInput type="tel" (keyup)="validatePhoneNumber()" (keydown)="check($event)"  maxlength="12" placeholder="PhoneNumber" formControlName="PhoneNumber" required autocomplete="off">
        </mat-form-field> -->
        <!-- <div class="col-md-12 p-0 mb-2" style="float: right;">
            <button class="fixed-width" mat-raised-button color="warn" [disabled]="!requestOTP"
                (click)="onClickRequestCode()">Request OTP</button>
        </div> -->
        <mat-form-field class="col-md-12 p-0" appearance="fill">
            <mat-label>Bill Amount</mat-label>
            <input matInput type="number" maxlength="10" placeholder="Enter Bill Amount" formControlName="billAmount"
                required autocomplete="off">
        </mat-form-field>
        <mat-form-field class="col-md-12 p-0" appearance="fill">
            <mat-label>Exclusion Amount</mat-label>
            <input matInput type="number" maxlength="10" placeholder="Enter Exclusion Amount"
                formControlName="exclusionAmount" required autocomplete="off">
        </mat-form-field>
        <mat-form-field class="col-md-12 p-0" appearance="fill">
            <mat-label>Additional Comments</mat-label>
            <input matInput type="text" placeholder="Enter Additional Comments"
                formControlName="additionalComments" required autocomplete="off">
        </mat-form-field>
        <div class="col-md-12 p-0">
            <label class="small mb-1" for="imgBase64">Upload Images</label>
            <input #inputImage type="file" class="form-control"
                [ngClass]="{'validation-error': reportTransactionForm.controls.imgBase64.invalid && (reportTransactionForm.controls.imgBase64.dirty || reportTransactionForm.controls.imgBase64.touched)}"
                (change)="fileChangeEvent($event,reportTransactionForm,'uploadImg')" id="imgBase64" />
        </div>
        <!-- <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Explain the issue</mat-label>
            <textarea matInput [(ngModel)]="issueText" placeholder=""></textarea>
        </mat-form-field> -->
        <!-- </mat-card> -->
        <div mat-dialog-actions class="p-0 d-flex align-items-center justify-content-end">
            <button class="btn btn-md red mr-4" mat-button color="warn" (click)="onDismiss()">Close</button>
            <button class="btn btn-md green" mat-raised-button color="warn" (click)="onSubmit()">Submit</button>
        </div>

    </div>
</form>