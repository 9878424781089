import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  AdminAllWalletDetailsGetAPiModel,
  WalletTransactionDetailsModel,
} from '../models/wallet-transaction-details.model';
import { IWalletAllTxnsPayload } from '../wallet-all-txns/wallet-all-txns.component';

export interface WalletMerchantRequestModel {
  fromDate: string;
  toDate: string;
  restaurantId: number;
  branchId: string;
}

export interface WalletAdminRequestModel {
  fromDate: string;
  toDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  incidentList;
  closedIncidentList;
  incidentCount;
  constructor(private http: HttpClient) { }

  public getMerchantWalletTransDet(
    req: WalletMerchantRequestModel
  ): Observable<WalletTransactionDetailsModel> {
    const url = environment.baseUrl + '/api/getwallettransactiondetails';
    return this.http.post<WalletTransactionDetailsModel>(url, req);
  }

  public getAllTransactions(payload: IWalletAllTxnsPayload) {
    const url = environment.baseUrl + '/api/getwallettransactiondetails-all';
    return this.http.post<WalletTransactionDetailsModel>(url, payload);
  }

  public getAdminAllMerchantsWalletDet(
    req: WalletAdminRequestModel
  ): Observable<AdminAllWalletDetailsGetAPiModel> {
    const url = environment.baseUrl + '/api/getwalletmerchantdetails';
    return this.http.post<AdminAllWalletDetailsGetAPiModel>(url, req);
  }

  public getReversaltxnsOPT(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-transaction-send-otp';
    return this.http.post<any>(url, req);
  }

  public getIncidentList(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-gettransactionreversal-incidentlist';
    return this.http.post<any>(url, req);
  }

  public getClosedIncidentList(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-gettransactionreversal-completed-incidentlist';
    return this.http.post<any>(url, req);
  }


  public verfifyUserOTP(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-transaction-validate-otp';
    return this.http.post<any>(url, req);
  }


  public reversalTransaction(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-payreversaltransaction';
    return this.http.post<any>(url, req);
  }


  public getCoupennTypes(req) {
    const url = environment.baseUrl + '/api/admin-merchant-getcoupon';
    return this.http.post<any>(url, req);

  }

  public validateUser(req) {
    const url = environment.baseUrl + '/api/admin-appuser-validate';
    return this.http.post<any>(url, req);

  }

  public NewTransaction(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-paytransaction';
    return this.http.post<any>(url, req);
  }


  public CreateIncident(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-transactionreversal-enquiry';
    return this.http.post<any>(url, req);
  }

  public getTransactionHistory(req): Observable<any> {
    const url = environment.baseUrl + '/api/admin-getincident-transactiondetails';
    return this.http.post<any>(url, req);
  }


  public getAdminNameList(): Observable<any> {
    const url = environment.baseUrl + '/api/admin-userlist';
    return this.http.get<any>(url);
  }

  public getTransactionReason(): Observable<any> {
    const url = environment.baseUrl + '/api/transactionreasonlist';
    return this.http.get<any>(url);
  }

  


  



  






}
