// LatLng, LatLngBounds, LatLngBoundsLiteral, LatLngLiteral,
import {MapsAPILoader} from '@agm/core';
import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-mapdialog',
    templateUrl: './mapdialog.component.html',
    styleUrls: ['./mapdialog.component.scss']
})
export class MapdialogComponent implements OnInit {
    @ViewChild('search', {static: false})
    public searchElementRef: ElementRef;
    latitude = 25.276987;
    longitude = 55.296249;
    zoom = 0;
    locationChoose = false;
    address: string;
    private geoCoder;

    constructor(public dialogRef: MatDialogRef<MapdialogComponent>,
                public formbuilder: FormBuilder, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
    }

    ngOnInit() {
        this.zoom = 15;

        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder();

            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    // set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 12;
                    this.getAddress(this.latitude, this.longitude);
                });
            });
        });
    }

    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude);
            });
        }
    }

    getAddress(latitude, longitude) {

        this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.locationChoose = true;
                    this.address = results[0].formatted_address;
                }
            }
        });
    }

    onMap() {
        const obj = {
            address: this.address,
            latitude: this.latitude,
            longitude: this.longitude
        };
        this.dialogRef.close(obj);
    }

    onChoseLocation(event) {
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.locationChoose = true;
        this.getAddress(this.latitude, this.longitude);
    }

}

