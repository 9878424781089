import {Component, Input, OnInit} from '@angular/core';

export interface DetailsListCardModel {
  label: string;
  value: string | number;
  color?: string;
}

@Component({
  selector: 'app-details-list-card',
  templateUrl: './details-list-card.component.html',
  styleUrls: ['./details-list-card.component.scss']
})
export class DetailsListCardComponent implements OnInit {
  @Input() dataList: DetailsListCardModel[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
