import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApprovalHelperService } from "src/app/modules/approval/on-boarding/_services/approval-helper.service";
import { RegistrationService } from "src/app/shared/services/registration.service";
import { RestaurentMasterModel } from "src/app/shared/services/helper/reastaurent-master.model";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { Location } from "@angular/common";
import { SharedService } from "src/app/shared/services/shared.service";
import {
  ColsModel,
  ColTypeModel,
} from "src/app/shared/datatable/datatable.model";
import * as moment from "moment";
import {
  StandardDiscount,
  PremiumDiscountModel,
  LoyaltyDiscountModel,
} from "src/app/modules/merchants/models/merchant-details.model";
import {
  MerchantsDataService,
  IContractDetailsPayload,
} from "src/app/modules/merchants/services/merchants-data.service";
import { DataCardConfig } from "src/app/shared/components/data-card-split/data-card-split.component";
import { DataCardModel } from "src/app/shared/components/data-card-split/model/data-card.model";
import { RestaurantMasterService } from "src/app/shared/services/helper/restaurant-master.service";
import { MerchantsProfileHelperService } from "../../services/merchants-profile-helper.service";

@Component({
  selector: "app-contract-details",
  templateUrl: "./contract-details.component.html",
  styleUrls: ["./contract-details.component.scss"],
})
export class ContractDetailsComponent implements OnInit {
  /* Actions to do **/
  actionDataCard: DataCardModel[] = [];
  actionDataCardConfig: DataCardConfig = {
    customClass: "mr-3 mb-4 v2",
    width: "15em",
  };
  form: FormGroup;
  editMode: boolean = false;
  restaurantList: RestaurentMasterModel;
  selectedRestaurant;
  isNoRestData: boolean;
  merchantProfile: any;
  contractTypeList: any[] = [];
  contractPeriod: any[] = [
    { textId: "1", textName: "3 Months" },
    { textId: "2", textName: "6 Months" },
    { textId: "3", textName: "1 Year" },
  ];

  /* Discount details */
  cols: ColsModel[] = [
    { column: "fromAmount", title: "From", type: ColTypeModel.TEXT },
    { column: "toAmount", title: "To", type: ColTypeModel.TEXT },
    {
      column: "discount",
      title: "User Discount",
      type: ColTypeModel.PERCENTAGE,
    },
    { column: "userReward", title: "User Reward", type: ColTypeModel.TEXT },
  ];
  /* Std Discount Data */
  stdDiscountData: StandardDiscount[] = [];
  premiumDiscount: PremiumDiscountModel;
  loyaltyDiscount: LoyaltyDiscountModel;
  merchantRegistrationId: number;
  restaurantId: number;
  branchId: number;
  restListItem;
  billingTypeDesc;
  recommendationList: any = [];
  contractChargeOnTypeList: any;
  isSalesRole: boolean;
  constructor(
    public mpHelpService: MerchantsProfileHelperService,
    private aprlHelpService: ApprovalHelperService,
    public restMasterSvc: RestaurantMasterService,
    public formbuilder: FormBuilder,
    private regService: RegistrationService,
    private merchantsDataService: MerchantsDataService,
    public dialog: MatDialog,
    public shared: SharedService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.isSalesRole = JSON.parse(localStorage.getItem('user')).role === 'Sales'
    this.selectedRestaurant = this.aprlHelpService.selectedMerchant;
    if (!this.selectedRestaurant) {
      this.selectedRestaurant = this.shared.selectedMerchant;
    }
  }

  async ngOnInit() {
    this.actionDataCard = [
      { value: 556, bottomTitle: "TOTAL REVENUE" },
      { value: 105, bottomTitle: "TOTAL KEEPERZ" },
      { value: 556, bottomTitle: "TOTAL REDEMPTIONS" },
    ];
    if (this.selectedRestaurant) {
      this.initiateForm();
      const restaurantRes: any = await this.regService.getRestaurantList();
      this.billingTypeDesc = restaurantRes.data.billingTypeDesc;
      this.contractChargeOnTypeList = restaurantRes.data.ContractChargesOnType;
      this.restaurantList = restaurantRes.data;
      this.restaurantId = this.selectedRestaurant.restaurantId;
      this.branchId = this.selectedRestaurant.branchId;
      this.recommendationList = restaurantRes.data.recommendationLevelList;
      this.contractTypeList = [
        { textId: "1", textName: "Exclusive" },
        { textId: "2", textName: "Semi-Exclusive" },
        { textId: "3", textName: "Non-Exclusive" },
      ];
      this.getMerchantDetails();
    } else {
      this.location.back();
    }
  }

  getContractPeriodTextName(textId: string) {
    if (textId) {
      const filtered = this.contractPeriod.find(
        (type) => type.textId === textId
      );
      return filtered !== undefined ? filtered.textName : '';
    } else {
      return "";
    }
  }

  getMerchantDetails(): void {
    const request = {
      restaurantId: this.selectedRestaurant.restaurantId,
      branchId: this.selectedRestaurant.branchId,
    };
    this.merchantsDataService.getMerchantDetails(request).subscribe(
      (response) => {
        this.isNoRestData = false;
        if (
          !response.isError &&
          _.has(response, "data.restaurantList") &&
          response.data.restaurantList.length
        ) {
          // this.mpHelpService.loadRestaurentDetails(
          //   response.data.restaurantList[0]
          // );
          this.merchantRegistrationId = response.data.restaurantList.find(
            (res) => res.restaurantId === this.restaurantId
          ).merchantRegistrationId;
          this.shared.merchantRegistrationId = this.merchantRegistrationId;
          let restListItem;
          let premiumDiscount =
            response.data.restaurantList[0].premiumDiscount[0];
          this.shared.premiumDiscount = premiumDiscount;
          this.restListItem = restListItem = response.data.restaurantList[0];
          this.shared.setitemDetails = restListItem.itemDetails;
          this.merchantProfile = this.responseBind(restListItem);
          this.stdDiscountData = restListItem.standardDiscount;
          this.premiumDiscount = restListItem.premiumDiscount[0];
          this.loyaltyDiscount = restListItem.loyaltyDiscount[0];
          const subDetails = this.restListItem.subscriptionDetails;
          this.patchValues(subDetails);
          this.enableDisableFields();
        } else {
          console.log("No Merchant data");
          this.isNoRestData = true;
        }
      },
      () => {
        console.log("ERROR: No Merchant data");
        this.isNoRestData = true;
      }
    );
  }

  onResEditComp(event) {
    this.getMerchantDetails();
  }

  enableDisableFields() {
    [
      "exposure",
      "loyalty",
      "custacquisition",
      "revbooster",
      "inapp",
      "isVerifiedCon",
      "isVerifiedLicense",
      "isExcludeAmount",
      "paymentEnable",
      "Recommendation",
      "contractChargeType"
    ].forEach((field) => {
      if (this.editMode) {
        this.form.get(field).enable();
      } else {
        this.form.get(field).disable();
      }
    });
  }

  patchValues(subDetails) {
    this.form.patchValue({ exposure: subDetails.exposure });
    this.form.patchValue({ loyalty: subDetails.loyalty });
    this.form.patchValue({ custacquisition: subDetails.customerAquisition });
    this.form.patchValue({ revbooster: subDetails.revenueBooster });
    this.form.patchValue({ inapp: subDetails.inAppAds });
    this.form.patchValue({ isExcludeAmount: subDetails.isExcludeAmount });
    this.form.patchValue({ isVerifiedCon: subDetails.isVerifiedContract });
    this.form.patchValue({ isVerifiedLicense: subDetails.isVerfiedLicense });
    this.form.patchValue({ approveBy: subDetails.updatedBy });
    this.form.patchValue({ approvalStatus: subDetails.approvalStatus });
    this.form.patchValue({ contracttype: subDetails.contractType });
    this.form.patchValue({ billingtype: subDetails.billingType });
    this.form.patchValue({ contractStartDate: subDetails.contractStartDate });
    this.form.patchValue({ contractperiod: subDetails.contractPerriod });
    this.form.patchValue({ standard: subDetails.contractChargesStandard });
    this.form.patchValue({ premium: subDetails.contractChargesPremium });
    this.form.patchValue({ special: subDetails.contractChargesSpecial });
    this.form.patchValue({ contractChargeType: subDetails.contractChargesSpecialTypeId });
    this.form.patchValue({ approveDate: subDetails.approvedOn });
    this.form.patchValue({ customerId: subDetails.CustomerId });
    this.form.patchValue({ paymentEnable: subDetails.isOnlinePaymentEnabled });
    this.form.patchValue({ Recommendation: this.getRecommendationId(subDetails.recommendationLevel) });
  }

  onClickSave() {

    this.editMode = false;
    const {
      exposure,
      inapp,
      loyalty,
      revbooster,
      standard,
      premium,
      contractperiod,
      approveBy,
      contractStartDate,
      contracttype,
      isVerifiedCon,
      isVerifiedLicense,
      billingtype,
      custacquisition,
      approveDate,
      customerId,
      isExcludeAmount,
      paymentEnable,
      Recommendation,
      special,
      contractChargeType
    } = this.form.value;
    let approvedtime;
    if (moment(approveDate).format("YYYY-MM-DD hh:mm").split(" ")[1] === "12:00") {
      approvedtime = moment(new Date()).format("HH:mm")
    }
    else {
      approvedtime = moment(approveDate).format("HH:mm")
    }

    const selectedContractChargeType = this.getSpecialChargesType(contractChargeType);

    const payload: IContractDetailsPayload = {
      merchantRegistrationId: this.merchantRegistrationId,
      restaurantId: this.restaurantId,
      CustomerAquisition: custacquisition,
      Exposure: exposure,
      InAppAds: inapp,
      Loyalty: loyalty,
      approvedOn: moment(approveDate).format("YYYY-MM-DD") + " " + approvedtime,
      RevenueBooster: revbooster,
      approvalStatus: Boolean(approveBy) ? "Approved" : "Not Approved",
      branchId: this.branchId.toString(), // from activatedRoute
      contractChargesPremium: premium,
      contractChargesStandard: standard,
      contractPerriod: contractperiod,
      contractStartDate: moment(contractStartDate).format("YYYY-MM-DD"),
      contractType: contracttype,
      isVerifiedContract: isVerifiedCon,
      isVerifiedLicense: isVerifiedLicense,
      //isExculdeAmount : '',
      updatedBy: approveBy,
      billingType: billingtype,
      contractChargesStandardType: this.getStandardChargesType(billingtype),
      contractChargesPremiumType: this.getPremiumChargesType(billingtype),
      CustomerId: customerId,
      isExcludeAmount: isExcludeAmount,
      isOnlinePaymentEnabled: paymentEnable,
      recommendationLevel: this.getRecommendationName(Recommendation),
      contractChargesSpecial: special,
      contractChargesSpecialType: selectedContractChargeType.length > 0 ? selectedContractChargeType[0].Type : '',
      contractChargesSpecialTypeId: selectedContractChargeType.length > 0 ? selectedContractChargeType[0].Id : 0

    };
    this.merchantsDataService.saveContractDetails(payload).subscribe((data) => {
      this.enableDisableFields();
      this.getMerchantDetails();
    });
  }

  getRecommendationName(id) {
    let recommendation = this.recommendationList.filter(rec => rec.recommendationLevelId == id);
    if (recommendation.length > 0) {
      return recommendation[0].recommendationLevelName;
    }
    else {
      return "";
    }
  }

  getRecommendationId(name) {
    let recommendation = this.recommendationList.filter(rec => rec.recommendationLevelName === name);
    if (recommendation.length > 0) {
      return recommendation[0].recommendationLevelId;
    }
    else {
      return "0";
    }
  }

  getStandardChargesType(category: string): string {
    const filtered = this.billingTypeDesc.find(
      (desc) => desc.billingType === category && desc.couponType === "Standard"
    );
    if (filtered) {
      return filtered.description;
    } else {
      return "";
    }
  }

  getSpecialChargesType(contactChargeId: number): any {
    // const selectedChargeType = this.restaurantList.ContractChargesOnType.filter(contractType => {
    //   contractType.Id.toString() === contactChargeId
    // })

    return this.restaurantList.ContractChargesOnType.filter(contractType => contractType.Id === Number(contactChargeId));


  }

  getPremiumChargesType(category: string): string {
    const filtered = this.billingTypeDesc.find(
      (desc) => desc.billingType === category && desc.couponType === "Premium"
    );
    if (filtered) {
      return filtered.description;
    } else {
      return "";
    }
  }

  initiateForm(): void {
    this.form = this.formbuilder.group({
      contracttype: new FormControl({ value: "" }, Validators.required),
      billingtype: new FormControl({ value: "" }, Validators.required),
      contractStartDate: new FormControl({ value: "" }, Validators.required),
      standard: ["", Validators.required],
      premium: ["", Validators.required],
      special: ["", Validators.required],
      contractChargeType: ['0', Validators.required],
      contractperiod: ["", Validators.required],
      exposure: new FormControl(),
      loyalty: new FormControl(),
      custacquisition: new FormControl(),
      revbooster: new FormControl(),
      paymentEnable: new FormControl(),
      inapp: new FormControl(),
      approveDate: [""],
      approveBy: [""],
      customerId: [""],
      isExcludeAmount: new FormControl(),
      isVerifiedCon: new FormControl(),
      isVerifiedLicense: new FormControl(),
      Recommendation: new FormControl('0'),

    });
  }

  onClickEdit() {
    this.editMode = true;
    this.patchValues(this.restListItem.subscriptionDetails);
    this.enableDisableFields();
  }

  responseBind(resp) {
    const data = {
      active: 0,
      res_address: resp.address1,
      ambienceDetails: this.getEnvironment(resp.ambienceDetails),
      avgPerPersonCost: resp.avgPerPersonCost,
      bankDetails: resp.bankDetails,
      branchId: resp.branchId,
      businessTypes: resp.businessTypes,
      capacity: resp.capacity,
      cityId: this.getCity(resp.cityId),
      countryId: this.getCountry(resp.countryId),
      cusineDetails: this.getCusine(resp.cusineDetails),
      emailId: resp.emailId,
      facebookLink: resp.facebookLink,
      facilityDetails: this.getFacilities(resp.facilityDetails),
      isCompletedFlag: resp.isCompletedFlag,
      isServedAlcohol: resp.isServedAlcohol,
      isShishaHookah: resp.isShishaHookah,
      itemDetails: this.getItemDetails(resp.itemDetails),
      itemMenuCardDetails: resp.itemMenuCardDetails,
      kpinDetails: resp.kpinDetails,
      latitude: resp.latitude,
      locality: this.getLocality(resp.locality),
      longitude: resp.longitude,
      loyaltyDiscount: resp.loyaltyDiscount,
      merchantRegistrationId: this.merchantRegistrationId,
      mobileNumber: resp.mobileNumber,
      paymentDetails: this.getPayment(resp.paymentDetails),
      phoneNumber: resp.phoneNumber,
      premiumDiscount: resp.premiumDiscount,
      restaurantGalaryDetails: resp.restaurantGalaryDetails,
      restaurantId: resp.restaurantId,
      restaurantName: resp.restaurantName,
      restaurantServices: resp.restaurantServices,
      restaurantTimeDetails: resp.restaurantTimeDetails,
      restaurantTypes: resp.restaurantTypes,
      specialityDetails: this.getSpeciality(resp.specialityDetails),
      standardDiscount: resp.standardDiscount,
      stateId: resp.stateId,
      storeTagDetails: resp.storeTagDetails,
      suiteDetails: this.getSuitablefor(resp.suiteDetails),
      tradeLicenseDetails: resp.tradeLicenseDetails,
      watsappNumber: resp.watsappNumber,
      websiteLink: resp.websiteLink,
      zipCode: resp.zipCode,

      /* Contract Details **/
      // contracttype: resp.
      // contractStartDate
      // billingtype
      // contractperiod
      // standard
      // premium
    };

    return data;
  }

  getItemDetails(itemList) {
    this.shared.getitemDetails.forEach((item: any, index) => {
      itemList[index].CategoryTempID =
        this.restaurantList.itemCategoryDetails.find(
          (m) => m.ItemCategoryId === item.CategoryId
        ).ItemCategoryName;
      itemList[index].ItemTagTempID =
        item.ItemTagId !== 0
          ? this.restaurantList.itemTagDetails.find(
            (m) => m.ItemTagId === item.ItemTagId
          ).ItemTagName
          : 0;
    });
    return itemList;
  }

  getLocality(localityId) {
    return this.restaurantList.localityDetails.find(
      (m) => m.localityId === localityId
    ).localityName;
  }

  getCity(cityId) {
    return this.restaurantList.cityDetails.find((m) => m.cityId === cityId)
      .cityName;
  }

  getCountry(countryId) {
    return this.restaurantList.countryDetails.find(
      (m) => m.countryId === countryId
    ).countryName;
  }

  getPayment(paymentList) {
    let payments = "";
    paymentList.forEach((element) => {
      payments += element.paymentName + ",";
    });
    const result = payments.slice(0, -1);
    return result;
  }

  getSuitablefor(suiteDetails) {
    let suiteName = "";
    suiteDetails.forEach((element) => {
      suiteName += element.suiteName + ",";
    });
    const result = suiteName.slice(0, -1);
    return result;
  }

  getEnvironment(ambienceDetails) {
    let ambienceName = "";
    ambienceDetails.forEach((element) => {
      ambienceName += element.ambienceName + ",";
    });
    const result = ambienceName.slice(0, -1);
    return result;
  }

  getCusine(cusineList) {
    let cusineName = "";
    cusineList.forEach((element) => {
      cusineName += element.cusineName + ",";
    });
    const result = cusineName.slice(0, -1);
    return result;
  }

  getSpeciality(specialityList) {
    let specialityName = "";
    specialityList.forEach((element) => {
      specialityName += element.specialityName + ",";
    });
    const result = specialityName.slice(0, -1);
    return result;
  }

  getFacilities(facilityDetails) {
    let item = "";
    facilityDetails.forEach((element) => {
      item += element.facilityName + ",";
    });
    const result = item.slice(0, -1);
    return result;
  }
}
