<div class="row mb-2 search-sec-main" *ngIf="showSearch">
  <div class="col-md-12">
    <input matInput class="search-field" (keyup)="applyFilter($event)" [formControl]="searchCtrl" placeholder="Search"
      #input />
  </div>
</div>
<div style="text-align: end; margin-right: 16px; padding-right: 25px" *ngIf="showEdit" (click)="onClickEdit()">
  <mat-icon style="font-size: 34px; cursor: pointer">edit</mat-icon>
</div>
<div class="table-container" *ngIf="cols.length > 0 && displayedColumns.length > 0"
  [ngClass]="showShadow ? 'mat-elevation-z2' : ''">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <table mat-table class="keeperz-table-v1" [ngClass]="styleClass" [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let col of cols">
      <ng-container [matColumnDef]="col.column">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ col?.title }}
        </th>
        <!-- color-code -->
        <ng-container *ngIf="col.type === colTypeModel.COLORCODE">
          <td
            [ngClass]="{'wrong-txns-bg': row.transactionType && row.transactionType === 3,  'reversed-txns-bg' :   row.transactionType && row.transactionType === 2, 'correct-txns-bg' :  row.transactionType && row.transactionType === '0'}"
            mat-cell *matCellDef="let row" class="pr-3">
            {{ row && row[col.column] ? row[col.column] : 'N/A' }}
          </td>
        </ng-container>
        <!-- Type Text-->
        <ng-container *ngIf="col.type === colTypeModel.TEXT">
          <td mat-cell *matCellDef="let row" class="pr-3" (click)="onRowClicked.emit(row)">
            {{ row && row[col.column] ? row[col.column] : 'N/A' }}
          </td>
        </ng-container>

        <ng-container *ngIf="col.type === colTypeModel.LINKTEXT">
          <td mat-cell *matCellDef="let row" class="pr-3" (click)="col.command(row)">
            <a>{{ row && row[col.column] ? row[col.column] : 'N/A' }}</a>
          </td>
        </ng-container>
        <!-- Type Percentage-->
        <ng-container *ngIf="col.type === colTypeModel.PERCENTAGE">
          <td mat-cell *matCellDef="let row" (click)="onRowClicked.emit(row)">
            {{ row && row[col.column] ? row[col.column] + '%' : 'N/A' }}
          </td>
        </ng-container>
        <!-- Type Color-->
        <ng-container *ngIf="col.type === colTypeModel.COLOR">
          <td mat-cell *matCellDef="let row" (click)="onRowClicked.emit(row)" [style.color]="row[col.colorObj]">
            {{ row && row[col.column] ? row[col.column] : 'N/A' }}
          </td>
        </ng-container>
        <!-- Type Date -->
        <ng-container *ngIf="col.type === colTypeModel.DATE">
          <td mat-cell *matCellDef="let row" (click)="onRowClicked.emit(row)" [style.color]="row[col.colorObj]">
            {{
            row && row[col.column]
            ? (row[col.column] | date: col.format)
            : 'N/A'
            }}
          </td>
        </ng-container>
        <!-- Type Date -->
        <ng-container *ngIf="col.type === colTypeModel.NUMBER">
          <td mat-cell *matCellDef="let row" (click)="onRowClicked.emit(row)" [style.color]="row[col.colorObj]">
            <ng-container *ngIf="checkForNan(row, col)">
              {{ row[col.column] }}
            </ng-container>
            <ng-container *ngIf="!checkForNan(row, col)">
              {{
              row && row[col.column]
              ? (row[col.column] | number: col.format)
              : 'N/A'
              }}
            </ng-container>
          </td>
        </ng-container>
        <!-- Type Money -->
        <ng-container *ngIf="col.type === colTypeModel.MONEY">
          <td mat-cell *matCellDef="let row" (click)="onRowClicked.emit(row)" [style.color]="row[col.colorObj]">
            <ng-container *ngIf="checkForNan(row, col)">
              {{ row[col.column] }}
            </ng-container>
            <ng-container *ngIf="!checkForNan(row, col)">
              {{
              row && row[col.column]
              ? (row[col.column]
              | currency: col.currencyType:'symbol-narrow':col.format)
              : 'N/A'
              }}
            </ng-container>
          </td>
        </ng-container>
        <!-- Type Image -->
        <ng-container *ngIf="col.type === colTypeModel.IMAGE">
          <td mat-cell *matCellDef="let row" (click)="onRowClicked.emit(row)">
            <img *ngIf="row && row[col.column]" [src]="row[col.column]" class="image-responsive td-image" width="80px"
              height="80px" />
          </td>
        </ng-container>
        <!-- Type Action -->
        <ng-container *ngIf="col.type === colTypeModel.ACTION">
          <td mat-cell *matCellDef="let row">
            <mat-slide-toggle color="warn" [(ngModel)]="row[col.column]" [disabled]="col.isDisable" [checked]="row[col.column]"
              (change)="onActionToggle.emit(row)">
            </mat-slide-toggle>
          </td>
        </ng-container>
        <!-- Type Action -->
        <ng-container *ngIf="col.type === colTypeModel.ACTIONDISABLE">
          <td mat-cell *matCellDef="let row">
            <mat-slide-toggle color="warn" [disabled]="row.isToggleDisabled || col.isDisable" [(ngModel)]="row[col.column]"
              [checked]="row[col.column]" (change)="onActionToggle.emit(row)">
            </mat-slide-toggle>
          </td>
        </ng-container>
        <!-- Type Button -->
        <ng-container *ngIf="col.type === colTypeModel.BUTTON">
          <td mat-cell *matCellDef="let row">
            <button [disabled]="col.isDisable" [class]="col.btnClass" (click)="col.command(row)">
              {{ col.btnText }}
            </button>
          </td>
        </ng-container>
        <!-- Type Notification Button -->
        <ng-container *ngIf="col.type === colTypeModel.NOTIFICATION">
          <td mat-cell *matCellDef="let row">
            <button [disabled]="row['isNotified'] || col.isDisable"
              [ngClass]="row['isNotified'] ? 'badge badge-secondary status-button' : col.btnClass"
              (click)="col.command(row)">
              {{ row['isNotified'] ? 'Notified' : col.btnText }}
            </button>
          </td>
        </ng-container>
        <!-- Type Progress -->
        <ng-container *ngIf="col.type === colTypeModel.PROGRESS">
          <td mat-cell *matCellDef="let row" class="text-right">
            <div class="">
              <mat-progress-bar color="warn" mode="determinate"
                [value]="(+row[col.achievedCol] / +row[col.targetCol]) * 100"></mat-progress-bar>
              <div class="d-flex justify-content-between mt-1 medium font-weight-600">
                <p class="mb-0">Target: {{ row[col.targetCol] }}</p>
                <p class="mb-0">Reached: {{ row[col.achievedCol] }}</p>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="col.type === colTypeModel.EDIT">
          <td mat-cell *matCellDef="let row">
            <span class="badge badge-warn status-button" (click)="editRowEditClicked(col.column, row)">Edit</span>
          </td>
        </ng-container>
        <ng-container *ngIf="col.type === colTypeModel.TRANSACTION">
          <td mat-cell *matCellDef="let row">
            <button [class]="col.btnClass" [disabled]="row['isReverseTransactionDone'] === 1 ? true : false"
              (click)="col.command({ col: col, row: row })">
              {{ col.btnText }}
            </button>
          </td>
          <!-- <td mat-cell *matCellDef="let row">
            <span
            class="badge badge-warn status-button"
            (click)="editRowTxnsClicked(col.column, row)"
            >{{col.title}}</span>
          </td> -->
        </ng-container>

        <ng-container *ngIf="col.type === colTypeModel.STATUS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-header">
            Status
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container>
              <span class="badge badge-success status-button"
                *ngIf="row.campaignStatus === 'Approved'">{{row.campaignStatus}}</span>
              <span class="badge badge-success  status-button"
                *ngIf="row.campaignStatus === 'Running'">{{row.campaignStatus}}</span>
              <span class="badge badge-info status-button"
                *ngIf="row.campaignStatus === 'Pending'">{{row.campaignStatus}}</span>
              <span class="badge badge-secondary status-button"
                *ngIf="row.campaignStatus === 'Expired'">{{row.campaignStatus}}</span>
            </ng-container>
          </td>
        </ng-container>


        <!-- <ng-container>
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-header"></th>
          <td mat-cell *matCellDef="let row">
              <span class="badge badge-dark-blue status-button" (click)="openDialog(row)">View</span>
          </td>
      </ng-container> -->
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        {{ data.length === 0 ? 'No data.' : 'No data matching the filter.' }}
      </td>
    </tr>
  </table>
</div>

<div [ngClass]="{'paginater-align': paginaterAlignLeft}" class="row mt-3" *ngIf="showPaginator">
  <mat-paginator #paginator [pageSize]="pageSize" [length]="length" [pageIndex]="pageIndex"
    [pageSizeOptions]="showPageSizeOptions ? [5, 10, 25, 100] : pageSize" (page)="onPageEvent($event)"></mat-paginator>
</div>