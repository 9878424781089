import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'chart.js';

export interface ChartV2Config {
  chartType: 'bar' | 'horizontalBar' | 'doughnut' | 'barLine' | 'line' | 'pie';
  minHeight?: number;
  maxDataLimit?: number;
  pluginText?: string;
  chartLabels?: string[];
  chartOptions: any;
  chartLegend: boolean;
  colors: string[];
}

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  @Input() chartConfig: ChartV2Config;
  @Input() chartDataset: Chart.ChartDataSets[] = [];
  @Input() chartLabels?: string[];

  inlinePlugin: any;
  textPlugin: any;

  ngOnInit() {
    if (this.chartConfig.pluginText) {
      // inline plugin
      this.textPlugin = [{
        id: 'textPlugin',
        beforeDraw(chart: any): any {
          const width = chart.chart.width;
          const height = chart.chart.height;
          const ctx = chart.chart.ctx;
          ctx.restore();
          const fontSize = (height / 114).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = 'middle';
          const text = 'Text Plugin';
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }];

      this.inlinePlugin = this.textPlugin;
    }
  }

}
