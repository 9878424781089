import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { WalletService } from '../services/wallet.service';
import {
  ColsModel,
  ColTypeModel,
} from "src/app/shared/datatable/datatable.model";
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ReversalTxnDialogComponent } from "../reversal-txn-dialog/reversal-txn-dialog.component";
import { DatatableComponent } from 'src/app/shared/datatable/datatable.component';
import { DataTableDialogComponent } from "../../../shared/components/data-table-dialog/data-table-dialog.component";
import { NotificationService } from "../../../shared/services/notification.service";
@Component({
  selector: 'app-reversal-incident-list',
  templateUrl: './reversal-incident-list.component.html',
  styleUrls: ['./reversal-incident-list.component.scss']
})
export class ReversalIncidentListComponent
  implements OnInit {
  incidentDataSource: any;
  totalRows: any;
  @ViewChild(DatatableComponent) table: any;
  gridPageNumber: number = 1;
  incidentsCols: ColsModel[] = [
    /** Transaction Details */
    // {
    //   column: "reversaltxns",
    //   title: "Reversal Transaction",
    //   type: ColTypeModel.EDIT,
    // },
    {
      column: 'reversaltxns',
      title: 'Reversal Transaction',
      type: ColTypeModel.TRANSACTION,
      txnType: 2,
      btnClass: 'btn btn-sm red pr-2 pl-2',
      btnText: 'Reversal Only',
      command: (res) => this.onReportEditClick(res),
    },
    // {
    //   column: "newtxns",
    //   title: "New Transaction",
    //   type: ColTypeModel.EDIT,
    // },
    {
      column: 'reversalnewtxns',
      title: 'New Transaction',
      type: ColTypeModel.TRANSACTION,
      txnType: 3,
      btnClass: 'btn btn-sm red pr-2 pl-2',
      btnText: 'Reversal/New',
      command: (res) => this.onReportEditClick(res),
    },
    {
      column: "incidentReport",
      title: "Incident Description",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionNo",
      title: "Transaction No.",
      type: ColTypeModel.TEXT,
    },
    {
      column: "restaurantName",
      title: "Merchant Name",
      type: ColTypeModel.TEXT,
    },
    { column: "inviterId", title: "Inviter ID", type: ColTypeModel.TEXT },
    { column: "userId", title: "User ID", type: ColTypeModel.TEXT },
    { column: "transactionDate", title: "Date", type: ColTypeModel.TEXT },
    { column: "time", title: "Time", type: ColTypeModel.TEXT },
    { column: "voucherType", title: "Voucher Type", type: ColTypeModel.TEXT },
    {
      column: "billAmount",
      title: "Bill Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "exclusionAmount",
      title: "Exclusion Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    /** User Discount/Redemption Calculation */
    { column: "kPINUsed", title: "Redeemed By", type: ColTypeModel.TEXT },
    {
      column: "discountPercentage",
      title: "Discount Applied(%)",
      type: ColTypeModel.TEXT,
    },

    {
      column: "discountAmount",
      title: "Discount Availed(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "billPaidAmount",
      title: "Final Bill Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    /** Payment Details */
    {
      column: "paymentMethod",
      title: "Payment Method",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentReceivedBy",
      title: "Received by",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentStatus",
      title: "Payment Status",
      type: ColTypeModel.TEXT,
    },
    { column: "paymentRefCode", title: "Ref.Code", type: ColTypeModel.TEXT },
    {
      column: "paymentProvider",
      title: "Payment Provider Id",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentFeesPercentage",
      title: "P/G Fees(%)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentFeesValues",
      title: "P/G Fees(Amount)",
      type: ColTypeModel.TEXT,
    },
    /** Keeperz Fees Calculation */
    { column: "featureType", title: "Transaction Type", type: ColTypeModel.TEXT },
    {
      column: "transactionPercentage",
      title: "Transaction Fees(%)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionCharges",
      title: "Transaction Fees(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "inviterCashBackValue",
      title: "Inviter Cashback(AED)",
      type: ColTypeModel.TEXT,
    },
    /** User Rewards */
    {
      column: "redeemptionKoinz",
      title: "Redemption Koinz",
      type: ColTypeModel.TEXT,
    },
    {
      column: "creditValue",
      title: "Redemption Cashback (AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "inviterCashBackKoinz",
      title: "Inviter Cashback Koinz",
      type: ColTypeModel.TEXT,
    },
  ];
  transactionHistoryCols: ColsModel[] = [
    /** Transaction Details */
    // {
    //   column: "reversaltxns",
    //   title: "Reversal Transaction",
    //   type: ColTypeModel.EDIT,
    // },
    // {
    //   column: 'reversaltxns',
    //   title: 'Reversal Transaction',
    //   type: ColTypeModel.TRANSACTION,
    //   txnType: 2,
    //   btnClass: 'btn btn-sm red pr-2 pl-2',
    //   btnText: 'Reversal',
    //   command: (res) => this.onReportEditClick(res),
    // },
    // {
    //   column: "newtxns",
    //   title: "New Transaction",
    //   type: ColTypeModel.EDIT,
    // },
    // {
    //   column: 'reversalnewtxns',
    //   title: 'New Transaction',
    //   type: ColTypeModel.TRANSACTION,
    //   txnType: 3,
    //   btnClass: 'btn btn-sm red pr-2 pl-2',
    //   btnText: 'New',
    //   command: (res) => this.onReportEditClick(res),
    // },
    // {
    //   column: "type",
    //   title: "type",
    //   type: ColTypeModel.COLORCODE,
    // },
    {
      column: "incidentReport",
      title: "Incident Description",
      type: ColTypeModel.COLORCODE,
    },
    {
      column: "transactionNo",
      title: "Transaction No.",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionDescription",
      title: "Transaction Type",
      type: ColTypeModel.TEXT,
    },
    {
      column: "restaurantName",
      title: "Merchant Name",
      type: ColTypeModel.TEXT,
    },
    { column: "inviterId", title: "Inviter ID", type: ColTypeModel.TEXT },
    { column: "userId", title: "User ID", type: ColTypeModel.TEXT },
    { column: "transactionDate", title: "Date", type: ColTypeModel.TEXT },
    { column: "time", title: "Time", type: ColTypeModel.TEXT },
    { column: "voucherType", title: "Voucher Type", type: ColTypeModel.TEXT },
    {
      column: "billAmount",
      title: "Bill Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "exclusionAmount",
      title: "Exclusion Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    /** User Discount/Redemption Calculation */
    { column: "kPINUsed", title: "Redeemed By", type: ColTypeModel.TEXT },
    {
      column: "discountPercentage",
      title: "Discount Applied(%)",
      type: ColTypeModel.TEXT,
    },

    {
      column: "discountAmount",
      title: "Discount Availed(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "billPaidAmount",
      title: "Final Bill Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    /** Payment Details */
    {
      column: "paymentMethod",
      title: "Payment Method",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentReceivedBy",
      title: "Received by",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentStatus",
      title: "Payment Status",
      type: ColTypeModel.TEXT,
    },
    { column: "paymentRefCode", title: "Ref.Code", type: ColTypeModel.TEXT },
    {
      column: "paymentProvider",
      title: "Payment Provider Id",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentFeesPercentage",
      title: "P/G Fees(%)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentFeesValues",
      title: "P/G Fees(Amount)",
      type: ColTypeModel.TEXT,
    },
    /** Keeperz Fees Calculation */
    { column: "featureType", title: "Transaction Type", type: ColTypeModel.TEXT },
    {
      column: "transactionPercentage",
      title: "Transaction Fees(%)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionCharges",
      title: "Transaction Fees(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "inviterCashBackValue",
      title: "Inviter Cashback(AED)",
      type: ColTypeModel.TEXT,
    },
    /** User Rewards */
    {
      column: "redeemptionKoinz",
      title: "Redemption Koinz",
      type: ColTypeModel.TEXT,
    },
    {
      column: "creditValue",
      title: "Redemption Cashback (AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "inviterCashBackKoinz",
      title: "Inviter Cashback Koinz",
      type: ColTypeModel.TEXT,
    },
  ];
  @Input() incidentData: any = [];
  @Output() bindIncidentCount = new EventEmitter<any>();
  gridpageSize: any = 10;
  //@Output() bindIncidentCount = new EventEmitter<any>();
  constructor(public walletService: WalletService,
    private dialog: MatDialog, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.getAllIncidents(false);
    //this.incidentDataSource = this.incidentData;
  }


  getAllIncidents(onPageEvent: boolean) {
    let pageNumber = onPageEvent ? this.gridPageNumber + 1 : this.gridPageNumber;
    const payload = {
      "pageSize": this.gridpageSize,
      "pageNumber": this.gridPageNumber === 0 ? 1 : pageNumber,
    }
    this.walletService.getIncidentList(payload).subscribe(res => {
      if (!res.isError) {
        //this.actionDataCard = [{ value: res.data.totalCount, bottomTitle: 'Incident' }];
        //this.incidentDataSource = res.data.listTransactionHistory;
        this.walletService.incidentList = res.data.listTransactionHistory;
        this.walletService.incidentCount = res.data.totalCount;
        setTimeout(() => {
          if (this.table) {
            this.table.isLoading = false;
            if (onPageEvent) {
              this.table.pageIndex = this.gridPageNumber;
            }
          }
          this.totalRows = res.data.totalCount;
        });
      }
    });
  }

  onPageEvent(e) {
    this.gridPageNumber = e.pageIndex;
    this.gridpageSize = e.pageSize;
    this.getAllIncidents(true);
  }

  onRowClicked(selectedIncident) {
    console.log(selectedIncident);
    const payload = {
      "transactionNo": selectedIncident.transactionNo
    }
    this.walletService.getTransactionHistory(payload).subscribe(res => {
      if (!res.isError) {
        if (res.data.length > 0) {
          res.data.forEach(element => {
            if (element.transactionType === 0) {
              element.transactionType = '0';
            }
          })
          this.openTransactionDialog(res.data);
        }
        else {
          this.notificationService.showWarning("There are no transaction history available", "info");
        }
      }
    })

  }

  openTransactionDialog(tabledata) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '100%';
    dialogConfig.height = "60%";
    dialogConfig.data = { datasource: tabledata, dataColumns: this.transactionHistoryCols };
    this.dialog.open(DataTableDialogComponent, dialogConfig);
  }

  onReportEditClick(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '35%';
    dialogConfig.data = { selectedRow: data.row, col: data.col };
    console.log(data);
    this.dialog.open(ReversalTxnDialogComponent, dialogConfig);
  }


}
