import { Component } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MerchantsProfileHelperService } from "../../../modules/merchants/merchant-mod/merchant-profile-mod/services/merchants-profile-helper.service";
import { ReversalTxnDialogComponent } from "../../../modules/wallet/reversal-txn-dialog/reversal-txn-dialog.component";
@Component({
  selector: "app-merchant-header-profile",
  templateUrl: "./merchant-header-profile.component.html",
  styleUrls: ["./merchant-header-profile.component.scss"],
})
export class MerchantHeaderProfileComponent {
  isSalesRole: boolean;
  constructor(public merchantProfileService: MerchantsProfileHelperService,
    private dialog: MatDialog) { 
      this.isSalesRole = JSON.parse(localStorage.getItem('user')).role === 'Sales';
    }


  onClickNewTxns() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '35%';
    dialogConfig.data = { selectedRow: '', col: { column: 'newtxns', title : 'New Transaction', txnType : 3 } };
    this.dialog.open(ReversalTxnDialogComponent, dialogConfig);
  }
}
