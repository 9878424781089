<div *ngIf="merchantProfileService && merchantProfileService.selectedMerchant">
  <div class="row">
    <div class="col-md-6 card-section">
      <div class="d-flex align-items-center ml-4">
        <img
          [src]="
            merchantProfileService.selectedMerchant.restaurantList[0]
              .logoImageURL
          "
        />
        <div class="header">
          <h6 class="text-overflow">
            {{
              merchantProfileService.selectedMerchant.restaurantList[0]
                .restaurantName
            }}
          </h6>
          <p
            class="text-overflow"
            *ngIf="merchantProfileService.selectedMerchant.restaurantList[0]"
          >
            {{merchantProfileService.selectedRestaurantCityAndLocality}}
          </p>
          <button mat-raised-button color="warn" [disabled]="isSalesRole" (click)="onClickNewTxns()">New Transaction</button>
        </div>
      </div>
    </div>
  </div>
</div>
