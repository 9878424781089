import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SignInResModel } from '../model/user.model';
import { RestaurentMasterGetModel } from './helper/reastaurent-master.model';
import { DefaultApiResModel } from '../model/default-api-res.model';
import {
  IDiscountPayload,
  IWordOfMouthPayload,
} from 'src/app/modules/merchants/merchant-mod/merchant-marketing-solutions-mod/merchant-marketing-solutions/merchant-marketing-solutions.component';
import { IloyaltyPayload } from 'src/app/modules/merchants/merchant-mod/merchant-profile-mod/services/merchants-profile-helper.service';
import { ITxnReportPayload } from 'src/app/modules/reports/merchant-txns-repot/merchant-txns-repot.component';
import { IUserReportPayload } from 'src/app/modules/reports/users-report/users-report.component';
import {
  IKpinPayload,
  IKpinResponseModel,
} from '../components/data-card-split/data-card-split.component';
import { IRecommendTxnReportPayload } from 'src/app/modules/reports/recommendation-txns-report/recommendation-txns-report.component';

export interface DefaultApiResponse {
  statusCode: number;
  message: string;
  isError: boolean;
  data: any;
}


export interface IReversalTransaction {
  fromDate: string;
  toDate: string;
  pageNumber: number;
  pageSize: number;
}




@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) { }

  public endPointUrl: any = environment.baseUrl;

  public Login(request: any) {
    const url = this.getUrl('/api/merchantlogin');
    return this.http.post<SignInResModel>(url, request);
  }

  public RestaurantList(): Observable<RestaurentMasterGetModel> {
    const url = this.getUrl('/api/restaurantdatalist');
    return this.http.get<RestaurentMasterGetModel>(url);
  }

  public signup(request: any, header): Observable<any> {
    const url = this.getUrl('/api/merchantregistration');
    return this.http.post<any>(url, request, header);
  }

  public postRestaurantDetails(request: any): Observable<any> {
    const url = this.getUrl('/api/restaurantdetails');
    return this.http.post<any>(url, request);
  }

  public postBankDetails(request: any): Observable<any> {
    const url = this.getUrl('/api/bankdetails');
    return this.http.post<any>(url, request);
  }

  public postTradeLicenseDetails(request: any): Observable<any> {
    const url = this.getUrl('/api/tradelicensedetails');
    return this.http.post<any>(url, request);
  }

  public postItemDetails(request: any): Observable<DefaultApiResponse> {
    const url = this.getUrl('/api/itemdetails');
    return this.http.post<DefaultApiResponse>(url, request);
  }

  public postGallery(request: any): Observable<DefaultApiResModel> {
    const url = this.getUrl('/api/restaurantgalary');
    return this.http.post<DefaultApiResModel>(url, request);
  }

  public postStandardDiscound(request: any, header): Observable<any> {
    const url = this.getUrl('/api/restaurantdiscount');
    return this.http.post<DefaultApiResModel>(url, request, header);
  }

  public postMerchantDetails(request: any): Observable<any> {
    const url = this.getUrl('/api/getmerchantdetails');
    return this.http.post<any>(url, request);
  }

  public postKPINDetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/merchantkpndetails');
    return this.http.post<any>(url, request, header);
  }

  public postEmployeeDetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/merchantkpn');
    return this.http.post<any>(url, request, header);
  }

  public getEmployeeDetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/getemployeedetails');
    return this.http.post<any>(url, request, header);
  }

  public postHappyHours(request: any, header): Observable<any> {
    const url = this.getUrl('/api/happyhoursdetails');
    return this.http.post<any>(url, request, header);
  }

  public postHotDeals(request: any): Observable<any> {
    const url = this.getUrl('/api/hotdealsdetails');
    return this.http.post<any>(url, request);
  }

  public getcampaignaudience(request: any): Observable<any> {
    const url = this.getUrl('/api/campaignaudience');
    return this.http.post<any>(url, request);
  }

  public getcampaignaudiencebyid(request: any): Observable<any> {
    const url = this.getUrl('/api/campaignaudiencebyid');
    return this.http.post<any>(url, request);
  }

  public postsecretdealsdetails(request: any): Observable<any> {
    const url = this.getUrl('/api/secretdealsdetails');
    return this.http.post<any>(url, request);
  }

  public postinactivecustomeroffer(request: any): Observable<any> {
    const url = this.getUrl('/api/inactivecustomeroffer');
    return this.http.post<any>(url, request);
  }

  public postwordofmouthdetailss(request: any, header): Observable<any> {
    const url = this.getUrl('/api/wordofmouthdetails');
    return this.http.post<any>(url, request, header);
  }

  public postinappadsdetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/inappadsdetails');
    return this.http.post<any>(url, request, header);
  }

  public postEnquiryform(request: any, header): Observable<any> {
    const url = this.getUrl('/api/enquiryform');
    return this.http.post<any>(url, request, header);
  }

  public postFirstTimeUser(request: any, header): Observable<any> {
    const url = this.getUrl('/api/verifyfirsttimeuser');
    return this.http.post<any>(url, request, header);
  }

  public getCampaigndetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/getcampaigndetails');
    return this.http.post<any>(url, request, header);
  }

  public getMerchantkpndetails(request, header): Observable<any> {
    const url = this.getApiGateWayUrl(
      '/api/getmerchantkpndetails?restaurantId=' +
      request.restaurantId +
      '&branchId=' +
      request.branchId
    );
    return this.http.get<any>(url, header);
  }

  public getreputaiondetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/getreputationdetails');
    return this.http.post<any>(url, request, header);
  }

  public gettransactiondetails(request: any, header): Observable<any> {
    const url = this.getUrl('/api/gettransactiondetails');
    return this.http.post<any>(url, request, header);
  }

  public getEnquiryFormList(): Observable<any> {
    const url = this.getUrl('/api/enquiryformlist');
    return this.http.get<any>(url);
  }

  public postEnquiryFormList(request): Observable<any> {
    const url = this.getUrl('/api/enquiryformlist');
    return this.http.post<any>(url, request);
  }

  public getMerchantlist(): Observable<any> {
    const url = this.getUrl('/api/merchantlist');
    return this.http.get<any>(url);
  }

  public postrestaurantbanner(request, header): Observable<any> {
    const url = this.getUrl('/api/restaurantbanner');
    return this.http.post<any>(url, request, header);
  }

  public postMerchantlist(request): Observable<any> {
    const url = this.getUrl('/api/merchantlist');
    return this.http.post<any>(url, request);
  }

  public postForgetpassword(request, header): Observable<any> {
    const url = this.getUrl('/api/forgetpassword');
    return this.http.post<any>(url, request, header);
  }

  public PostApprovecampaign(request): Observable<any> {
    const url = this.getUrl('/api/approvecampaign');
    return this.http.post<any>(url, request);
  }

  public postApproveads(request): Observable<any> {
    const url = this.getUrl('/api/approveads');
    return this.http.post<any>(url, request);
  }

  public getCampaignListAll(): Observable<any> {
    const url = this.getUrl('/api/getcampaigndetailslist');
    return this.http.get<any>(url);
  }

  public getstandardlist(): Observable<any> {
    const url = this.getUrl('/api/getstandardlist');
    return this.http.get<any>(url);
  }

  public getdashboarddetails(request, header): Observable<any> {
    const url = this.getUrl('/api/getdashboarddetails');
    return this.http.post<any>(url, request, header);
  }

  public getmycampaigndetails(request): Observable<any> {
    const url = this.getUrl('/api/getmycampaigndetails');
    return this.http.post<any>(url, request);
  }

  public getcustomertypedetails(request, header): Observable<any> {
    const url = this.getUrl('/api/getcustomertypedetails');
    return this.http.post<any>(url, request, header);
  }

  public getengagementdetails(request, header): Observable<any> {
    const url = this.getUrl('/api/getengagementdetails');
    return this.http.post<any>(url, request, header);
  }

  public getrevenueperformancedetails(request, header): Observable<any> {
    const url = this.getUrl('/api/getrevenueperformancedetails');
    return this.http.post<any>(url, request, header);
  }

  public sendMail(request: any, header): Observable<any> {
    const url = this.getApiGateWayUrl('/api/mail');
    return this.http.post<any>(url, request, header);
  }

  public getUrl(url) {
    const modifiedUrl = this.endPointUrl + url;
    return modifiedUrl;
  }

  public getApiGateWayUrl(url) {
    const modifiedUrl = environment.apigateUrl + url;
    return modifiedUrl;
  }

  public getUrlMail(url) {
    const modifiedUrl = environment.mail + url;
    return modifiedUrl;
  }

  public postRestaurantDiscount(
    discount: IDiscountPayload | IWordOfMouthPayload | IloyaltyPayload
  ): Observable<any> {
    const url = this.getUrl('/api/restaurantdiscount');
    return this.http.post<any>(url, discount);
  }

  public getMerTxnReportDetails(payload: ITxnReportPayload): Observable<any> {
    const url = this.getUrl('/api/getmerchanttransaction-reportdetails');
    return this.http.post<any>(url, payload);
  }

  public getRecommendTxnReportDetails(payload: IRecommendTxnReportPayload): Observable<any> {
    const url = this.getUrl('/api/get-recommend-transactiondetails-all');
    return this.http.post<any>(url, payload);
  }

  public getWalletTxnReportDetails(payload: any): Observable<any> {
    const url = this.getUrl('/api/get-wallet-transactiondetails');
    return this.http.post<any>(url, payload);
  }


  public getOpenTxnReportDetails(payload: IRecommendTxnReportPayload): Observable<any> {
    const url = this.getUrl('/api/get-report-open-transaction-details');
    return this.http.post<any>(url, payload);
  }



  public getUserRegistrationDetails(
    payload: IUserReportPayload
  ): Observable<any> {
    const url = this.getUrl('/api/getreport-userregistrationdetails');
    return this.http.post<any>(url, payload);
  }

  public generateKpin(payload: IKpinPayload): Observable<IKpinResponseModel> {
    const url = this.getUrl('/api/generateadmin-kpin');
    return this.http.post<any>(url, payload);
  }

  public getGeneratedKpin(payload: IKpinPayload): Observable<IKpinResponseModel> {
    const url = this.getUrl('/api/getadmin-kpin');
    return this.http.post<any>(url, payload);
  }

  public deleteMenuCardImages(request, header) {
    const url = this.getUrl('/api/restaurant-images-delete');
    return this.http.post<any>(url, request, header);
  }


  public getReversalTxnReportDetails(payload: IReversalTransaction): Observable<any> {
    const url = this.getUrl('/api/admin-getreversal-transactiondetails-all');
    return this.http.post<any>(url, payload);
  }

  public addNewCity(payload: any): Observable<any> {
    const url = this.getUrl('/api/citydetails');
    return this.http.post<any>(url, payload);
  }

  public localityPost(payload: any): Observable<any> {
    const url = this.getUrl('/api/localitydetails');
    return this.http.post<any>(url, payload);
  }

  public getErrorCodeDetailsById(payload: any): Observable<any> {
    const url = this.getUrl('/api/localitydetails');
    return this.http.post<any>(url, payload);
  }

  public getErrorCodeList() {
    const url = this.getUrl('/api/report-errorcode');
    return this.http.get<any>(url);
  }

  public getErrorCodeDetails(payload: any): Observable<any> {
    const url = this.getUrl('/api/report-errordetails');
    return this.http.post<any>(url, payload);
  }

  public postEventads(payload: any): Observable<any> {
    const url = this.getUrl('/api/events-inappadsdetails');
    return this.http.post<any>(url, payload);
  }


  public getEventAdsList() {
    const url = this.getUrl('/api/get-event-addetailslist');
    return this.http.get<any>(url);
  }

  public getAdminNameList(): Observable<any> {
    const url = environment.baseUrl + '/api/admin-userlist';
    return this.http.get<any>(url);
  }

  public sendEventOtp(payload: any): Observable<any> {
    const url = this.getUrl('/api/eventads-approval-send-otp');
    return this.http.post<any>(url, payload);
  }

  public validateOtp(payload: any): Observable<any> {
    const url = this.getUrl('/api/eventads-validate-otp');
    return this.http.post<any>(url, payload);
  }

  public updateEventAdsStatus(payload: any): Observable<any> {
    const url = this.getUrl('/api/admin-ads-status');
    return this.http.post<any>(url, payload);
  }

  public getNotificationListData(): Observable<any> {
    const url = environment.baseUrl + '/api/get-admin-notificationdetails-list';
    return this.http.get<any>(url);
  }

  public getNationalityList(): Observable<any> {
    const url = environment.baseUrl + '/api/country';
    return this.http.get<any>(url);
  }

  public postAudience(payload: any): Observable<any> {
    const url = this.getUrl('/api/admin-notify-create-audience');
    return this.http.post<any>(url, payload);
  }


  public getAudiecneList(): Observable<any> {
    const url = this.getUrl('/api/getnotification-audiecnelist');
    return this.http.get<any>(url);
  }

  public getNotificationAudienceData(payload: any): Observable<any> {
    const url = this.getUrl('/api/list-notify-audience');
    return this.http.post<any>(url, payload);
  }

  public postNotification(payload: any): Observable<any> {
    const url = this.getUrl('/api/amdin-notification-details');
    return this.http.post<any>(url, payload);
  }
  public sendNotification(payload: any): Observable<any> {
    const url = this.getUrl('/api/notify-send');
    return this.http.post<any>(url, payload);
  }

  public checkNotificationStatus(payload: any): Observable<any> {
    const url = this.getUrl('/api/admin-notify-get-details');
    return this.http.post<any>(url, payload);
  }

  public SendCloseTxnOTP(payload: any): Observable<any> {
    const url = this.getUrl('/api/admin-opentransaction-send-otp');
    return this.http.post<any>(url, payload);
  }

  public verfifyUserOTP(payload: any): Observable<any> {
    const url = this.getUrl('/api/admin-opentransaction-validate-otp');
    return this.http.post<any>(url, payload);
  }

  public updateOpenTransaction(payload: any): Observable<any> {
    const url = this.getUrl('/api/admin-update-open-transaction-details');
    return this.http.post<any>(url, payload);
  }

  public validateUser(req) {
    const url = environment.baseUrl + '/api/admin-appuser-validate';
    return this.http.post<any>(url, req);

  }

}
