import { Component, OnInit, ViewChild } from '@angular/core';
import { WalletService } from '../services/wallet.service';
import { MatTableDataSource } from '@angular/material/table';
import { WalletBaseComponent } from '../wallet.base';
import { ApprovalHelperService } from '../../approval/on-boarding/_services/approval-helper.service';
import { MatPaginator } from '@angular/material/paginator';
import { DatatableComponent } from 'src/app/shared/datatable/datatable.component';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { DataCardModel } from '../../../shared/components/data-card-split/model/data-card.model';
import { DataCardConfig } from '../../../shared/components/data-card-split/data-card-split.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReversalTxnDialogComponent } from "./../reversal-txn-dialog/reversal-txn-dialog.component";
import { Router } from '@angular/router';
export interface IWalletAllTxnsPayload {
  pageSize: string;
  pageNumber: string;
}

@Component({
  selector: 'app-wallet-all-txns',
  templateUrl: './wallet-all-txns.component.html',
  styleUrls: ['./wallet-all-txns.component.scss'],
})
export class WalletAllTxnsComponent
  extends WalletBaseComponent
  implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sort;
  @ViewChild(DatatableComponent) table: any;
  dataSource;
  incidentDataSource;
  gridPageNumber: number = 1;
  gridpageSize: string = '10';
  totalRows: number;
  data;
  isAllTransaction = true;
  isIncidents = false;
  incidentList: any = [];
  allTransactionData: any = [];
  actionDataCard: DataCardModel[] = [];
  actionDataCardConfig: DataCardConfig = {
    header: 'Incidents',
    customClass: 'mr-4 mb-4',
    width: '13.5em',
  };
  constructor(
    protected walletService: WalletService,
    private aprlService: ApprovalHelperService,
    protected dialog: MatDialog,
    protected router: Router
  ) {
    super(walletService, dialog, router);
  }

  ngOnInit(): void {
    this.datewiseList = [
      { textId: 1, textValue: 'Total' },
      { textId: 2, textValue: '7 Days' },
      { textId: 3, textValue: '14 Days' },
      { textId: 4, textValue: '30 Days' },
      { textId: 5, textValue: 'Custom' },
    ];
    this.getAllTxns(false);

    this.getAllIncidents();

    this.getSuccessText().subscribe((res: any) => {
      this.isSuccess = res.isSuccess;
      this.message = res.message;
      let interval = setInterval(() => {
        this.isSuccess = false;
        clearInterval(interval);
      }, 10000)
    })
  }

  onSuccessClose() {
    this.isSuccess = false;
  }

  getAllIncidents() {
    const payload = {
      "pageSize": 10,
      "pageNumber": 1
    }
    this.walletService.getIncidentList(payload).subscribe(res => {
      if (!res.isError) {
        this.actionDataCard = [{ value: res.data.totalCount, bottomTitle: 'Incident' }];
        this.incidentList = res.data.listTransactionHistory;
        this.incidentDataSource = res.data.listTransactionHistory;
      }
    });
  }

  getAllTxns(onPageEvent: boolean) {
    let pageNumber = onPageEvent ? this.gridPageNumber + 1 : this.gridPageNumber;
    const payload: IWalletAllTxnsPayload = {
      pageNumber: this.gridPageNumber === 0 ? '1' : pageNumber.toString(),
      pageSize: this.gridpageSize.toString(),
    };
    this.walletService.getAllTransactions(payload).subscribe((response) => {
      this.responsedata = response.data;
      this.dataSource = this.responsedata.listTransactionHistory;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.displayedColumns = this.transCols.map((c) => c.column);
      setTimeout(() => {
        if (this.table) {
          this.table.isLoading = false;
          if (onPageEvent) {
            this.table.pageIndex = this.gridPageNumber;
          }
        }
        this.totalRows = response.data.totalCount;
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageEvent(e) {
    this.gridPageNumber = e.pageIndex;
    this.gridpageSize = e.pageSize;
    this.getAllTxns(true);
  }

  loadData(fromDate, toDate) {
    const request = {
      fromDate,
      toDate,
      branchId: '1',
      restaurantId: 2,
    };
    return this.walletService.getMerchantWalletTransDet(request);
  }

  export() {
    let transHistory = [];
    this.responsedata.listTransactionHistory.forEach(element => {
      let obj = {

        //"Inviter ID":element.inviterId === ""? 'N/A':element.inviterId,
        "Ref.Code": element.paymentRefCode,
        "User ID": element.userId,
        "Customer ID" : element.customerId,
        "Transaction No": element.transactionNo,
        "Date": element.transactionDate,
        "Time": element.time,
        "Voucher Type": element.voucherType,
        "Voucher Discount": element.discountPercentage,
        "Bill Amount": Number(element.billAmount),
        "Exclusion Amount(AED)": Number(element.exclusionAmount),
        "User Discount(AED)": Number(element.discountAmount),
        "Final Bill Amount(AED)": Number(element.billPaidAmount),
        "Payment Method": element.paymentMethod,
        "Payment Status": element.paymentStatus,
        "Received by": (element.paymentReceivedBy === "" || element.paymentReceivedBy === null) ? 'N/A' : element.paymentReceivedBy,
        "Redeemed By": (element.kPINUsed === "" || element.kPINUsed === null) ? 'N/A' : element.kPINUsed,
        "Payment Provider Id": element.paymentProvider === "" ? 'N/A' : element.paymentProvider,
        "P/G Fees(%)": element.paymentFeesPercentage === "" ? 'N/A' : Number(element.paymentFeesPercentage),
        "P/G Fees(Amount)": element.paymentFeesValues === "" ? 'N/A' : Number(element.paymentFeesValues),
        "Transaction Type": element.featureType,
        "Transaction Fees(%)": Number(element.transactionPercentage),
        "Transaction Fees(AED)": Number(element.transactionCharges),
        "Redemption Cashback (AED)": Number(element.creditValue),
        //"Inviter Cashback(AED)":element.inviterCashBackValue === ""? 'N/A':element.inviterCashBackValue,
        "Redemption Cashback(Koinz)": Number(element.redeemptionKoinz),
        "Payment Done By": element.paymentDoneBy,
        "Payment Done By Mobile No": element.MobileNo
        //"Inviter Cashback Koinz":element.inviterCashBackKoinz === ""? 'N/A':element.inviterCashBackKoinz
      }
      transHistory.push(obj)
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transHistory);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.utils..Sheets.Sheet1.A1 = { font: { bold: true } };

    /* save to file */
    XLSX.writeFile(wb, 'All Transaction by Page-' + moment(new Date()) + '.xlsx', { cellStyles: true });

  }

  exportAll() {
    const payload: IWalletAllTxnsPayload = {
      pageNumber: "1",
      pageSize: "0",
    };
    if (this.allTransactionData.length === 0) {
      this.walletService.getAllTransactions(payload).subscribe((response) => {
        this.allTransactionData = response.data;
        this.exportAllTransaction(this.allTransactionData);
      });
    }
    else {
      this.exportAllTransaction(this.allTransactionData);
    }
  }

  exportAllTransaction(allTransaction) {
    let transHistory = [];
    allTransaction.listTransactionHistory.forEach(element => {
      let obj = {

        //"Inviter ID":element.inviterId === ""? 'N/A':element.inviterId,
        "Ref.Code": element.paymentRefCode,
        "User ID": element.userId,
        "Transaction No": element.transactionNo,
        "Date": element.transactionDate,
        "RestaurantName": element.restaurantName == null ? "" : element.restaurantName,
        "GroupName": element.groupName == null ? "" : element.groupName,
        "BrandName": element.brandName == null ? "" : element.brandName,
        "FranchiseName": element.franchiseName == null ? "" : element.franchiseName,
        "Customerid": element.customerId == null ? "" : element.customerId,
        "Time": element.time,
        "Voucher Type": element.voucherType,
        "Voucher Discount": Number(element.discountPercentage),
        "Bill Amount": Number(element.billAmount),
        "Exclusion Amount(AED)": Number(element.exclusionAmount),
        "User Discount(AED)": Number(element.discountAmount),
        "Final Bill Amount(AED)": Number(element.billPaidAmount),
        "Payment Method": element.paymentMethod,
        "Payment Status": element.paymentStatus,
        "Received by": element.paymentReceivedBy === "" ? 'N/A' : element.paymentReceivedBy,
        "Redeemed By": element.kPINUsed,
        "Payment Provider Id": element.paymentProvider === "" ? 'N/A' : element.paymentProvider,
        "P/G Fees(%)": element.paymentFeesPercentage === "" ? 'N/A' : element.paymentFeesPercentage,
        "P/G Fees(Amount)": element.paymentFeesValues === "" ? 'N/A' : element.paymentFeesValues,
        "Transaction Type": element.featureType,
        "Transaction Fees(%)": Number(element.transactionPercentage),
        "Transaction Fees(AED)": Number(element.transactionCharges),
        "Redemption Cashback (AED)": Number(element.creditValue),
        //"Inviter Cashback(AED)":element.inviterCashBackValue === ""? 'N/A':element.inviterCashBackValue,
        "Redemption Cashback(Koinz)": Number(element.redeemptionKoinz),
        "Payment Done By": element.paymentDoneBy,
        "Payment Done By Mobile No": element.MobileNo
        //"Inviter Cashback Koinz":element.inviterCashBackKoinz === ""? 'N/A':element.inviterCashBackKoinz
      }
      transHistory.push(obj)
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transHistory);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.utils..Sheets.Sheet1.A1 = { font: { bold: true } };

    /* save to file */
    XLSX.writeFile(wb, 'All Transaction-' + moment(new Date()) + '.xlsx', { cellStyles: true });
  }

  onActionTodoClick(event) {
    console.log(event);
    this.isAllTransaction = false;
    this.isIncidents = true;
  }

  onClickgoBack() {
    this.isAllTransaction = true;
    this.isIncidents = false;
  }

}
