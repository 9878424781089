import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MerchantsProfileHelperService } from '../../modules/merchants/merchant-mod/merchant-profile-mod/services/merchants-profile-helper.service';
import { ApprovalHelperService } from '../../modules/approval/on-boarding/_services/approval-helper.service';
import { DefaultApiResModel } from '../model/default-api-res.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private apiService: ApiService,
    private mpHelpService: MerchantsProfileHelperService,
    private aprlHelpService: ApprovalHelperService,) {
  }

  // getLogin(request, header) {
  //   return new Promise((resolve, reject) => {
  //     this.apiService.Login(request, header).subscribe((data) => {
  //       resolve(data);
  //     });
  //   });
  // }

  setSignUp(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.signup(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  getRestaurantList() {
    return new Promise((resolve, reject) => {
      this.apiService.RestaurantList().subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postRestaurantDetails(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postRestaurantDetails(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postItemDetails(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postItemDetails(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postBankDetails(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postBankDetails(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postTradeLicense(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postTradeLicenseDetails(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postGallery(request): Promise<DefaultApiResModel> {
    return new Promise((resolve, reject) => {
      this.apiService.postGallery(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postStandardDiscound(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postStandardDiscound(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  postMerchantDetails(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postMerchantDetails(request).subscribe((data: any) => {
        // this.mpHelpService.selectedMerchant = data.data;
        // this.aprlHelpService.selectedMerchant = data.data;
        resolve(data);
      });
    });
  }

  public postKPINDetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postKPINDetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postEmployeeDetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postEmployeeDetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postVerifyCampaign(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postHotDeals(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postHappyHours(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postHappyHours(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postHotDeals(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postHotDeals(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postsecretdealsdetails(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postsecretdealsdetails(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getcampaignaudiencebyid(request) {
    return new Promise((resolve, reject) => {
      this.apiService.getcampaignaudiencebyid(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getcampaignaudience(request) {
    return new Promise((resolve, reject) => {
      this.apiService.getcampaignaudience(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postwordofmouthdetailss(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postwordofmouthdetailss(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postinappadsdetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postinappadsdetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postinactivecustomeroffer(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postinactivecustomeroffer(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postEnquiryform(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postEnquiryform(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postFirstTimeUser(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postFirstTimeUser(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getEmployeeDetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getEmployeeDetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getCampaigndetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getCampaigndetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getMerchantkpndetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getMerchantkpndetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getreputaiondetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getreputaiondetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public gettransactiondetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.gettransactiondetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getEnquiryFormList() {
    return new Promise((resolve, reject) => {
      this.apiService.getEnquiryFormList().subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postEnquiryFormList(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postEnquiryFormList(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getMerchantlist() {
    return new Promise((resolve, reject) => {
      this.apiService.getMerchantlist().subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postMerchantlist(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postMerchantlist(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postrestaurantbanner(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postrestaurantbanner(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postForgetpassword(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.postForgetpassword(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public PostApprovecampaign(request) {
    return new Promise((resolve, reject) => {
      this.apiService.PostApprovecampaign(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public postApproveads(request) {
    return new Promise((resolve, reject) => {
      this.apiService.postApproveads(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getCampaignListAll() {
    return new Promise((resolve, reject) => {
      this.apiService.getCampaignListAll().subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getdashboarddetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getdashboarddetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }


  public getmycampaigndetails(request) {
    return new Promise((resolve, reject) => {
      this.apiService.getmycampaigndetails(request).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getcustomertypedetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getcustomertypedetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public getengagementdetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getengagementdetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }


  public getrevenueperformancedetails(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.getrevenueperformancedetails(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }


  public getstandardlist() {
    return new Promise((resolve, reject) => {
      this.apiService.getstandardlist().subscribe((data: any) => {
        resolve(data);
      });
    });
  }


  public sendMail(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.sendMail(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  public deleteGalleryImages(request, header) {
    return new Promise((resolve, reject) => {
      this.apiService.deleteMenuCardImages(request, header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }
  public deleteMenuCardImages(request,header){
    return new Promise((resolve, reject) => {
      this.apiService.deleteMenuCardImages(request,header).subscribe((data: any) => {
        resolve(data);
      });
    });
  }
}
