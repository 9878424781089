<div class="container-fluid" style="
    scroll-behavior: smooth;
    overflow-y: auto;
    margin-top: 20px;
    background-color: #fff;
  ">
  <div class="d-flex justify-content-end dialog-close mb-2"></div>
  <!-- Content Row -->
  <div class="card card-bg shadow py-2" style="width: 97%">
    <div class="cardbody" style="background-color: #fff">
      <div class="text-xs font-weight-bold text-primary-db mb-0" style="color: #f33835 !important; font-size: 14px">
        {{restaurantName}}
      </div>
      <div class="row" style="margin-top: 32px">
        <div class="col-xl-3 col-md-6 mb-4 card-content">
          <div class="card card-bg shadow ht-50 py-2 box-shdow">
            <div class="cardbody">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 ml-2">
                  <div class="h11 mb-2 font-weight-bold text-gray-800 height90" style="color: #201e1e !important">
                    AED {{ responsedata?.offlinePaidAmount }}
                  </div>
                  <div class="text-xs font-weight-bold text-primary-db text-uppercase mb-0"
                    style="color: #f33835 !important">
                    OFFLINE PAID AMOUNT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4 card-content">
          <div class="card card-bg shadow ht-50 py-2 box-shdow">
            <div class="cardbody">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 ml-2">
                  <div class="h11 mb-2 font-weight-bold text-gray-800 height90" style="color: #201e1e !important">
                    AED {{ responsedata?.onlinePaidAmount }}
                  </div>
                  <div class="text-xs font-weight-bold text-primary-db text-uppercase mb-0"
                    style="color: #f33835 !important">
                    ONLINE PAID AMOUNT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4 card-content">
          <div class="card card-bg shadow ht-50 py-2 box-shdow">
            <div class="cardbody">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 ml-2">
                  <div class="h11 mb-2 font-weight-bold text-gray-800 height90" style="color: #201e1e !important">
                    AED {{ responsedata?.totalAmount }}
                  </div>
                  <div class="text-xs font-weight-bold text-primary-db text-uppercase mb-0"
                    style="color: #f33835 !important">
                    TOTAL AMOUNT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 15px">
    <mat-tab-group style="width: 99%">
      <mat-tab label="Transaction History">
        <div class="card card-bg shadow py-2" style="width: 97% !important; margin-top: 25px; margin-left: 15px">
          <div class="cardbody" style="background-color: #fff">
            <div class="row" style="margin-top: 5px">
              <div class="form-group col-md-12">
                <div class="fo1rm-row filter-control">
                  <div class="d-flex align-items-center col-md-12 pl-0">
                    <div class="form-group col-md-2 mb-0">
                      <!-- <label class="small mb-1" for="Days">Days</label> -->
                      <select class="form-control" (change)="changeDate($event.target.value)">
                        <option *ngFor="let item of datewiseList" [value]="item.textId">
                          {{ item.textValue }}
                        </option>
                      </select>
                    </div>

                    <input class="form-control col-md-7" (keyup)="applyFilter($event)" placeholder="Search" />
                    <div class="col-md-3" *ngIf="responsedata &&
                      responsedata.listTransactionHistory.length > 0">
                      <button style="float: right;" mat-raised-button color="warn" (click)="export()">Download to
                        excel</button>
                    </div>
                  </div>

                  <div class="d-flex align-items-center col-md-12 pl-0 mt-3" *ngIf="isCustom">
                    <div class="col-md-3 form-group d-flex align-items-center">
                      <input matSuffix class="form-control" required="true" [min]="minDate" [max]="maxDate"
                        [matDatepicker]="startPicker" id="sDate" type="text" [(ngModel)]="startDate"
                        placeholder="Enter start date" />
                      <mat-datepicker-toggle [for]="startPicker"></mat-datepicker-toggle>
                      <mat-datepicker #startPicker></mat-datepicker>
                    </div>

                    <div class="col-md-3 form-group d-flex align-items-center pl-0">
                      <input matSuffix class="form-control" [min]="minDateTo" [max]="maxDateTo"
                        [matDatepicker]="endPicker" id="eDate" type="text" [(ngModel)]="endDate"
                        placeholder="Enter end date" />
                      <mat-datepicker-toggle [for]="endPicker"></mat-datepicker-toggle>
                      <mat-datepicker #endPicker></mat-datepicker>
                    </div>
                    <div class="form-group col-md-2 d-flex">
                      <mat-icon (click)="customSearch()" style="font-size: 26px; cursor: pointer">
                        search
                      </mat-icon>
                    </div>
                  </div>
                </div>

                <div class="transaction-history" style="position: relative; overflow: auto">
                  <div style="width: 225em; position: relative">
                    <div class="table-container" *ngIf="
                        responsedata &&
                        responsedata.listTransactionHistory &&
                        transCols.length > 0 &&
                        displayedColumns.length > 0
                      " class="mat-elevation-z2">
                      <table mat-table class="keeperz-table-v1" [dataSource]="dataSource" matSort>
                        <ng-container *ngFor="let col of transCols">
                          <ng-container [matColumnDef]="col.column">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              {{ col?.title }}
                            </th>
                            <ng-container  *ngIf="col.type !== colTypeModel.BUTTON">
                              <td mat-cell *matCellDef="let row">
                                {{
                                row && row[col.column] ? row[col.column] : 'N/A'
                                }}
                              </td>
                            </ng-container>
                            <ng-container *ngIf="col.type === colTypeModel.BUTTON">
                              <td mat-cell *matCellDef="let row">
                                <button [class]="col.btnClass" (click)="col.command(row)">
                                  {{ col.btnText }}
                                </button>
                              </td>
                            </ng-container>
                            <!-- <ng-container *ngIf="col.type === colTypeModel.EDIT">
                              <td mat-cell *matCellDef="let row">
                                <span
                                class="badge badge-warn status-button"
                                (click)="editRowEditClicked(row)"
                                >Edit</span>
                              </td>
                            </ng-container> -->
                          </ng-container>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="
                            let row;
                            columns: displayedColumns;
                            let i = index
                          "></tr>
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4">
                            {{
                            responsedata &&
                            responsedata.listTransactionHistory.length === 0
                            ? 'No data.'
                            : 'No data matching the filter.'
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="mt-3 d-flex justify-content-start">
                      <mat-paginator #paginator [showFirstLastButtons]="true" [pageSize]="10" [length]="totalrows" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- <mat-tab label="Invoices">
        <div
          class="card card-bg shadow py-2"
          style="width: 97% !important; margin-top: 5px; margin-left: 15px"
        >
          <div class="cardbody" style="background-color: #fff">
            <div class="row" style="margin-top: 5px">
              <div class="form-group col-md-12">
                <div
                  class="container"
                  style="padding-left: 0px !important"
                  *ngIf="responsedata && responsedata.listTransactionInvoices"
                >
                  <app-datatable
                    [cols]="invoiceCols"
                    [data]="responsedata.listTransactionInvoices"
                    styleClass="v2"
                    [showShadow]="false"
                  ></app-datatable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab> -->
    </mat-tab-group>
  </div>
  <div *ngIf="isSuccess" class="alert alert-success  alert-dismissible fade show" style="z-index: 999999;
    display: flex;
    position: fixed;
    margin: 25% 8%;
    width: 60%;
    place-content: center;top:30px">
    <mat-icon>check_circle_outline</mat-icon>
    <div style="width: 10px;"></div>
    {{message}}
    <button type="button" class="close" (click)="onSuccessClose()" data-dismiss="alert">&times;</button>
</div>
</div>


