import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SettingsType} from '../../../modules/settings-mod/settings/settings.component';

export interface DialogData {
  selectedSettingsType: SettingsType;
}

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.scss']
})
export class EnterOtpComponent implements OnInit {
  form: FormGroup;

  constructor(
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<EnterOtpComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      digit1: ['', [Validators.required, Validators.maxLength(1)]],
      digit2: ['', [Validators.required, Validators.maxLength(1)]],
      digit3: ['', [Validators.required, Validators.maxLength(1)]],
      digit4: ['', [Validators.required, Validators.maxLength(1)]]
    });
  }

  submit() {
    this.dialogRef.close(Object.values(this.form.value).join().replace(/,/g, ''));
  }

}
