<div class="row" style="margin-top: 5px">
    <div class="form-group col-md-12">
        <div class="transaction-history" style="position: relative; overflow: auto">
            <div class="table-container" *ngIf="
            this.walletService.closedIncidentList &&
            this.walletService.closedIncidentList.length > 0 &&
                  incidentsCols.length > 0" class="mat-elevation-z2">
                <div style="width: 225em; position: relative;height: 330px;">
                    <app-datatable #table mat-table [data]="this.walletService.closedIncidentList" [cols]="incidentsCols"
                        class="keeperz-table-v1" matSort [showPaginator]="true" (onRowClicked)="onRowClicked($event)"
                        [pageSize]="10" [showPageSizeOptions]="true" [length]="totalRows" styleClass="v3"
                        (onRowEditClicked)="onReportEditClick($event)" (pageEvent)="onPageEvent($event)"
                        [paginaterAlignLeft]="true">
                    </app-datatable>
                </div>
            </div>
        </div>
    </div>
</div>