<div class="card card-bg  mat-elevation-z2 py-2 box-shdow pt-3">
    <div class="cardbody" style="padding: 25px;">
        <h6 class="clr-red mb-3" *ngIf="title">{{title}}</h6>
        <div class="cc-value text-xs" *ngIf="value">{{value}}</div>
        <div class="cc-graph-title text-xs text-primary-db" *ngIf="graphTitle">{{graphTitle}}</div>
        <div class="mt-2 mb-2">
            <app-charts [chartConfig]="chartCardDetails.chartConfig"
                        [chartDataset]="chartCardDetails.dataset" [chartLabels]="chartCardDetails.labels"></app-charts>
        </div>
    </div>
</div>
