<div id="modal-content-wrapper">
    <div class="form-row">
        <div class="form-group col-md-4">
           
            <!-- <input class="form-control" id="address" type="text"
                
                 placeholder="Enter address"> -->
                 <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
        </div>
        <div class="form-group col-md-8" style="padding-left: 18vw;">
            Address: {{address}}
        </div>
    </div>
      <section>
        <!-- (dragEnd)="markerDragEnd($event)" -->
        <agm-map [latitude]="latitude" [longitude]="longitude" (mapClick)="onChoseLocation($event)" [zoom]="zoom">
            <agm-marker [latitude]="latitude" [longitude]="longitude"  [markerDraggable]="true" *ngIf="locationChoose"></agm-marker>
          </agm-map>
           
        </section>
        <footer id="modal-footer">
            <div style="margin-top: 15px;">Latitude: {{latitude}}</div>
            <div>Longitude: {{longitude}}</div>
            <div style="display: flex;place-content: flex-end;align-items: end;margin-top: 10px;">
                <button style="background-color: #e3191f !important;" (click)="onMap()" class="btn btn-primary" type="button"
                    id="modal-action-button">Confirm Location</button>
            </div>
        
        </footer>
    </div>
