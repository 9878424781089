import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { WalletService } from '../services/wallet.service';
import { NotificationService } from "../../../shared/services/notification.service";
import { ReversalIncidentListComponent } from "../reversal-incident-list/reversal-incident-list.component";
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
@Component({
  providers: [ReversalIncidentListComponent],
  selector: 'app-report-incident-dialog',
  templateUrl: './report-incident-dialog.component.html',
  styleUrls: ['./report-incident-dialog.component.scss']
})
export class ReportIncidentDialogComponent implements OnInit {

  title: string;
  message: string;
  isReportNeeded = false;
  issueText = '';
  isSuccess: boolean;
  isImageError: boolean;
  reportTransactionForm: FormGroup;
  imageError: string;
  selectedRow: any;
  modifyReasonList: any;
  cardImageBase64: any;
  isImageSaved: boolean;
  constructor(public dialogRef: MatDialogRef<ReportIncidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportIncidentDialogModel, private walletService: WalletService,
    private notificationService: NotificationService,
    private reversalIncidentListComponent: ReversalIncidentListComponent,
    private fb: FormBuilder) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.selectedRow = this.data.rowData
  }

  ngOnInit() {
    this.reportTransactionForm = this.fb.group({
      modifyReason: [''],
      billAmount: [''],
      additionalComments: [''],
      exclusionAmount: [''],
      imgBase64: ['']
    })

    this.reportTransactionForm.controls['billAmount'].setValue(this.selectedRow.billAmount);
    this.reportTransactionForm.controls['exclusionAmount'].setValue(this.selectedRow.exclusionAmount);

    // this.modifyReasonList = [
    //   { id: 1, reasonText: 'Wrong Bill Amount' },
    //   { id: 2, reasonText: 'Reversal Only' }
    // ]


    this.walletService.getTransactionReason().subscribe(res => {
      this.modifyReasonList = res.data;
    });


  }

  onConfirm(): void {
    this.isReportNeeded = true;
  }

  onSubmit() {
    // Close the dialog, return true
    this.creteNewIncident(this.issueText, this.data.rowData);
    
    //this.dialogRef.close({ issue: this.issueText, isSubmit: true });
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  // async reportClicked(selectedRow) {
  //   console.log(selectedRow);
  //   const message = "ARE YOU SURE YOU WANT TO REPORT THIS TRANSCATION";
  //   const dialogData = new ConfirmDialogModel('Confirm', message);
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     maxWidth: '500px',
  //     data: dialogData,
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult.isSubmit) {
  //       console.log(dialogResult);
  //       this.creteNewIncident(dialogResult, selectedRow);
  //     }
  //   });
  // }


  creteNewIncident(issuetxt, selectedTxn) {
    // const request = {
    //   restaurantId: selectedTxn.restaurantId,
    //   branchId: "",
    //   "userId": Number(selectedTxn.userId),
    //   "transactionNo": selectedTxn.transactionNo,
    //   "incidentReport": issuetxt,
    //   "updatedBy": 1
    // }
    const request = {
      restaurantId: selectedTxn.restaurantId,
      branchId: "",
      "userId": Number(selectedTxn.userId),
      "transactionNo": selectedTxn.transactionNo,
      "incidentReport": this.reportTransactionForm.value.additionalComments,
      "updatedBy": 1,
      "reasonType": this.getModifyReason(),
      "additionalImageContent": this.reportTransactionForm.value.imgBase64
    }

    // let reportObj = {
    //   modifyReason: this.getModifyReason(),
    //   billAmount: this.reportTransactionForm.value.billAmount,
    //   additionalComments: this.reportTransactionForm.value.additionalComments,
    //   exclusionAmount: this.reportTransactionForm.value.exclusionAmount,
    //   baseImg64: this.reportTransactionForm.value.imgBase64
    // }

    this.walletService.CreateIncident(request).subscribe(response => {
      if (!response.isError) {
        this.reversalIncidentListComponent.getAllIncidents(false);
        //this.isSuccess = true;
        this.message = 'Wrong Transaction incident created in the System, Keeperz’s Finance Team will review and get back to you Shortly. In-case of emergency you can send email to vendordispute@keeperz.ae';
        this.dialogRef.close({isSucces : true, message : this.message });
        //this.notificationService.showSuccess(this.message, 'Success');
        // let interval = setInterval(() => {
        //   this.isSuccess = false;
        //   this.dialogRef.close(false);
        //   clearInterval(interval);
        // }, 10000)
      }
      else {
        //this.isImageError = true;
        this.imageError = "Error in report creation"
        this.notificationService.showError(this.imageError, 'Error');
        // let interval = setInterval(() => {
        //   this.isImageError = false;
        //   clearInterval(interval);
        // }, 5000)
      }
    });;
  }

  getModifyReason() {
    let selectedReason = this.modifyReasonList.filter(x => x.Id === this.reportTransactionForm.value.modifyReason);
    if (selectedReason.length > 0) {
      return selectedReason[0].ReasonType;
    }
    else {
      return ""
    }
  }

  onSuccessClose() {
    this.isSuccess = false;
  }

  fileChangeEvent(fileInput: any, cImage: FormGroup, type) {
    this.imageError = null;
    this.isImageError = false;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 1000000;
      const allowed_types = ['image/png', 'image/jpeg', 'image/webp', 'image/jpg', 'application/pdf', 'image/avif'];
      const max_height = 4000;
      const max_width = 4000;

      if (fileInput.target.files[0].size > max_size) {
        this.isImageError = true;
        this.imageFormClear(cImage, type)
        this.imageError =
          'Maximum size allowed is 1 MB';

        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.isImageError = true;
        this.imageFormClear(cImage, type)
        this.imageError = 'Only Images are allowed ( JPG | PNG | WEBP | AV1 | PDF)';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > max_height && img_width > max_width) {
            this.imageFormClear(cImage, type)
            this.isImageError = true
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              '*' +
              max_width +
              'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            if (type === "uploadImg") {
              cImage.controls.imgBase64.setValue(imgBase64Path);
              console.log(imgBase64Path);
            }

            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }


  }
  imageFormClear(cImage: FormGroup, type) {
    if (type === "uploadImg") {
      cImage.controls.uploadImg.setValue("");
    }

  }
}

export class ReportIncidentDialogModel {

  constructor(public title: string, public message: string, public rowData: any) {
  }
}
