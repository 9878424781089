import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ApprovalHelperService {
  /** Merchant Details */
  selectedMerchant;
  /** Enquiry Details */
  selectedEnquiry;
  merchantRegistrationId;
  constructor() {}
}
