import { Component, Inject, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NotificationService } from '../../../shared/services/notification.service';
import { SharedService } from '../../../shared/services/shared.service';
import { WalletService } from '../services/wallet.service';

@Component({
  selector: 'app-reversal-txn-dialog',
  templateUrl: './reversal-txn-dialog.component.html',
  styleUrls: ['./reversal-txn-dialog.component.scss']
})
export class ReversalTxnDialogComponent implements OnInit {

  currentUserWalletDetails: any;
  reversaltxnsForm: FormGroup;
  reversaltxnsPreview = false;
  topupReason = [];
  isExistApprovalCode = false;
  requestOTP = false;
  UserName = '';
  CoupenTypeList = [];
  isUservalidated = false;
  UserDetails: any;
  currentWalletBalence = '';
  txnsText = '';
  allowedChars = new Set('0123456789'.split('').map(c => c.charCodeAt(0)));
  adminMobileNumList: any;
  selectedAdminNumber: any;
  selectedAdminName: any;
  showVerify: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private MatDialogRef: MatDialogRef<ReversalTxnDialogComponent>,
    private notificationService: NotificationService,
    private walletService: WalletService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.buildForm();
  }


  buildForm() {
    this.reversaltxnsForm = this.fb.group({
      mobileNumber: ['', Validators.required],
      billAmount: ['', Validators.required],
      PhoneNumber: [''],
      OTPCode: [''],
      exclusionAmount: [''],
    })

    this.reversaltxnsForm.controls['OTPCode'].disable();

    if (this.data.col.column === 'newtxns') {
      this.txnsText = this.data.col.title;

      this.getCoupenTypeList();
    }
    else {
      this.txnsText = this.data.col.title;
      if (this.data.col.txnType === 2) {
        this.reversaltxnsPreview = true;
        this.reversaltxnsForm.controls['mobileNumber'].setValue(this.data.selectedRow.mobileNo);
        this.reversaltxnsForm.controls['billAmount'].setValue(this.data.selectedRow.billAmount);
        this.reversaltxnsForm.controls['exclusionAmount'].setValue(this.data.selectedRow.exclusionAmount);
      }
      else {

        let result = this.data.selectedRow.mobileNo.replace("+", " ");
        this.reversaltxnsForm.controls['mobileNumber'].setValue(result.trim());
        this.validateMobileNumber();
        this.reversaltxnsForm.controls['mobileNumber'].disable();
      }
    }

    this.walletService.getAdminNameList().subscribe(res => {
      this.adminMobileNumList = res.data;
    })

    // this.adminMobileNumList = [
    //   { Name: "Rabie", Number: "971551990189" },
    //   { Name: "Amr", Number: "971504979445" },
    //   { Name: "Thahir", Number: "971552032103" },
    //   { Name: "Zan", Number: "971551025358" },
    //   { Name: "Admin", Number: "97111111111" },
    // ]
  }

  // onChange(event) {
  //   this.selectedAdminNumber = event.mobileNo;
  //   this.selectedAdminName = event.adminName;
  //   this.requestOTP = true;
  // }

  onChange(e) {
    let selectedAdmin = this.getSelectedName(e.value);
    this.selectedAdminNumber = selectedAdmin.mobileNo;
    this.selectedAdminName = selectedAdmin.adminName;
    this.requestOTP = true;
  }

  getSelectedName(adminId) {
    return this.adminMobileNumList.find(admin => admin.adminId === adminId);
  }




  check(event: KeyboardEvent) {

    // 31 and below are control keys, don't block them.
    if (event.keyCode > 31 && !this.allowedChars.has(event.keyCode)) {
      event.preventDefault();
    }

    //this.validateOTP();
  }


  validatePhoneNumber() {
    if (this.reversaltxnsForm.value.PhoneNumber.length === 12) {
      this.requestOTP = true;
    }
    else {
      this.requestOTP = false;
    }
  }

  validateOTP() {
    if (this.reversaltxnsForm.value.OTPCode.length > 0) {
      this.showVerify = true;
    }
    else {
      this.showVerify = false;
    }
  }


  validateMobileNumber() {
    if (this.data.col.txnType === 3 && (this.reversaltxnsForm.value.mobileNumber.length === 12)) {
      const payload = {
        "mobileNumber": this.reversaltxnsForm.value.mobileNumber,
      }

      this.walletService.validateUser(payload).subscribe(res => {
        if (!res.isError) {
          if (res.message === "Record does not exists") {
            this.notificationService.showError('Error', "User is not registered");
          }
          else {
            this.isUservalidated = true;
            this.UserDetails = res.data.userDetails;
          }
        }
      })
    }

  }


  getCoupenTypeList() {
    const payload = {
      "restaurantId": this.sharedService.selectedMerchant.restaurantId,
      "branchId": this.sharedService.selectedMerchant.branchId,
    }

    this.walletService.getCoupennTypes(payload).subscribe(res => {
      if (!res.isError) {
        this.CoupenTypeList = res.data;
        this.reversaltxnsForm.addControl('coupenType', new FormControl('Standard', Validators.required));
      }
    })
  }

  onClickRequestCode() {
    const payload = {
      "restaurantId": this.data.selectedRow.restaurantId,
      "branchId": "",
      "userId": 0,
      "transactionNo": this.data.selectedRow.transactionNo,
      "incidentReport": "",
      "updatedBy": this.sharedService.users.merchantRegistrationId,
      "mobileNumber": this.selectedAdminNumber
    }
    this.walletService.getReversaltxnsOPT(payload).subscribe(res => {
      if (!res.isError) {
        this.notificationService.showSuccess(res.message, 'Success');
        this.reversaltxnsForm.controls['OTPCode'].enable();
        this.isExistApprovalCode = true;
        this.requestOTP = false;
        setInterval(() => {
          this.requestOTP = true;
        }, 60000)
      }
    });
  }

  onClickSubmit() {
    this.reversaltxnsPreview = true;
    this.reversaltxnsForm.controls['mobileNumber'].enable();
    //this.reversaltxnsForm.controls['OTPCode'].disable();
  }

  onClickVerify() {

    const payload = {
      "restaurantId": this.data.selectedRow.restaurantId,
      "branchId": this.data.selectedRow.branchId,
      "userId": this.data.selectedRow.userId,
      "transactionNo": this.data.selectedRow.transactionNo,
      "incidentReport": this.data.selectedRow.incidentReport,
      "updatedBy": this.sharedService.users.merchantRegistrationId,
      "mobileNumber": this.selectedAdminNumber,
      "OTP": this.reversaltxnsForm.value.OTPCode
    }


    this.walletService.verfifyUserOTP(payload).subscribe(res => {
      if (!res.isError) {
        this.Transaction();
      }
      else {
        this.notificationService.showError('Error in Verifying the OTP', 'Error');
      }
    })
  }

  Transaction() {
    let Payload;
    if (this.data.col.column === 'newtxns') {
      Payload = {
        "restaurantId": this.sharedService.selectedMerchant.restaurantId,
        "branchId": this.sharedService.selectedMerchant.branchId,
        "userId": this.UserDetails.userId,
        "currentTime": moment(new Date()).format('YYYY-MM-DD hh:mm A'),
        "billAmount": this.reversaltxnsForm.value.billAmount,
        "exclusiveAmount": this.reversaltxnsForm.value.exclusionAmount === "" ? "0" : this.reversaltxnsForm.value.exclusionAmount,
        "updatedBy": this.sharedService.users.merchantRegistrationId,
        "couponType": this.reversaltxnsForm.value.coupenType,
        "payMode": "cash",
        "adminMobileNo": this.selectedAdminNumber,
        "adminName": this.selectedAdminName,
        "discountTypeId": this.getdiscountAndCampaignId(this.reversaltxnsForm.value.coupenType, 'discountTypeId'),
        "campaignId": this.getdiscountAndCampaignId(this.reversaltxnsForm.value.coupenType, 'campaignId'),
      }
    }
    else if (this.data.col.column !== 'newtxns') {
      Payload = {
        "transactionType": this.data.col.txnType,
        "restaurantId": this.data.selectedRow.restaurantId,
        "branchId": this.data.selectedRow.branchId,
        "userId": this.data.selectedRow.userId,
        "prevInvoiceTransactionNo": this.data.selectedRow.reversalTransactionNo,
        "currentTime": moment(new Date()).format('YYYY-MM-DD hh:mm A'),
        "billAmount": this.reversaltxnsForm.value.billAmount,
        "exclusiveAmount": this.reversaltxnsForm.value.exclusionAmount === "" ? "0" : this.reversaltxnsForm.value.exclusionAmount,
        "updatedBy": this.sharedService.users.merchantRegistrationId.toString(),
        "adminMobileNo": this.selectedAdminNumber,
        "adminName": this.selectedAdminName
      }
    }

    if (this.data.col.column !== "newtxns") {
      this.walletService.reversalTransaction(Payload).subscribe(res => {
        if (!res.isError) {
          let message = 'Reversal Transaction is completed successfully';
          this.notificationService.showSuccess(message, 'Success');
        }
        else {
          this.notificationService.showError('Error in Verifying the transaction', 'Error');
        }
        this.MatDialogRef.close();
      })
    }
    else if (this.data.col.column === "newtxns") {
      this.walletService.NewTransaction(Payload).subscribe(res => {
        if (!res.isError) {
          let message = 'New Transaction is completed successfully';
          this.notificationService.showSuccess(message, 'Success');
        }
        else {
          this.notificationService.showError('Error in Verifying the transaction', 'Error');
        }
        this.MatDialogRef.close();
      })
    }

  }

  getdiscountAndCampaignId(coupenType, attributeType) {
    let campaignIddata = this.CoupenTypeList.filter(x => x.couponName === coupenType);
    if (campaignIddata.length > 0) {
      if (attributeType === 'campaignId') {
        return campaignIddata[0].campaignId;
      }
      else if (attributeType === 'discountTypeId') {
        return campaignIddata[0].discountTypeId;
      }
    }
    else {
      return "";
    }
  }

  Onclose() {
    this.MatDialogRef.close();
  }
}
