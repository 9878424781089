import { Injectable } from "@angular/core";
import {
  MerchantRequestModel,
  MerchantsDataService,
} from "../../../services/merchants-data.service";
import {
  ItemDetails,
  MerchantDetailsDataModel,
  MerchantRestaurantModel,
} from "../../../models/merchant-details.model";
import { RestaurantDetailsModel } from "../models/rest-details.model";
import * as moment from "moment";
import { DetailsListCardModel } from "../../../../../shared/components/details-list-card/details-list-card.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RestaurantMasterService } from "../../../../../shared/services/helper/restaurant-master.service";
import { SharedService } from "src/app/shared/services/shared.service";

export interface ILoyaltyDiscount {
  loyaltyDiscountId: number;
  startDate: string;
  targetAmount: string;
  platinumLevel: string;
  platinumDuration: string;
  platinumCashBack: string;
  goldLevel: string;
  goldDuration: string;
  goldCashBack: string;
  silverLevel: string;
  silverDuration: string;
  silverCashBack: string;
}

export interface IloyaltyPayload {
  merchantRegistrationId: number;
  branchId: string;
  restaurantId: number;
  loyaltyDiscount: ILoyaltyDiscount[];
}
@Injectable({
  providedIn: "root",
})
export class MerchantsProfileHelperService {
  recommendData: any;
  selectedMerchant: MerchantDetailsDataModel;
  selectedRestaurant: MerchantRestaurantModel;
  restaurantDetails: RestaurantDetailsModel;
  itemDetails: ItemDetails[] = [];
  bankDetails: DetailsListCardModel[] = [];
  tradeDetails: DetailsListCardModel[] = [];
  requestModel: MerchantRequestModel;
  selectedResDetails: any;
  loyaltyTblDetails;
  selectedRestaurantCityAndLocality: string;

  constructor(
    private merchantDataService: MerchantsDataService,
    private route: ActivatedRoute,
    private router: Router,
    private restaurantMasterService: RestaurantMasterService,
    private shared: SharedService
  ) {

  }

  init() {
    const urlSplit = this.router.url.split("/");
    this.requestModel = {
      branchId: urlSplit[3],
      restaurantId: +urlSplit[2],
    };
    this.merchantDataService
      .getMerchantDetails(this.requestModel)
      .subscribe((res) => {
        if (res && res.data) {
          this.selectedResDetails = res.data.restaurantList[0];
          this.shared.setMerchantDetails = res.data.restaurantList[0];
          this.shared.merchantRegistrationId = res.data.restaurantList.find(
            (res) => res.restaurantId === this.requestModel.restaurantId
          ).merchantRegistrationId;
          this.shared.restaurantId = this.requestModel.restaurantId;
          this.shared.branchId = this.requestModel.branchId;
          sessionStorage.setItem("branchId", this.shared.branchId);
          sessionStorage.setItem(
            "restaurantId",
            this.shared.restaurantId.toString()
          );
          let premiumDiscountArr = res.data.restaurantList[0].premiumDiscount;
          if (premiumDiscountArr && premiumDiscountArr.length) {
            let premiumDiscount = res.data.restaurantList[0].premiumDiscount[0];
            this.shared.premiumDiscountId = premiumDiscount.premiumDiscountId;
            this.shared.premiumDiscount = premiumDiscount;
          }

          let loyaltyDiscountId;
          if (this.selectedResDetails.loyaltyDiscount.length) {
            loyaltyDiscountId =
              this.selectedResDetails.loyaltyDiscount[0].loyaltyDiscountId;
          }
          this.shared.loyaltyDiscountId = loyaltyDiscountId;
          this.selectedMerchant = this.setFilterData(res.data);
          this.selectedRestaurantCityAndLocality = this.restaurantMasterService.getLocality(this.selectedMerchant.restaurantList[0].locality) + ", " + this.restaurantMasterService.getCity(this.selectedMerchant.restaurantList[0].cityId);
          this.loadData();
        }
      });
  }

  setFilterData(resData: MerchantDetailsDataModel): MerchantDetailsDataModel {
    resData.restaurantList[0].restaurantServices =
      resData.restaurantList[0].restaurantServices.map((ser) => {
        ser.item_id = ser.RestaurantServicesId;
        ser.item_text = ser.RestaurantServicesName;
        return ser;
      });
    resData.restaurantList[0].restaurantTypes =
      resData.restaurantList[0].restaurantTypes.map((ser) => {
        ser.item_id = ser.RestaurantTypeId;
        ser.item_text = ser.RestaurantTypeName;
        return ser;
      });
    resData.restaurantList[0].cusineDetails =
      resData.restaurantList[0].cusineDetails.map((ser) => {
        ser.item_id = ser.cusineId;
        ser.item_text = ser.cusineName;
        return ser;
      });
    resData.restaurantList[0].specialityDetails =
      resData.restaurantList[0].specialityDetails.map((ser) => {
        ser.item_id = ser.specialityId;
        ser.item_text = ser.specialityName;
        return ser;
      });
    resData.restaurantList[0].ambienceDetails =
      resData.restaurantList[0].ambienceDetails.map((ser) => {
        ser.item_id = ser.ambienceId;
        ser.item_text = ser.ambienceName;
        return ser;
      });
    resData.restaurantList[0].suiteDetails =
      resData.restaurantList[0].suiteDetails.map((ser) => {
        ser.item_id = ser.suiteId;
        ser.item_text = ser.suiteName;
        return ser;
      });
    resData.restaurantList[0].storeTagDetails =
      resData.restaurantList[0].storeTagDetails.map((ser) => {
        ser.item_id = ser.TagId;
        ser.item_text = ser.TagName;
        return ser;
      });
    resData.restaurantList[0].paymentDetails =
      resData.restaurantList[0].paymentDetails.map((ser) => {
        ser.item_id = ser.paymentId;
        ser.item_text = ser.paymentName;
        return ser;
      });
    resData.restaurantList[0].facilityDetails =
      resData.restaurantList[0].facilityDetails.map((ser) => {
        ser.item_id = ser.facilityId;
        ser.item_text = ser.facilityName;
        return ser;
      });
    return resData;
  }

  clear() {
    this.selectedMerchant = null;
  }

  loadData() {
    this.restaurantDetails = {};
    this.selectedRestaurant = this.selectedMerchant.restaurantList[0];
    if (this.selectedRestaurant) {
      this.loadRestaurentDetails(this.selectedRestaurant);
      this.loadItemDetails(this.selectedRestaurant);
      this.loadBankDetails(this.selectedRestaurant);
      this.loadTradeDetails(this.selectedRestaurant);
      this.loadOfferDetails(this.selectedRestaurant);
    }
  }

  loadOfferDetails(restaurantData: MerchantRestaurantModel) {
    if (
      restaurantData.standardDiscount &&
      restaurantData.standardDiscount.length > 0
    ) {
      const details = restaurantData.loyaltyDiscount[0];
      if (details) {
        this.loyaltyTblDetails = [
          {
            level: "Platinum",
            duration: details.platinumDuration,
            cashback: details.platinumCashBack,
          },
          {
            level: "Gold",
            duration: details.goldDuration,
            cashback: details.goldCashBack,
          },
          {
            level: "Silver",
            duration: details.silverDuration,
            cashback: details.silverCashBack,
          },
        ];
      }
    }
  }

  loadTradeDetails(restaurantData: MerchantRestaurantModel) {
    if (
      restaurantData.tradeLicenseDetails &&
      restaurantData.tradeLicenseDetails.length > 0
    ) {
      const details = restaurantData.tradeLicenseDetails[0];
      if (details) {
        this.tradeDetails = [
          { label: "Trade License number", value: "**************" },
          {
            label: "Start Date",
            value: moment(details.startDate).format("DD/MM/YYYY"),
          },
          {
            label: "End Date",
            value: moment(details.endDate).format("DD/MM/YYYY"),
          },
          {
            label: "Authorized Person Designation",
            value: details.authorizePersonDesignation,
          },
          {
            label: "Authorized Person Email",
            value: details.authorizePersonEmail,
          },
          {
            label: "Authorized Person Name",
            value: details.authorizePersonName,
          },
          {
            label: "Is this Branch",
            value: details.isBranch === 1 ? "Yes" : "No",
          },
          { label: "Mother License Name", value: "********" },
          { label: "Mother License Number", value: "********" },
          {
            label: "Authorized Person Mobile",
            value: details.authorizePersonMobile,
          },
        ];
      }
    }
  }

  loadBankDetails(restaurantData: MerchantRestaurantModel) {
    if (restaurantData.bankDetails && restaurantData.bankDetails.length > 0) {
      this.bankDetails = [
        {
          label: "Name on Account",
          value: restaurantData.bankDetails[0].accountName,
        },
        { label: "IFSC Code", value: restaurantData.bankDetails[0].iFSCCode },
        {
          label: "Account Number",
          value: restaurantData.bankDetails[0].accountNumber,
        },
        {
          label: "Account Type",
          value:
            restaurantData.bankDetails[0].accountType === 0
              ? "Savings"
              : "Current",
        },
        { label: "IBAN Number", value: "******" },
        { label: "Address", value: restaurantData.bankDetails[0].address },
      ];
      // restaurantData.bankDetails[0].iBANNumber
    }
  }



  loadItemDetails(restaurantData: MerchantRestaurantModel) {
    this.itemDetails = restaurantData.itemDetails.map((tag) => {
      tag.tagName = this.restaurantMasterService.getTagName(tag.ItemTagId);
      tag.categoryName = this.restaurantMasterService.getItemCategoryName(
        tag.CategoryId
      );
      return tag;
    });
  }

  loadRestaurentDetails(restaurantData: MerchantRestaurantModel) {
    this.restaurantDetails.card1 = [
      { label: "Merchant Name", value: restaurantData.restaurantName },
      { label: "Address", value: restaurantData.address1 },
      { label: "LandMark/Street", value: restaurantData.address2 },
      { label: "Locality", value: restaurantData.locality },
      {
        label: "City",
        value: this.restaurantMasterService.getCity(restaurantData.cityId),
      },
      { label: "Latitude", value: restaurantData.latitude },
      { label: "Longitude", value: restaurantData.longitude },
      { label: "Zip/pin code", value: restaurantData.zipCode },
      {
        label: "Country",
        value: this.restaurantMasterService.getCountry(
          restaurantData.countryId
        ),
      },
    ];
    if (restaurantData.tradeLicenseDetails[0]) {
      this.restaurantDetails.card2 = [
        { label: "Capacity", value: restaurantData.capacity },
        {
          label: "Cost For Two Persons",
          value: restaurantData.avgPerPersonCost,
        },
        {
          label: "Authorized Person Designation",
          value:
            restaurantData.tradeLicenseDetails[0].authorizePersonDesignation,
        },
        {
          label: "Authorized Person Email",
          value: restaurantData.tradeLicenseDetails[0].authorizePersonEmail,
        },
        {
          label: "Authorized Person Name",
          value: restaurantData.tradeLicenseDetails[0].authorizePersonName,
        },
        {
          label: "Authorized Person Mobile",
          value: restaurantData.tradeLicenseDetails[0].authorizePersonMobile,
        },
      ];
    }
    this.restaurantDetails.card3 = [
      { label: "Website Link", value: restaurantData.websiteLink },
      { label: "Facebook Link", value: restaurantData.facebookLink },
      { label: "Telephone number", value: restaurantData.phoneNumber },
      { label: "Whatsapp number", value: restaurantData.watsappNumber },
    ];
    this.restaurantDetails.card4 = [
      {
        label: "Serves Alcochol",
        value: restaurantData.isServedAlcohol === 1 ? "Yes" : "No",
      },
      {
        label: "Shisha / Hokah",
        value: restaurantData.isShishaHookah === 1 ? "Shisha" : "Hokah",
      },
      {
        label: "Payments",
        value: restaurantData.paymentDetails.map((p) => p.paymentName).join(),
      },
      {
        label: "Services",
        value: restaurantData.restaurantServices
          .map((p) => p.RestaurantServicesName)
          .join(),
      },
      {
        label: "Cusine",
        value: restaurantData.cusineDetails.map((p) => p.cusineName).join(),
      },
      {
        label: "Speciality",
        value: restaurantData.specialityDetails
          .map((p) => p.specialityName)
          .join(),
      },
      {
        label: "Facilities",
        value: restaurantData.facilityDetails.map((p) => p.facilityName).join(),
      },
    ];
    this.restaurantDetails.card5 = [];
    const weekDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    restaurantData.restaurantTimeDetails.map((timeDet) => {
      const time = `${moment(timeDet.startDate).format("hh:ss a")} - ${moment(
        timeDet.endDate
      ).format("hh:ss a")}`;
      this.restaurantDetails.card5.push({
        label: weekDays[timeDet.weekDayId - 1],
        value: time,
      });
    });
  }
}
