import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-rating-card',
  templateUrl: './rating-card.component.html',
  styleUrls: ['./rating-card.component.scss']
})
export class RatingCardComponent implements OnInit {
  @Input() rating: string;
  @Input() title: string;
  ratingNumber: number;
  id: string;

  constructor() { }

  ngOnInit(): void {
    // tslint:disable-next-line:radix
    this.ratingNumber = parseInt(this.rating);
    this.id = this.title.replace(/ /g, '_');
  }

}
