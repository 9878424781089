import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterModule } from "@angular/router";
import { DatatableComponent } from "./datatable/datatable.component";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChartsComponent } from "./charts/charts.component";
import { NgChartjsModule } from "ng-chartjs";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { ApiService } from "./services/api.service";
import { NotificationService } from "./services/notification.service";
import { RegistrationService } from "./services/registration.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { RegistrationPendingModelComponent } from "./components/registration-pending-model/registration-pending-model.component";
import { AuthServices } from "./services/auth.service";
import { MatIconModule } from "@angular/material/icon";
import { HttpConfigInterceptor } from "./interceptor/http-interceptor";
import { DataCardSplitComponent } from "./components/data-card-split/data-card-split.component";
import { MatCardModule } from "@angular/material/card";
import { ChartCardComponent } from "./components/chart-card/chart-card.component";
import { BasicLayoutComponent } from "./layout-components/basic-layout/basic-layout.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { CdkTableModule } from "@angular/cdk/table";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateService } from "@ngx-translate/core";
import { MerchantHeaderProfileComponent } from "./layout-components/merchant-header-profile/merchant-header-profile.component";
import { DetailsListCardComponent } from "./components/details-list-card/details-list-card.component";
import { CustomToggleBtnsComponent } from "./components/custom-toggle-btns/custom-toggle-btns.component";
import { MatNativeDateModule } from "@angular/material/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTabsModule } from "@angular/material/tabs";
import { RatingCardComponent } from "./components/rating-card/rating-card.component";
import { NgxStarRatingModule } from "ngx-star-rating";
import { TitleCardComponent } from "./components/title-card/title-card.component";
import { EnterOtpComponent } from "./components/enter-otp/enter-otp.component";
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { MapdialogComponent } from "./components/mapdialog/mapdialog.component";
import { AgmCoreModule } from "@agm/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { PreviewDialogComponent } from "./components/preview-dialog/preview-dialog.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { ContractDetailsComponent } from "../modules/merchants/merchant-mod/merchant-profile-mod/components/contract-details/contract-details.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { DataTableDialogComponent } from './components/data-table-dialog/data-table-dialog.component';
import { ViewDialogComponent } from './components/view-dialog/view-dialog.component';
import { IdleTimeoutDialogComponent } from './components/idle-timeout-dialog/idle-timeout-dialog.component';
import { GoogleAddressDialogComponent } from './components/google-address-dialog/google-address-dialog.component';


@NgModule({
  declarations: [
    DatatableComponent,
    ChartsComponent,
    RegistrationPendingModelComponent,
    DataCardSplitComponent,
    ChartCardComponent,
    BasicLayoutComponent,
    MerchantHeaderProfileComponent,
    DetailsListCardComponent,
    CustomToggleBtnsComponent,
    RatingCardComponent,
    TitleCardComponent,
    EnterOtpComponent,
    ComingSoonComponent,
    MapdialogComponent,
    PreviewDialogComponent,
    ConfirmDialogComponent,
    ContractDetailsComponent,
    DataTableDialogComponent,
    ViewDialogComponent,
    IdleTimeoutDialogComponent,
    GoogleAddressDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatSidenavModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    CdkTableModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSlideToggleModule, // here otherwise it's not possible to use the component mat-slide-toggle defined in this module
    MatIconModule,
    MatCardModule,
    NgChartjsModule,
    MatSortModule,
    MatPaginatorModule,
    RouterModule,
    NgDynamicBreadcrumbModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgxStarRatingModule,
    AgmCoreModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatButtonModule
  ],
  providers: [
    ApiService,
    NotificationService,
    RegistrationService,
    //NgxSpinnerService,
    AuthServices,
    CurrencyPipe,
    DecimalPipe,
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    DatatableComponent,
    ChartsComponent,
    RegistrationPendingModelComponent,
    DataCardSplitComponent,
    ChartCardComponent,
    ConfirmDialogComponent,
    //NgxSpinnerModule,
    MatSlideToggleModule,
    MerchantHeaderProfileComponent,
    DetailsListCardComponent,
    CustomToggleBtnsComponent,
    RatingCardComponent,
    TitleCardComponent,
    ComingSoonComponent,
    MapdialogComponent,
    ContractDetailsComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    IdleTimeoutDialogComponent
  ],
})
export class SharedModule {}
