import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { SharedService } from '../../services/shared.service';
import { DataCardModel } from './model/data-card.model';
import * as moment from 'moment';

export interface DataCardConfig {
  header?: string;
  width?: string;
  customClass?: string;
}

export interface IKpinPayload {
  restaurantId: number;
  branchId: string;
}

export interface IKPinResponseData {
  assingedUser: string;
  kpin: string;
  status: string;
  isExpired: false;
  createdDate: string;
}

export interface IPinAndCreatedDate {
  pin: string;
  createdDate: string;
}

export interface IKpinResponseModel {
  statusCode: 200;
  message: 'Record updated';
  isError: false;
  data: IKPinResponseData[];
}

@Component({
  selector: 'app-data-card-split',
  templateUrl: './data-card-split.component.html',
  styleUrls: ['./data-card-split.component.scss'],
})
export class DataCardSplitComponent implements OnInit, OnDestroy {
  public selectedRestaurant;
  public pin: string;
  public remainingHours: string;
  public generatedPinDateTime: string;
  public generateKpinPayload: IKpinPayload;
  public getKpinPayload: IKpinPayload;
  public interval;
  public pinExists: boolean;
  public branchId;
  public restaurantId;
  @Input() displayPin: boolean;
  @Input() config: DataCardConfig;
  @Input() data: DataCardModel[] = [];
  @Output() onClick = new EventEmitter<DataCardModel>();
  @Output() onClickSend = new EventEmitter<DataCardModel>();
  @Output() onClickCheckStatus = new EventEmitter<DataCardModel>();
  isSalesRole: boolean;
  

  constructor(private api: ApiService, private shared: SharedService) { 
    this.isSalesRole = JSON.parse(localStorage.getItem('user')).role === 'Sales'
  }

  ngOnInit(): void {
    this.KpinSetup();
  }

  KpinSetup() {
    const selectedMerchant = this.shared.selectedMerchant;
    if (selectedMerchant) {
      this.getKpinPayload = {
        restaurantId: selectedMerchant.restaurantId,
        branchId: selectedMerchant.branchId,
      };
      this.api
        .getGeneratedKpin(this.getKpinPayload)
        .subscribe((resp: IKpinResponseModel) => {
          if (resp.data && resp.data.length > 0) {
            if (!resp.data[0].isExpired) {
              this.pin = resp.data[0].kpin;
              this.generatedPinDateTime = resp.data[0].createdDate;
              this.getRemainingHours();
            }
            else {
              this.pinExists = false;
            }
          }
          else{
            this.pinExists = false;
          }
        });
    }

  }

  generatePin() {
    const selectedMerchant = this.shared.selectedMerchant;
    if (selectedMerchant) {
      this.generateKpinPayload = {
        restaurantId: selectedMerchant.restaurantId,
        branchId: selectedMerchant.branchId,
      };
      this.api
        .generateKpin(this.generateKpinPayload)
        .subscribe((resp: IKpinResponseModel) => {
          if (!resp.data[0].isExpired) {
            this.pin = resp.data[0].kpin;
            this.generatedPinDateTime = resp.data[0].createdDate;
            this.getRemainingHours();
          }
          else{
            this.pinExists = false;
          }
        });
    }
  }

  getRemainingHours() {
    const createdDate = moment(this.generatedPinDateTime);
    const addOneDay = moment(createdDate).add(1, 'days').toString();
    const expiryDate = new Date(addOneDay).getTime();
    const timeinterval = setInterval(() => {
      const currentDate = new Date().getTime();
      var distance = expiryDate - currentDate;
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      if (hours <= 0 && minutes <= 0) {
        this.pinExists = false;
        this.remainingHours = "";
        clearInterval(timeinterval);
      }
      else {
        this.pinExists = true;
        this.remainingHours = hours + 'h : ' + minutes + 'm';
      }
    }, 1000)
  }

  setToLocalStorage() {
    localStorage.setItem('generatedPin', this.pin);
    localStorage.setItem('generatedPinDate', this.generatedPinDateTime);
    const selectedMerchant = this.shared.selectedMerchant;
    const generatedRestaurants = JSON.parse(
      localStorage.getItem('generatedFor')
    );

    if (
      Array.isArray(generatedRestaurants) &&
      generatedRestaurants.length &&
      !generatedRestaurants.includes(
        `${selectedMerchant.restaurantId}_${selectedMerchant.branchId}`
      )
    ) {
      generatedRestaurants.push(
        `${selectedMerchant.restaurantId}_${selectedMerchant.branchId}`
      );
      localStorage.setItem(
        'generatedFor',
        JSON.stringify(generatedRestaurants)
      );
    } else {
      localStorage.setItem(
        'generatedFor',
        JSON.stringify([
          { "merchant": "${selectedMerchant.restaurantId}_${selectedMerchant.branchId}", "KPin": this.pin },
        ])
      );
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
