export enum ColTypeModel {
  TEXT = 'text',
  DATE = 'date',
  MONEY = 'money',
  NUMBER = 'number',
  ACTION = 'action',
  COLOR = 'color',
  IMAGE = 'image',
  PERCENTAGE = 'percentage',
  BUTTON = 'button',
  PROGRESS = 'PROGRESS',
  EDIT = 'edit',
  TRANSACTION = 'transaction',
  COLORCODE = 'colorcode',
  STATUS = 'status',
  NOTIFICATION = 'notification',
  ACTIONDISABLE = 'actiondisable',
  LINKTEXT ='linktext'
}

export interface ColsModel {
  column: string;
  title: string;
  type: ColTypeModel;
  format?: string;
  currencyType?: string;
  colorObj?: string;
  /* For Button */
  btnClass?: string;
  btnText?: string;
  command?: any;
  targetCol?: string;
  achievedCol?: string;
  txnType?: number,
  isDisable?: boolean
}
