import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './shared/guard/auth.guard';
import {LeftNavBarComponent} from './shared/layout-components/left-nav-bar/left-nav-bar.component';
import {BasicLayoutComponent} from './shared/layout-components/basic-layout/basic-layout.component';
import {ComingSoonComponent} from './shared/components/coming-soon/coming-soon.component';

const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'login',
        loadChildren: () => import('./modules/user-common/signin-mod/signin.module').then(mod => mod.SigninModule)
      },
      {
        path: 'signup',
        loadChildren: () => import('./modules/user-common/signup-mod/signup.module').then(mod => mod.SignupModule)
      },
    ]
  },
  {
    path: '',
    component: LeftNavBarComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'approval',
        loadChildren: () => import('./modules/approval/approval.module').then(mod => mod.ApprovalModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(mod => mod.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'events',
        loadChildren: () => import('./modules/events/events.module').then(mod => mod.EventsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/notification/notification.module').then(mod => mod.NotificationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'merchants',
        loadChildren: () => import('./modules/merchants/merchants.module').then(mod => mod.MerchantsModule),
        canActivate: [AuthGuard]
      },{
        path: 'admin-employees',
        loadChildren: () => import('./modules/admin-employee/admin-employee.module').then(mod => mod.AdminEmployeeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings-mod/settings.module').then(mod => mod.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'marketing',
        loadChildren: () => import('./modules/marketing-mod/marketing.module').then(mod => mod.MarketingModule)
      },
      {
        path: 'reviews',
        loadChildren: () => import('./modules/reviews-mod/reviews.module').then(mod => mod.ReviewsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(mod => mod.ReportsModule)
      },
      {
        path: 'admin-loyalty',
        loadChildren: () => import('./modules/admin-loyalty/admin-loyalty.module').then(mod => mod.AdminloyaltyModule)
      },
      {
        path: 'wallet',
        loadChildren: () => import('./modules/wallet/wallet.module').then(mod => mod.WalletModule)
      },
      {
        path: 'notifications',
        component: ComingSoonComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
