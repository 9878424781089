import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-data-table-dialog',
  templateUrl: './data-table-dialog.component.html',
  styleUrls: ['./data-table-dialog.component.scss']
})
export class DataTableDialogComponent implements OnInit {
  datasource;
  dataColumns;
  totalRows;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, public matDialogRef: MatDialogRef<DataTableDialogComponent>
  ) { }

  ngOnInit(): void {
    this.datasource = this.data.datasource;
    this.totalRows = this.data.datasource.length;
    this.dataColumns = this.data.dataColumns;
  }

}
