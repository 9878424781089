import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { WalletService } from '../services/wallet.service';
import {
  ColsModel,
  ColTypeModel,
} from '../../../shared/datatable/datatable.model';
import {
  AdminWalletDetailsModel,
  ListTransactionInvoicesModel,
} from '../models/wallet-transaction-details.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as XLSX from 'xlsx';
import { ReportIncidentDialogComponent, ReportIncidentDialogModel } from "../report-incident-dialog/report-incident-dialog.component";
import { MatDialog } from '@angular/material/dialog';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-merchant-wallet-details',
  templateUrl: './merchant-wallet-details.component.html',
  styleUrls: ['./merchant-wallet-details.component.scss'],
})
export class MerchantWalletDetailsComponent implements OnInit, AfterViewInit {
  transactionHistoryList: any[] = [];
  minDate: Date;
  maxDate: Date;
  minDateTo: Date;
  maxDateTo: Date;
  datewiseList: any[] = [];
  startDate = '';
  endDate = '';
  restaurantName: string;
  colTypeModel;
  totalrows;
  isSuccess: any;
  message: any;
  constructor(
    public formbuilder: FormBuilder,
    private walletService: WalletService,
    private shared: SharedService,
    private dialog: MatDialog
  ) {
    const currentYear = new Date().getFullYear();
    // const currentMonth = new Date().getMonth();
    this.minDate = new Date(currentYear - 2, 0, 1);
    this.maxDate = new Date();

    this.minDateTo = new Date(currentYear - 2, 0, 1);
    this.maxDateTo = new Date();
    this.colTypeModel = ColTypeModel;
  }

  transCols: ColsModel[] = [
    /** Transaction Details */
    // {
    //   column: 'Report Transaction',
    //   title: 'Report Transaction',
    //   type: ColTypeModel.BUTTON,

    // },
    {
      column: 'action',
      title: 'Report',
      type: ColTypeModel.BUTTON,
      btnClass: 'btn btn-sm red pr-2 pl-2',
      btnText: 'Report',
      command: (res) => this.reportClicked(res),
    },
    {
      column: 'transactionId',
      title: 'Transaction Id',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'transactionNo',
      title: 'Ref Code',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'restaurantName',
      title: 'Merchant Name',
      type: ColTypeModel.TEXT,
    },
    { column: 'userId', title: 'User ID', type: ColTypeModel.TEXT },
    {
      column: 'userName',
      title: 'User Name',
      type: ColTypeModel.TEXT,
    },
    { column: 'inviterId', title: 'Inviter ID', type: ColTypeModel.TEXT },
    { column: 'transactionDate', title: 'Date', type: ColTypeModel.TEXT },
    { column: 'time', title: 'Time', type: ColTypeModel.TEXT },
    { column: 'voucherType', title: 'Voucher Type', type: ColTypeModel.TEXT },
    {
      column: 'billAmount',
      title: 'Bill Amount(AED)',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'exclusionAmount',
      title: 'Exclusion Amount(AED)',
      type: ColTypeModel.TEXT,
    },
    /** User Discount/Redemption Calculation */
    { column: 'kPINUsed', title: 'Redeemed By', type: ColTypeModel.TEXT },
    {
      column: 'discountPercentage',
      title: 'Discount Applied(%)',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'discountAmount',
      title: 'Discount Availed(AED)',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'billPaidAmount',
      title: 'Final Bill Amount(AED)',
      type: ColTypeModel.TEXT,
    },
    /** Payment Details */
    {
      column: 'paymentMethod',
      title: 'Payment Method',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'paymentReceivedBy',
      title: 'Received by',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'paymentStatus',
      title: 'Payment Status',
      type: ColTypeModel.TEXT,
    },
    { column: 'paymentRefCode', title: 'P/G RefCode', type: ColTypeModel.TEXT },
    {
      column: 'paymentProvider',
      title: 'Payment Provider Id',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'paymentFeesPercentage',
      title: 'P/G Fees(%)',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'paymentFeesValues',
      title: 'P/G Fees(Amount)',
      type: ColTypeModel.TEXT,
    },
    /** Keeperz Fees Calculation */
    { column: 'featureType', title: 'Transaction Type', type: ColTypeModel.TEXT },
    {
      column: 'transactionPercentage',
      title: 'Transaction Fees(%)',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'transactionCharges',
      title: 'Transaction Fees(AED)',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'inviterCashBackValue',
      title: 'Inviter Cashback(AED)',
      type: ColTypeModel.TEXT,
    },
    /** User Rewards */
    {
      column: 'redeemptionKoinz',
      title: 'Redemption Koinz',
      type: ColTypeModel.TEXT,
    },
    {
      column: "creditValue",
      title: "Redemption Cashback (AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: 'inviterCashBackKoinz',
      title: 'Inviter Cashback Koinz',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'paymentDoneBy',
      title: 'Payment Done By',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'MobileNo',
      title: 'Mobile No',
      type: ColTypeModel.TEXT,
    }
  ];

  invoiceCols: ColsModel[] = [
    { column: 'invoiceNo', title: 'Invoice No', type: ColTypeModel.TEXT },
    { column: 'invoiceDate', title: 'Invoice Date', type: ColTypeModel.TEXT },
    { column: 'description', title: 'Description', type: ColTypeModel.TEXT },
    {
      column: 'billAmount',
      title: 'Bill Amount',
      type: ColTypeModel.MONEY,
      currencyType: 'AED',
      format: '1.0-0',
    },
    {
      column: 'transactionCharge',
      title: 'Transaction Charges',
      type: ColTypeModel.MONEY,
      currencyType: 'AED',
      format: '1.0-0',
    },
    {
      column: 'netAmount',
      title: 'Net Amount',
      type: ColTypeModel.MONEY,
      currencyType: 'AED',
      format: '1.0-0',
    },
    { column: 'status', title: 'Status', type: ColTypeModel.TEXT },
    {
      column: 'action',
      title: '',
      type: ColTypeModel.BUTTON,
      btnClass: 'btn btn-sm red pr-2 pl-2',
      btnText: 'PDF',
      command: (res: ListTransactionInvoicesModel) => this.generatePdf(res),
    },
  ];

  responsedata: any;
  isCustom = false;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (
      this.sort &&
      this.responsedata &&
      this.responsedata.listTransactionHistory
    ) {
      this.dataSource.sort = this.sort;
    }
  }

  displayedColumns: string[] = [];
  async ngOnInit() {
    this.datewiseList = [
      { textId: 1, textValue: 'Total' },
      { textId: 2, textValue: '7 Days' },
      { textId: 3, textValue: '14 Days' },
      { textId: 4, textValue: '30 Days' },
      { textId: 5, textValue: 'Custom' },
    ];
    this.loadData(
      '2021-01-01',
      moment(new Date()).format('YYYY-MM-DD'),
      this.shared.branchId,
      this.shared.restaurantId
    ).subscribe((response) => {
      this.responsedata = response.data;
      this.restaurantName = response.data.listTransactionHistory.length > 0 ? response.data.listTransactionHistory[0].restaurantName : "";
      this.dataSource = new MatTableDataSource(
        this.responsedata.listTransactionHistory
      );
      //this.totalrows = this.responsedata.listTransactionHistory.length;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.displayedColumns = this.transCols.map((c) => c.column);
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  async generatePdf(pdfRes: ListTransactionInvoicesModel) {
    this.loadData(
      '2021-01-01',
      '2022-12-31',
      this.shared.branchId,
      this.shared.restaurantId
    ).subscribe(async (response) => {
      const TransactionList = [];
      TransactionList.push([
        {
          text: 'Trans No',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'Date',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'Voucher',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'Bill Amount',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'Discount',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'Transaction Charges',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'Transaction Percentage',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: 'KPIN',
          border: [false, true, false, true],
          alignment: 'left',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
      ]);
      if (response.data) {
        if (response.data.listTransactionHistory) {
          response.data.listTransactionHistory.forEach((item) => {
            TransactionList.push([
              {
                text: item.transactionNo,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.transactionDate + ' ' + item.time,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.voucherType,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.billAmount,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.discountAmount,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.transactionCharges,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.transactionPercentage,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
              {
                text: item.kPINUsed,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: 'left',
                fontSize: 8,
              },
            ]);
          });
        }
      }
      const res = await this.getBase64ImageFromURL(
        './../../assets/images/invoice-image.png'
      );
      const foot = await this.getBase64ImageFromURL(
        './../../assets/images/footers.png'
      );

      const docDefinition = {
        header: {
          columns: [
            {
              image: res,
              width: 600,
            },
          ],
        },
        footer: {
          fillColor: '#eaf2f5',
          columns: [
            {
              image: foot,
              width: 599,
              height: 40,
            },
          ],
        },

        content: [
          '\n\n',
          '\n\n',
          '\n\n',
          {
            columns: [
              [
                {
                  stack: [
                    {
                      columns: [
                        {
                          text: '',
                          color: '#aaaaab',
                          bold: true,
                          width: 360,
                          fontSize: 12,
                          alignment: 'left',
                        },
                        {
                          text: 'Receipt No.',
                          color: '#aaaaab',
                          bold: true,
                          width: 70,
                          fontSize: 12,
                          alignment: 'left',
                        },
                        {
                          text: pdfRes.invoiceNo,
                          bold: true,
                          color: '#333333',
                          fontSize: 12,
                          alignment: 'left',
                          width: 70,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: '',
                          color: '#aaaaab',
                          bold: true,
                          width: 360,
                          fontSize: 12,
                          alignment: 'left',
                        },
                        {
                          text: 'Invoice Date',
                          color: '#aaaaab',
                          bold: true,
                          width: 70,
                          fontSize: 12,
                          alignment: 'left',
                        },
                        {
                          text: pdfRes.invoiceDate,
                          bold: true,
                          color: '#333333',
                          fontSize: 12,
                          alignment: 'left',
                          width: 70,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: '',
                          color: '#aaaaab',
                          bold: true,
                          width: 360,
                          fontSize: 12,
                          alignment: 'left',
                        },
                        {
                          text: 'Status',
                          color: '#aaaaab',
                          bold: true,
                          fontSize: 12,
                          alignment: 'left',
                          width: 70,
                        },
                        {
                          text: pdfRes.status,
                          bold: true,
                          fontSize: 14,
                          alignment: 'left',
                          // color: 'green',
                          width: 70,
                        },
                      ],
                    },
                  ],
                },
              ],
            ],
          },
          {
            columns: [
              {
                text: 'To',
                color: '#aaaaab',
                bold: true,
                fontSize: 14,
                alignment: 'left',
                margin: [0, 20, 0, 5],
              },
            ],
          },
          {
            columns: [
              {
                text: pdfRes.restaurantName,
                bold: true,
                color: '#333333',
                alignment: 'left',
              },
            ],
          },
          {
            columns: [
              {
                text: pdfRes.restaurantLocation,
                style: 'restaurantLocation',
              },
            ],
          },
          '\n\n',
          {
            width: '100%',
            alignment: 'center',
            text: 'Summary',
            bold: true,
            margin: [0, 10, 0, 10],
            fontSize: 15,
          },
          {
            layout: {
              defaultBorder: false,
              hLineWidth: () => {
                return 1;
              },
              vLineWidth: () => {
                return 1;
              },
              hLineColor: (i) => {
                if (i === 1 || i === 0) {
                  return '#bfdde8';
                }
                return '#eaeaea';
              },
              vLineColor: () => {
                return '#eaeaea';
              },
              hLineStyle: () => {
                return null;
              },
              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              paddingLeft: () => {
                return 10;
              },
              paddingRight: () => {
                return 10;
              },
              paddingTop: () => {
                return 2;
              },
              paddingBottom: () => {
                return 2;
              },
              fillColor: () => {
                return '#fff';
              },
            },
            table: {
              headerRows: 1,
              widths: ['*', 80],
              body: [
                [
                  {
                    text: 'Invoice No',
                    fillColor: '#eaf2f5',
                    border: [false, true, false, true],
                    margin: [0, 5, 0, 5],
                    textTransform: 'uppercase',
                  },
                  {
                    text: 'Bill Amount',
                    fillColor: '#eaf2f5',
                    border: [false, true, false, true],
                    margin: [0, 5, 0, 5],
                    textTransform: 'uppercase',
                    alignment: 'right',
                  },
                ],
                [
                  {
                    text: pdfRes.invoiceNo,
                    border: [false, false, false, true],
                    margin: [0, 5, 0, 5],
                    alignment: 'left',
                  },
                  {
                    border: [false, false, false, true],
                    text: pdfRes.billAmount,
                    fillColor: '#f5f5f5',
                    alignment: 'right',
                    margin: [0, 5, 0, 5],
                  },
                ],
              ],
            },
          },
          '\n',
          {
            layout: {
              defaultBorder: false,
              hLineWidth: () => {
                return 1;
              },
              vLineWidth: () => {
                return 1;
              },
              hLineColor: () => {
                return '#eaeaea';
              },
              vLineColor: () => {
                return '#eaeaea';
              },
              hLineStyle: () => {
                return null;
              },
              // vLineStyle: function () { return {dash: { length: 10, space: 4 }}; },
              paddingLeft: () => {
                return 10;
              },
              paddingRight: () => {
                return 10;
              },
              paddingTop: () => {
                return 3;
              },
              paddingBottom: () => {
                return 3;
              },
              fillColor: () => {
                return '#fff';
              },
            },
            table: {
              headerRows: 1,
              widths: ['*', 80],
              body: [
                [
                  {
                    text: 'VAT',
                    border: [false, true, false, true],
                    alignment: 'right',
                    margin: [0, 5, 0, 5],
                  },
                  {
                    border: [false, true, false, true],
                    text: pdfRes.transactionCharge,
                    alignment: 'right',
                    fillColor: '#f5f5f5',
                    margin: [0, 5, 0, 5],
                  },
                ],
                [
                  {
                    text: 'Net Amount',
                    border: [false, false, false, true],
                    alignment: 'right',
                    margin: [0, 5, 0, 5],
                  },
                  {
                    text: pdfRes.netAmount,
                    border: [false, false, false, true],
                    fillColor: '#f5f5f5',
                    alignment: 'right',
                    margin: [0, 5, 0, 5],
                  },
                ],
              ],
            },
          },
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',
          '\n\n',

          {
            text: 'Description',
            style: 'notesText',
          },
          {
            text: pdfRes.description,
            style: 'notesText',
          },
          '\n\n',
          '\n\n',
          '\n\n',

          '\n\n',
          {
            width: '100%',
            alignment: 'center',
            text: 'Transaction',
            bold: true,
            margin: [0, 10, 0, 10],
            fontSize: 15,
          },
          '\n\n',
          {
            layout: {
              defaultBorder: false,
              hLineWidth: () => {
                return 1;
              },
              vLineWidth: () => {
                return 1;
              },
              hLineColor: () => {
                return '#eaeaea';
              },
              vLineColor: () => {
                return '#eaeaea';
              },
              hLineStyle: () => {
                return null;
              },
              // vLineStyle: function () { return {dash: { length: 10, space: 4 }}; },
              paddingLeft: () => {
                return 10;
              },
              paddingRight: () => {
                return 10;
              },
              paddingTop: () => {
                return 3;
              },
              paddingBottom: () => {
                return 3;
              },
              fillColor: () => {
                return '#fff';
              },
            },
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ['*', '*', '*', '*', '*', '*', '*', '*'],

              body: TransactionList,
            },
          },
        ],

        styles: {
          notesTitle: {
            fontSize: 10,
            bold: true,
            margin: [0, 50, 0, 3],
          },
          notesText: {
            fontSize: 10,
          },
        },
        defaultStyle: {
          columnGap: 20,
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download(
          pdfRes.invoiceNo + '_' + moment(new Date()).format('DDMMYYYYHHmm')
        );
    });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.setAttribute('Access-Control-Allow-Origin', '*');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  loadData(fromDate, toDate, branchId, restaurantId) {
    const request = {
      fromDate,
      toDate,
      branchId: branchId,
      restaurantId: restaurantId,
    };
    return this.walletService.getMerchantWalletTransDet(request);
  }

  async changeDate(type) {
    let todate = null;
    let fromdate = null;
    this.isCustom = false;
    if (type === '1') {
      fromdate = '2021-01-01';
      todate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ).format('YYYY-MM-DD');
    }
    if (type === '2') {
      fromdate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        )
      ).format('YYYY-MM-DD');
      todate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ).format('YYYY-MM-DD');
    }
    if (type === '3') {
      fromdate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 14
        )
      ).format('YYYY-MM-DD');
      todate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    if (type === '4') {
      fromdate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 30
        )
      ).format('YYYY-MM-DD');
      todate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    if (type === '5') {
      todate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      this.isCustom = true;
      this.startDate = '';
      this.endDate = '';
    }
    if (type !== '5') {
      this.ChartdaysDetails(fromdate, todate, this.shared.branchId, this.shared.restaurantId);
    }
  }

  customSearch() {
    if (
      this.startDate != null &&
      this.endDate != null &&
      this.endDate >= this.startDate
    ) {
      this.ChartdaysDetails(
        moment(new Date(this.startDate)).format('YYYY-MM-DD'),
        moment(new Date(this.endDate)).format('YYYY-MM-DD'),
        this.shared.branchId,
        this.shared.restaurantId
      );
      this.isCustom = false;
      this.startDate = '';
      this.endDate = '';
    }
  }

  async ChartdaysDetails(fromDate, todate, branchId?, restaurantId?) {
    this.loadData(fromDate, todate, branchId, restaurantId).subscribe(
      (response) => {
        this.responsedata = response.data;
        this.dataSource = new MatTableDataSource(
          this.responsedata.listTransactionHistory
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //this.totalrows = this.responsedata.listTransactionHistory.length;
      }
    );
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  export() {
    let transHistory = [];
    this.responsedata.listTransactionHistory.forEach(element => {
      let obj = {

        //"Inviter ID":element.inviterId === ""? 'N/A':element.inviterId,
        "Ref.Code": element.paymentRefCode,
        "User ID": element.userId,
        "Transaction No": element.transactionNo,
        "Date": element.transactionDate,
        "Time": element.time,
        "Voucher Type": element.voucherType,
        "Voucher Discount": element.discountPercentage,
        "Bill Amount": Number(element.billAmount),
        "Exclusion Amount(AED)": Number(element.exclusionAmount),
        "User Discount(AED)": Number(element.discountAmount),
        "Final Bill Amount(AED)": Number(element.billPaidAmount),
        "Payment Method": element.paymentMethod,
        "Payment Status": element.paymentStatus,
        "Received by": (element.paymentReceivedBy === "" || element.paymentReceivedBy === null) ? 'N/A' : element.paymentReceivedBy,
        "Redeemed By": (element.kPINUsed === "" || element.kPINUsed === null) ? 'N/A' : element.kPINUsed,
        "Payment Provider Id": element.paymentProvider === "" ? 'N/A' : element.paymentProvider,
        "P/G Fees(%)": element.paymentFeesPercentage === "" ? 'N/A' : element.paymentFeesPercentage,
        "P/G Fees(Amount)": element.paymentFeesValues === "" ? 'N/A' : element.paymentFeesValues,
        "Transaction Type": element.featureType,
        "Transaction Fees(%)": element.transactionPercentage,
        "Transaction Fees(AED)": Number(element.transactionCharges),
        "Redemption Cashback (AED)": Number(element.creditValue),
        //"Inviter Cashback(AED)":element.inviterCashBackValue === ""? 'N/A':element.inviterCashBackValue,
        "Redemption Cashback(Koinz)": Number(element.redeemptionKoinz),
        "Payment Done By": element.paymentDoneBy,
        "Payment Done By Mobile No": element.MobileNo
        //"Inviter Cashback Koinz":element.inviterCashBackKoinz === ""? 'N/A':element.inviterCashBackKoinz
      }
      transHistory.push(obj)
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transHistory);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.utils..Sheets.Sheet1.A1 = { font: { bold: true } };

    /* save to file */
    XLSX.writeFile(wb, 'Merchant Specific Transaction' + moment(new Date()) + '.xlsx', { cellStyles: true });

  }


  async reportClicked(selectedRow) {
    console.log(selectedRow);
    const message = "Are you sure you want to report this transaction?";
    const dialogData = new ReportIncidentDialogModel('Confirm', message, selectedRow);
    const dialogRef = this.dialog.open(ReportIncidentDialogComponent, {
      width: '40%',
      data: dialogData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.isSucces) {
        this.isSuccess = res.isSucces;
        this.message = res.message;
        let interval = setInterval(() => {
          this.isSuccess = false;
          clearInterval(interval);
        }, 10000)
      }
    });

  }

  onSuccessClose() {
    this.isSuccess = false;
  }


}
