<h5 mat-dialog-title>{{title}}</h5>

<div mat-dialog-content class="mt-3">
    <p>{{message}}</p>
</div>

<div mat-dialog-actions class="d-flex align-items-center justify-content-end mt-5">
    <button class="btn red mr-4" color="warn" mat-button (click)="onDismiss()">No</button>
    <button class="btn" mat-raised-button color="warn" (click)="onConfirm()">Yes</button>
</div>
