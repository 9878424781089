import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthServices } from '../../services/auth.service';
import { RestaurantMasterService } from '../../services/helper/restaurant-master.service';

@Component({
  selector: 'app-left-nav-bar',
  templateUrl: './left-nav-bar.component.html',
  styleUrls: ['./left-nav-bar.component.scss'],
})
export class LeftNavBarComponent implements OnInit {
  isActiveMenu: boolean;
  isSidenav: boolean;
  isTopper: boolean;
  isFooter: boolean;
  navItems;

  constructor(
    public shared: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public auth: AuthServices,
    private restaurantMasterService: RestaurantMasterService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('fp-en');
    this.isActiveMenu = true;
    this.isSidenav = true;
    this.isTopper = true;
    this.isFooter = true;
  }

  ngOnInit() {
    this.restaurantMasterService.init();
    if (JSON.parse(localStorage.getItem('user')).role != 'Admin') {
      this.navItems = [
        { title: 'Merchants', link: ['/merchants'], icon: 'verified' },
      ];
    } else {
      this.navItems = [
        { title: 'Dashboard', link: ['/dashboard'], icon: 'dashboard' },
        {
          title: 'Approval',
          link: ['/approval/on-boarding'],
          icon: 'grading',
          childLinks: [
            { title: 'On-boarding', link: ['/approval/on-boarding'] },
            { title: 'Campaign', link: ['/approval/campaign'] },
            { title: 'Ads', link: ['/approval/ads'] },
            { title: 'Premium Feature', link: ['/approval/feature'] },
          ],
        },
        { title: 'Users', link: ['/users'], icon: 'account_box' },
        { title: 'Smart Ads', link: ['/events'], icon: 'event' },
        { title: 'Notification', link: ['/notification'], icon: 'notifications' },
        { title: 'Merchants', link: ['/merchants'], icon: 'verified' },
        // { title: "Employees", link: ["/admin-employees"], icon: "groups" },
        {
          title: 'Master Data',
          link: ['/settings/master-data'],
          icon: 'settings'
          // childLinks: [
          //   /* The below module is moved to sysres/Bup folder */
          //   // {title: 'Rewards', link: ['/settings/rewards']},
          //   { title: "Master Data", link: ["/settings/master-data"] },
          // ],
        },
        // {
        //   title: "Marketing",
        //   link: ["/marketing"],
        //   icon: "campaign",
        //   childLinks: [
        //     { title: "Campaigns", link: ["/marketing/marketing-campaigns"] },
        //     // {title: 'Koinz / Vouchers', link: ['/marketing/vouchers']},
        //     { title: "Keeperz Ads", link: ["/marketing/marketing-ads"] },
        //   ],
        // },
        { title: 'Reviews', link: ['/reviews'], icon: 'try' },
        // { title: "loyalty", link: ["/admin-loyalty"], icon: "credit_score" },
        {
          title: 'Reports',
          link: ['/reports'],
          icon: 'analytics',
          childLinks: [
            { title: 'Review Report', link: ['/reports/review-reports'] },
            { title: 'Merchant Transaction', link: ['/reports/merchant-txn-reports'] },
            { title: 'Reversal Transaction', link: ['/reports/reversal-txns-report'] },
            //{ title: 'Error Code', link: ['/reports/error-code-report'] },
            //{ title: 'Recommendation Transaction', link: ['/reports/recommendation-txns-report'] },
            { title: 'Open Transaction', link: ['/reports/open-txns-report'] },
            { title: 'Wallet Transaction Report', link: ['/reports/wallet-txns-report'] },
            { title: 'Users Report', link: ['/reports/user-reg-reports'] },
          ],
        },
        {
          title: 'Financials',
          link: ['/wallet'],
          icon: 'account_balance_wallet',
        },
        // {
        //   title: "Notifications",
        //   link: ["/notifications"],
        //   icon: "notifications",
        // },
      ];
    }
  }

  slideMenu() {
    this.isActiveMenu = !this.isActiveMenu;
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }
}
