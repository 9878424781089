import { LatLng, LatLngBounds, LatLngBoundsLiteral, LatLngLiteral, MapsAPILoader,MouseEvent  } from '@agm/core';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-google-address-dialog',
  templateUrl: './google-address-dialog.component.html',
  styleUrls: ['./google-address-dialog.component.scss']
})
export class GoogleAddressDialogComponent implements OnInit {

  @ViewChild('search', { static: false})
  public searchElementRef: ElementRef;
  latitude:number = 25.276987;
  longitude:number = 55.296249;
  zoom:number;
  locationChoose = false;
  address: string;
  private geoCoder;
  
  constructor(public dialogRef: MatDialogRef<GoogleAddressDialogComponent>,public formbuilder:FormBuilder,private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnInit() {
    this.zoom = 15;
     //load Places Autocomplete
     //this.setCurrentLocation();
     this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
       autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
   
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.locationChoose = true;
          this.address = results[0].formatted_address;
        }
      } 
    });
  }

  onMap() {
    let obj = {
      address: this.address,
      latitude: this.latitude,
      longitude:this.longitude
    }
    this.dialogRef.close(obj)
  }

  onChoseLocation(event)
  {
    console.log(event)
      this.latitude =event.coords.lat;
      this.longitude = event.coords.lng;
      this.locationChoose = true;
      this.getAddress(this.latitude, this.longitude);
  }

}
declare namespace google.maps {
  /***** Services *****/
  class Geocoder {
      geocode(request: GeocoderRequest, callback: (results: GeocoderResult[], status: GeocoderStatus) => void): void;
  }

  interface GeocoderRequest {
      address?: string;
      bounds?: LatLngBounds | LatLngBoundsLiteral;
      componentRestrictions?: GeocoderComponentRestrictions;
      location?: LatLng | LatLngLiteral;
      placeId?: string;
      region?: string;
  }

  interface GeocoderComponentRestrictions {
      administrativeArea?: string;
      country?: string | string[];
      locality?: string;
      postalCode?: string;
      route?: string;
  }

  enum GeocoderStatus {
      ERROR = 'ERROR',
      INVALID_REQUEST = 'INVALID_REQUEST',
      OK = 'OK',
      OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
      REQUEST_DENIED = 'REQUEST_DENIED',
      UNKNOWN_ERROR = 'UNKNOWN_ERROR',
      ZERO_RESULTS = 'ZERO_RESULTS',
  }

  interface GeocoderResult {
      address_components: GeocoderAddressComponent[];
      formatted_address: string;
      geometry: GeocoderGeometry;
      partial_match: boolean;
      place_id: string;
      postcode_localities: string[];
      types: string[];
  }

  interface GeocoderAddressComponent {
      long_name: string;
      short_name: string;
      types: string[];
  }

  interface GeocoderGeometry {
      bounds: LatLngBounds;
      location: LatLng;
      location_type: GeocoderLocationType;
      viewport: LatLngBounds;
  }

  enum GeocoderLocationType {
      APPROXIMATE = 'APPROXIMATE',
      GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
      RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
      ROOFTOP = 'ROOFTOP',
  }
}


declare namespace google.maps.places {
  // TODO find source documentation
  interface RadarSearchRequest {
      bounds?: LatLngBounds | LatLngBoundsLiteral;
      keyword?: string;
      location?: LatLng | LatLngLiteral;
      name?: string;
      radius?: number;
      types?: string[] /* Deprecated. Will be removed February 16, 2017 */;
      type?: string;
  }

  class PlacesService {
      constructor(attrContainer: HTMLDivElement | any);
      findPlaceFromPhoneNumber(
          request: FindPlaceFromPhoneNumberRequest,
          callback: (results: PlaceResult[], status: PlacesServiceStatus) => void,
      ): void;
      findPlaceFromQuery(
          request: FindPlaceFromQueryRequest,
          callback: (results: PlaceResult[], status: PlacesServiceStatus) => void,
      ): void;
      getDetails(
          request: PlaceDetailsRequest,
          callback: (result: PlaceResult, status: PlacesServiceStatus) => void,
      ): void;
      nearbySearch(
          request: PlaceSearchRequest,
          callback: (results: PlaceResult[], status: PlacesServiceStatus, pagination: PlaceSearchPagination) => void,
      ): void;
      /**
       * @deprecated Radar search is deprecated as of June 30, 2018. After that
       *     time, this feature will no longer be available.
       */
      radarSearch(
          request: RadarSearchRequest,
          callback: (results: PlaceResult[], status: PlacesServiceStatus) => void,
      ): void;
      textSearch(
          request: TextSearchRequest,
          callback: (results: PlaceResult[], status: PlacesServiceStatus, pagination: PlaceSearchPagination) => void,
      ): void;
  }

  interface PlaceDetailsRequest {
      placeId: string;
      fields?: string[];
      sessionToken?: any;
  }

  interface FindPlaceFromPhoneNumberRequest {
      fields: string[];
      locationBias?: LocationBias;
      phoneNumber: string;
  }

  interface FindPlaceFromQueryRequest {
      fields: string[];
      locationBias?: LocationBias;
      query: string;
  }

  interface PlaceSearchRequest {
      bounds?: LatLngBounds | LatLngBoundsLiteral;
      keyword?: string;
      location?: LatLng | LatLngLiteral;
      maxPriceLevel?: number;
      minPriceLevel?: number;
      name?: string;
      openNow?: boolean;
      radius?: number;
      rankBy?: RankBy;
      types?: string[] /* Deprecated. Will be removed February 16, 2017 */;
      type?: string;
  }

  interface TextSearchRequest {
      bounds?: LatLngBounds | LatLngBoundsLiteral;
      location?: LatLng | LatLngLiteral;
      query: string;
      radius?: number;
      types?: string[] /* Deprecated. Will be removed February 16, 2017 */;
      type?: string;
  }

  enum RankBy {
      PROMINENCE = 0,
      DISTANCE = 1,
  }

  type LocationBias = LatLng | LatLngLiteral | LatLngBounds | LatLngBoundsLiteral | string;

  enum PlacesServiceStatus {
      INVALID_REQUEST = 'INVALID_REQUEST',
      NOT_FOUND = 'NOT_FOUND',
      OK = 'OK',
      OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
      REQUEST_DENIED = 'REQUEST_DENIED',
      UNKNOWN_ERROR = 'UNKNOWN_ERROR',
      ZERO_RESULTS = 'ZERO_RESULTS',
  }

  interface PlaceSearchPagination {
      nextPage(): void;
      hasNextPage: boolean;
  }

  interface PlaceResult {
      address_components?: GeocoderAddressComponent[];
      adr_address?: string;
      aspects?: PlaceAspectRating[];
      formatted_address?: string;
      formatted_phone_number?: string;
      geometry?: PlaceGeometry;
      html_attributions?: string[];
      icon?: string;
      id?: string;
      international_phone_number?: string;
      name: string;
      opening_hours?: OpeningHours;
      permanently_closed?: boolean;
      photos?: PlacePhoto[];
      place_id?: string;
      plus_code?: PlacePlusCode;
      price_level?: number;
      rating?: number;
      reviews?: PlaceReview[];
      types?: string[];
      url?: string;
      user_ratings_total?: number;
      /**
       * @deprecated utc_offset is deprecated as of November 2019 and will be turned off in November 2020.
       *      Use PlaceResult.utc_offset_minutes instead.
       */
      utc_offset?: number;
      utc_offset_minutes?: number;
      vicinity?: string;
      website?: string;
  }

  interface PlaceAspectRating {
      rating: number;
      type: string;
  }

  // TODO add BusinessStatus https://developers.google.com/maps/documentation/javascript/reference/places-service#BusinessStatus

  interface PlaceGeometry {
      location: LatLng;
      viewport: LatLngBounds;
  }

  // TODO rename to PlaceOpeningHours https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceOpeningHours
  interface OpeningHours {
      /**
       * @deprecated open_now is deprecated as of November 2019 and will be turned off in November 2020.
       *      Use the PlaceOpeningHours.isOpen function from a PlacesService.getDetails result instead.
       */
      open_now: boolean;
      periods: OpeningPeriod[];
      weekday_text: string[];
      isOpen(date?: Date): boolean;
  }

  // TODO rename to PlaceOpeningHoursPeriod https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceOpeningHoursPeriod
  interface OpeningPeriod {
      open: OpeningHoursTime;
      close?: OpeningHoursTime;
  }

  // TODO rename to PlaceOpeningHoursTime https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceOpeningHoursTime
  interface OpeningHoursTime {
      day: number;
      hours: number;
      minutes: number;
      nextDate: number;
      time: string;
  }

  interface PlacePlusCode {
      compound_code?: string;
      global_code: string;
  }

  interface PlacePhoto {
      height: number;
      html_attributions: string[];
      width: number;
      getUrl(opts: PhotoOptions): string;
  }

  interface PhotoOptions {
      maxHeight?: number;
      maxWidth?: number;
  }

  interface PlaceReview {
      aspects: PlaceAspectRating[];
      author_name: string;
      author_url?: string;
      language: string;
      profile_photo_url: string;
      // TODO rating is documented in the HTTP API (https://developers.google.com/places/web-service/details#PlaceDetailsResults) but not in the Javascript API.
      rating: number;
      relative_time_description: string;
      text: string;
      time: number;
  }
}


declare namespace google.maps.places {
  class Autocomplete extends MVCObject {
      constructor(inputField: HTMLInputElement, opts?: AutocompleteOptions);
      getBounds(): LatLngBounds;
      getPlace(): PlaceResult;
      setBounds(bounds: LatLngBounds | LatLngBoundsLiteral): void;
     // setComponentRestrictions(restrictions: ComponentRestrictions): void;
      setFields(fields: string[] | undefined): void;
      setOptions(options: AutocompleteOptions): void;
      setTypes(types: string[]): void;
  }

  interface AutocompleteOptions {
      bounds?: LatLngBounds | LatLngBoundsLiteral;
      componentRestrictions?: any;
      placeIdOnly?: boolean;
      strictBounds?: boolean;
      types?: string[];
      type?: string;
      fields?: string[];
  }

  class SearchBox extends MVCObject {
      constructor(inputField: HTMLInputElement, opts?: SearchBoxOptions);
      getBounds(): LatLngBounds;
      getPlaces(): PlaceResult[];
      setBounds(bounds: LatLngBounds | LatLngBoundsLiteral): void;
  }

  interface SearchBoxOptions {
      bounds: LatLngBounds | LatLngBoundsLiteral;
  }
}


declare namespace google.maps {
  namespace event {
      /**
       * Cross browser event handler registration. This listener is removed by
       * calling removeListener(handle) for the handle that is returned by this
       * function.
       */
      function addDomListener(
          instance: object,
          eventName: string,
          handler: (event: Event) => void,
          capture?: boolean,
      ): MapsEventListener;

      /**
       * Wrapper around addDomListener that removes the listener after the first
       * event.
       */
      function addDomListenerOnce(
          instance: object,
          eventName: string,
          handler: (event: Event) => void,
          capture?: boolean,
      ): MapsEventListener;

      /**
       * Adds the given listener function to the given event name for the given
       * object instance. Returns an identifier for this listener that can be used
       * with removeListener().
       */
      function addListener(instance: object, eventName: string, handler: (...args: any[]) => void): MapsEventListener;

      /**
       * Like addListener, but the handler removes itself after handling the first
       * event.
       */
      function addListenerOnce(
          instance: object,
          eventName: string,
          handler: (...args: any[]) => void,
      ): MapsEventListener;

      /**
       * Removes all listeners for all events for the given instance.
       */
      function clearInstanceListeners(instance: object): void;

      /**
       * Removes all listeners for the given event for the given instance.
       */
      function clearListeners(instance: object, eventName: string): void;

      /**
       * Removes the given listener, which should have been returned by
       * addListener above. Equivalent to calling listener.remove().
       */
      function removeListener(listener: MapsEventListener): void;

      /**
       * Triggers the given event. All arguments after eventName are passed as
       * arguments to the listeners.
       */
      function trigger(instance: any, eventName: string, ...args: any[]): void;
  }

  interface MapsEventListener {
      /**
       * Removes the listener.  Equivalent to calling
       * google.maps.event.removeListener(listener).
       */
      remove(): void;
  }

  type MVCEventHandler<T extends MVCObject, A extends any[]> = (this: T, ...args: A) => void;

  class MVCObject {
      /**
       * The MVCObject constructor is guaranteed to be an empty function, and so
       * you may inherit from MVCObject by simply writing MySubclass.prototype =
       * new google.maps.MVCObject();. Unless otherwise noted, this is not true of
       * other classes in the API, and inheriting from other classes in the API is
       * not supported.
       */
      constructor();

      /**
       * Adds the given listener function to the given event name. Returns an
       * identifier for this listener that can be used with
       * google.maps.event.removeListener.
       */
      addListener(eventName: string, handler: MVCEventHandler<this, any[]>): MapsEventListener;

      /** Binds a View to a Model. */
      bindTo(key: string, target: MVCObject, targetKey?: string, noNotify?: boolean): void;

      changed(key: string): void;

      /** Gets a value. */
      get(key: string): any;

      /**
       * Notify all observers of a change on this property. This notifies both
       * objects that are bound to the object's property as well as the object
       * that it is bound to.
       */
      notify(key: string): void;

      /** Sets a value. */
      set(key: string, value: any): void;

      /** Sets a collection of key-value pairs. */
      setValues(values: any): void;

      /**
       * Removes a binding. Unbinding will set the unbound property to the current
       * value. The object will not be notified, as the value has not changed.
       */
      unbind(key: string): void;

      /** Removes all bindings. */
      unbindAll(): void;
  }

  /** This class extends MVCObject. */
  class MVCArray<T> extends MVCObject {
      /** A mutable MVC Array. */
      constructor(array?: T[]);

      /** Removes all elements from the array. */
      clear(): void;

      /**
       * Iterate over each element, calling the provided callback.
       * The callback is called for each element like: callback(element, index).
       */
      forEach(callback: (elem: T, i: number) => void): void;

      /**
       * Returns a reference to the underlying Array.
       * Warning: if the Array is mutated, no events will be fired by this object.
       */
      getArray(): T[];

      /** Returns the element at the specified index. */
      getAt(i: number): T;

      /** Returns the number of elements in this array. */
      getLength(): number;

      /** Inserts an element at the specified index. */
      insertAt(i: number, elem: T): void;

      /** Removes the last element of the array and returns that element. */
      pop(): T;

      /**
       * Adds one element to the end of the array and returns the new length of
       * the array.
       */
      push(elem: T): number;

      /** Removes an element from the specified index. */
      removeAt(i: number): T;

      /** Sets an element at the specified index. */
      setAt(i: number, elem: T): void;
  }
}

