import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { WalletService } from '../services/wallet.service';
import {
  ColsModel,
  ColTypeModel,
} from '../../../shared/datatable/datatable.model';
import { AdminWalletDetailsModel } from '../models/wallet-transaction-details.model';
import { Router } from '@angular/router';
import { WalletBaseComponent } from '../wallet.base';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MatDialog } from '@angular/material/dialog';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-wallet-main',
  templateUrl: './wallet-main.component.html',
  styleUrls: ['./wallet-main.component.scss'],
})
export class WalletMainComponent extends WalletBaseComponent implements OnInit {
  data: AdminWalletDetailsModel[] = [];
  minDate: Date;
  maxDate: Date;
  minDateTo: Date;
  maxDateTo: Date;
  datewiseList: any[] = [];
  startDate = '';
  endDate = '';
  incidentCount = 0;

  constructor(
    public formbuilder: FormBuilder,
    protected walletService: WalletService,
    protected router: Router,
    private shared: SharedService, protected dialog: MatDialog
  ) {
    super(walletService, dialog, router);
    const currentYear = new Date().getFullYear();
    // const currentMonth = new Date().getMonth();
    this.minDate = new Date(currentYear - 2, 0, 1);
    this.maxDate = new Date();

    this.minDateTo = new Date(currentYear - 2, 0, 1);
    this.maxDateTo = new Date();
  }

  cols: ColsModel[] = [
    {
      column: 'restaurantName',
      title: 'Merchant Name',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'restaurantLocation',
      title: 'Location',
      type: ColTypeModel.TEXT,
    },
    {
      column: 'trasactionCount',
      title: 'Transaction Count',
      type: ColTypeModel.TEXT,
    },
  ];

  isCustom = false;

  async ngOnInit() {
    this.datewiseList = [
      { textId: 1, textValue: 'Total' },
      { textId: 2, textValue: '7 Days' },
      { textId: 3, textValue: '14 Days' },
      { textId: 4, textValue: '30 Days' },
      { textId: 5, textValue: 'Custom' },
    ];
    this.loadData('2021-01-01', moment(new Date()).format('YYYY-MM-DD')).subscribe((response) => {
      this.data = response.data;
    });

    
  }

  loadData(fromDate, toDate) {
    const request = { fromDate, toDate };
    return this.walletService.getAdminAllMerchantsWalletDet(request);
  }

  onBindIncidentCount(Count) {
    this.incidentCount = Count;
  }

  navigateToWalletDetails(row: AdminWalletDetailsModel) {
    const restaurantId = row.RestaurantId || row.restaurantId;
    this.shared.restaurantId = restaurantId;
    this.shared.branchId = row.branchId;

    this.router.navigate([`/wallet/${restaurantId}/transactions`]);
  }
}
