<form [formGroup]="form" class="p-4">
    <div class="d-flex justify-content-center">
        <h6>Enter OTP</h6>
    </div>
    <div class="d-flex justify-content-center mt-4 mb-4">
        <input type="text" name="digit1" class="otp-input" formControlName="digit1" maxlength="1">
        <input type="text" name="digit2" class="otp-input" formControlName="digit2" maxlength="1">
        <input type="text" name="digit3" class="otp-input" formControlName="digit3" maxlength="1">
        <input type="text" name="digit4" class="otp-input" formControlName="digit4" maxlength="1">
    </div>
    <div class="d-flex justify-content-center">
        <button [disabled]="!form.valid" class="btn bg-red btn-md" (click)="submit()">Submit</button>
    </div>
</form>
