import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataCardModel} from '../data-card-split/model/data-card.model';

export interface ToggleBtnDetailsModel {
  id: string;
  title: string;
  icon: string;
  class: 'left' | 'right';
}

@Component({
  selector: 'app-custom-toggle-btns',
  templateUrl: './custom-toggle-btns.component.html',
  styleUrls: ['./custom-toggle-btns.component.scss']
})
export class CustomToggleBtnsComponent implements OnInit {
  selectedBtn = 'menuItems';
  @Input() btnItemDetails: ToggleBtnDetailsModel[] = [];
  @Output() onChange = new EventEmitter<ToggleBtnDetailsModel>();

  constructor() { }

  ngOnInit(): void {
  }

}
