<mat-tab-group style="width: 99%">
  <mat-tab label="All Transactions">
    <app-wallet-all-txns></app-wallet-all-txns>
  </mat-tab>
  <mat-tab label="Merchant Specific">
    <div class="m-5" *ngIf="data && data.length > 0">
      <app-datatable [data]="data" [cols]="cols" styleClass="v3" [showPageSizeOptions]="true"
        (onRowClicked)="navigateToWalletDetails($event)"></app-datatable>
    </div>
  </mat-tab>
  <mat-tab label="Incident List">
    <ng-template mat-tab-label>
        <span [matBadge]="this.walletService.incidentCount" matBadgeSize="large" matBadgeColor="warn" matBadgeOverlap="false">Incident List</span>
    </ng-template>
    <app-reversal-incident-list (bindIncidentCount)="onBindIncidentCount($event)"></app-reversal-incident-list>
  </mat-tab>
  <mat-tab label="Closed Incidents">
    <!-- <ng-template mat-tab-label>
        <span [matBadge]="this.walletService.incidentCount" matBadgeSize="large" matBadgeColor="warn" matBadgeOverlap="false">Incident List</span>
    </ng-template> -->
    <app-closed-incident-list></app-closed-incident-list>
  </mat-tab>
</mat-tab-group>