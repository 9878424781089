<!-- Page Wrapper -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true">
    <p style="color: white"><label translate="fp.loading"></label></p>
</ngx-spinner>
<div id="wrapper">
    <!-- Sidebar -->
    <div  [ngClass]="{'nav-menu-slide-active':isActiveMenu,'nav-menu-slide-in-active':!isActiveMenu }">
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion nav-bar-scroll"
            [ngClass]="{'nav-menu-slide-active':isActiveMenu,'nav-menu-slide-in-active':!isActiveMenu }"
            *ngIf="isSidenav" id="accordionSidebar">

            <!-- Sidebar - Brand -->
            <a class="sidebar-brand d-flex align-items-start" (click)="slideMenu()">
                <div class="sidebar-brand-icon">
                    <mat-icon class="menu-icon">menu</mat-icon>
                </div>
                <div class="sidebar-brand-text mx-3 menu-header">
                    <img _ngcontent-kqw-c3="" src="./../../assets/images/keeperz.png" style="width: 100px;">
                </div>
            </a>

            <!-- Divider -->
            <div class="mb-5 pb-2"></div>
            <!-- Nav Item - Dashboard -->
            <ng-container *ngFor="let item of navItems">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link mt-0 mb-0" [routerLink]="item.link">
                        <span class="icon-text-align">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <mat-icon class="mr-4">{{item?.icon}}</mat-icon>
                                    <span class="text"> {{item?.title}} </span>
                                </div>
                                <div class="d-flex align-items-center"
                                    style="position: absolute; right: 2.5em !important;">
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </div>
                            </div>
                        </span>
                    </a>

                    <ul *ngIf="item.childLinks" class="navbar-nav bg-gradient-primary sidebar-dark accordion child-link"
                        style="margin-left: 5vw;margin-top:0.5vw">
                        <li class="sub-menu" *ngFor="let childLink of item.childLinks" [routerLink]="childLink.link"
                            routerLinkActive="active">
                            {{childLink.title}}
                        </li>
                    </ul>
                </li>
            </ng-container>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link mt-0 mb-0" (click)="logout()">
                    <span class="icon-text-align">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <mat-icon class="mr-4">logout</mat-icon>
                                <span class="text"> Logout </span>
                            </div>
                            <div class="d-flex align-items-center" style="position: absolute; right: 2.5em !important;">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                        </div>
                    </span>
                </a>
            </li>
        </ul>
    </div>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column" style="z-index: 99;">
        <div id="content" style="position: relative;">

            <!-- Topbar -->
            <div class="topbar-container">
                <div class="d-flex align-items-center mt-3">

                    <div class="breadcrumb-sec container">
                        <app-ng-dynamic-breadcrumb [symbol]="'>'" [bgColor]="'#fff'" [fontSize]="'1.5em'"
                            [fontColor]="'#000'" [lastLinkColor]="'#ccc'"></app-ng-dynamic-breadcrumb>
                    </div>

                    <nav class="navbar navbar-expand navbar-light bg-white topbar static-top" *ngIf="isTopper">

                        <!-- Sidebar Toggle (Topbar) -->
                        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                            <i class="fa fa-bars">fa-bars</i>
                        </button>

                        <!-- Topbar Navbar -->
                        <ul class="ml-auto" style="list-style: none;">
                            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
                            <li class="nav-item dropdown no-arrow d-sm-none">
                                <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-search fa-fw">fas search</i>
                                </a>
                                <!-- Dropdown - Messages -->
                                <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                    aria-labelledby="searchDropdown">
                                    <form class="form-inline mr-auto w-100 navbar-search">
                                        <div class="input-group">
                                            <input type="text" class="form-control bg-light border-0 small"
                                                placeholder="Search for..." aria-label="Search"
                                                aria-describedby="basic-addon2">
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" type="button">
                                                    <i class="fas fa-search fa-sm">fa-search</i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </li>

                            <!-- Nav Item - User Information -->
                            <li class="nav-item dropdown no-arrow" *ngIf="shared.getUser()">
                                <a class="nav-link d-flex justify-content-end">
                                    <span class="mr-2 d-none d-lg-inline text-dark small" mat-button
                                        [matMenuTriggerFor]="userProfileMenu">
                                        {{shared.getUser().merchantName}}
                                    </span>
                                    <img class="img-profile rounded-circle"
                                        src="../../../../assets/images/Keeperz-6.jpg">
                                    <mat-menu #userProfileMenu="matMenu">
                                        <button mat-menu-item>Profile</button>
                                        <button mat-menu-item>Settings</button>
                                        <button mat-menu-item>Activity Log</button>
                                        <button mat-menu-item (click)="logout()">Logout</button>
                                    </mat-menu>
                                </a>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>

            <div class="mt-5 mb-2 pt-5">
                <app-merchant-header-profile></app-merchant-header-profile>
            </div>

            <!-- End of Topbar -->
            <div class="container" style="min-height: 500px;max-width: 1500px !important;">
                <router-outlet></router-outlet>
            </div>

        </div>
        <!-- Main Content -->


        <!-- End of Main Content -->

        <!-- Footer -->
        <footer class="sticky-footer bg-white" *ngIf="isFooter">
            <div class="container my-auto">
                <div class="copyright text-center my-auto">
                    <span>Copyright &copy; Keeperz 2020</span>
                </div>
            </div>
        </footer>
        <!-- End of Footer -->

    </div>
    <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up">fa-angle-up</i>
</a>