// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// http://keeperz-dev.uaenorth.cloudapp.azure.com/
export const environment = {
  production: true,
  baseUrl: 'https://prodapi.keeperz.app',
  apigateUrl: 'http://keeperz-dev.uaenorth.cloudapp.azure.com',
  mail: 'https://uuavoa8y8k.execute-api.ap-south-1.amazonaws.com/default',
  idleTimeoutSeconds: 3000000,
  isIdleTimeoutEnabled : false
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
