import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MerchantListDataModel } from '../models/merchants.model';
import { MerchantDashboardModel } from '../models/merchant-dashboard.model';
import { MerchantCampaignAdsModelModel } from '../models/merchant-campaign-ads.model';
import { MerchantDetailsModel } from '../models/merchant-details.model';
import { MerchantReputationModel } from '../models/merchant-reputation.model';
import { InAppDetailsPostModel } from '../models/in-app-details.model';
import {
  HappyHoursPostModel,
  HotDealsPostModel,
} from '../models/happy-hours-and-hot-deals.model';
import { tap } from 'rxjs/operators';

export interface IUpdateFeaturePayload {
  restaurantId: number;
  branchId: string;
  featureType: string;
  approved: number;
}

export interface IContractDetailsPayload {
  merchantRegistrationId: number;
  restaurantId: number;
  CustomerAquisition: boolean;
  Exposure: boolean;
  InAppAds: boolean;
  Loyalty: boolean;
  RevenueBooster: boolean;
  approvalStatus: string;
  branchId: string;
  contractChargesPremium: string;
  contractChargesStandard: string;
  contractPerriod: string;
  contractStartDate: string;
  contractType: string;
  isVerifiedContract: boolean;
  isVerifiedLicense: boolean;
  updatedBy: string;
  billingType: string;
  contractChargesStandardType: string;
  contractChargesPremiumType: string;
  approvedOn: string;
  CustomerId: string;
  isExcludeAmount: boolean,
  recommendationLevel: string,
  isOnlinePaymentEnabled: boolean,
  contractChargesSpecial: number,
  contractChargesSpecialType: string,
  contractChargesSpecialTypeId: number
}
export interface MerchantRequestModel {
  restaurantId: number;
  branchId: string;
}

export interface MerchantStatusUpdateReq {
  restaurantId: number;
  branchId: string;
  active: 1 | 0;
}

export enum ApprovalFeatureType {
  premium = 'premium',
  loyalty = 'loyalty',
}

@Injectable({
  providedIn: 'root',
})
export class MerchantsDataService {
  onGetMerchantDet = new EventEmitter<MerchantDetailsModel>();

  constructor(private http: HttpClient) { }

  public getMerchantList(): Observable<MerchantListDataModel> {
    const url = environment.baseUrl + '/api/merchantlists';
    return this.http.get<MerchantListDataModel>(url);
  }

  public saveContractDetails(
    payload: IContractDetailsPayload
  ): Observable<any> {
    const url = environment.baseUrl + '/api/merchantlist';
    return this.http.post<any>(url, payload);
  }

  public getMerchantDashboardDetails(
    payload
  ): Observable<MerchantDashboardModel> {
    const url = environment.baseUrl + '/api/getdashboarddetails';
    return this.http.post<MerchantDashboardModel>(url, payload);
  }

  public getMerchantDetails(
    request: MerchantRequestModel
  ): Observable<MerchantDetailsModel> {
    const url = environment.baseUrl + '/api/getmerchantdetails';
    return this.http
      .post<MerchantDetailsModel>(url, request)
      .pipe(tap((res) => this.onGetMerchantDet.next(res)));
  }

  public getMerchantCampaignAndAdsDetails(
    request: MerchantRequestModel
  ): Observable<MerchantCampaignAdsModelModel> {
    const url = environment.baseUrl + '/api/getcampaigndetails';
    return this.http.post<MerchantCampaignAdsModelModel>(url, request);
  }

  public getMerchantReputation(
    request: MerchantRequestModel
  ): Observable<MerchantReputationModel> {
    const url = environment.baseUrl + '/api/getreputationdetails';
    return this.http.post<MerchantReputationModel>(url, request);
  }

  public updateMerchantStatus(
    request: MerchantStatusUpdateReq
  ): Observable<MerchantReputationModel> {
    const url = environment.baseUrl + '/api/merchantstatus';
    return this.http.post<MerchantReputationModel>(url, request);
  }

  /* Marketing Solutions */

  /* 1) In App Ads */
  public addInAppDetails(request: InAppDetailsPostModel): Observable<any> {
    const url = environment.baseUrl + '/api/inappadsdetails';
    return this.http.post<any>(url, request);
  }

  /* 2) Hot Deals / Happy Hours */
  public addHotDeals(
    request: HotDealsPostModel
  ): Observable<MerchantReputationModel> {
    const url = environment.baseUrl + '/api/hotdealsdetails';
    return this.http.post<MerchantReputationModel>(url, request);
  }

  public addHappyHours(
    request: HappyHoursPostModel
  ): Observable<MerchantReputationModel> {
    const url = environment.baseUrl + '/api/happyhoursdetails';
    return this.http.post<MerchantReputationModel>(url, request);
  }

  public postStandardDiscount(request: any): Observable<any> {
    const url = environment.baseUrl + '/api/restaurantdiscount';
    return this.http.post<any>(url, request);
  }

  /** Update approval and recommendation */
  public updateApprovalFeature(payload: IUpdateFeaturePayload) {
    const url = environment.baseUrl + '/api/adminfeature';
    return this.http.post<any>(url, payload);
  }


  public updateAdsStatus(payload) {
    const url = environment.baseUrl + '/api/admin-ads-status';
    return this.http.post<any>(url, payload);
  }
  public updateCampaignStatus(payload) {
    const url = environment.baseUrl + '/api/admin-campagin-status';
    return this.http.post<any>(url, payload);
  }

  public getCampaignTypes(): Observable<any> {
    const url = environment.baseUrl + '/api/campaigntypes';
    return this.http.get<any>(url);
  }

  // public getCampaignTypes(id): Observable<any> {
  //   const pathparams = '?discounttypeid=' + id
  //   const url = environment.baseUrl + '/api/campaigntypes' + pathparams;
  //   return this.http.get<any>(url);
  // }


  public postFlexibleCampaign(payload: any) {
    const url = environment.baseUrl + '/api/multicampaign-details';
    return this.http.post<any>(url, payload);
  }
  public getCampaignDetails(payload): Observable<MerchantListDataModel> {
    //const pathparams = '?campaignTypeId=' + id
    const url = environment.baseUrl + '/api/getcampaign-details';
    return this.http.post<any>(url, payload);
  }


  public getCampaignNamesById(id): Observable<MerchantListDataModel> {
    const pathparams = '?campaignTypeId=' + id
    const url = environment.baseUrl + '/api/campaignnames' + pathparams;
    return this.http.get<MerchantListDataModel>(url);
  }

  public postHappyHours(request: any, header): Observable<any> {
    const url = environment.baseUrl + '/api/happyhoursdetails';
    return this.http.post<any>(url, request, header);
  }

  public postHotDeals(request: any, header): Observable<any> {
    const url = environment.baseUrl + '/api/hotdealsdetails';
    return this.http.post<any>(url, request, header);
  }

  public postVerifyCampaign(request: any, header): Observable<any> {
    const url = environment.baseUrl + '/api/verifyactivecampaign';
    return this.http.post<any>(url, request, header);
  }

  public postinappadsdetails(request, header) {
    const url = environment.baseUrl + '/api/inappadsdetails';
    return this.http.post<any>(url, request, header);
  }


  public updateinappadsdetails(request, header) {
    const url = environment.baseUrl + '/api/inappadsdetails-update';
    return this.http.post<any>(url, request, header);
  }

  // public sendNotification(request, header) {
  //   const url = environment.baseUrl + '/api/notify-onboardrestaurant';
  //   return this.http.post<any>(url, request, header);
  // }


  public sendNotification(payload: any) {
    const url = environment.baseUrl + '/api/notify-onboardrestaurant';
    return this.http.post<any>(url, payload);
  }

  public setStandardStatus(payload: any) {
    const url = environment.baseUrl + '/api/update-status-standarddiscount';
    return this.http.post<any>(url, payload);
  }

  public getmerchantrecommenddetails(payload): Observable<any> {
    const url = environment.baseUrl + '/api/getmerchantrecommenddetails';
    return this.http.post<MerchantListDataModel>(url, payload);
  }
}
