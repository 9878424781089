import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_BASE_HREF } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { LeftNavBarComponent } from "./shared/layout-components/left-nav-bar/left-nav-bar.component";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSortModule } from "@angular/material/sort";
import { MatRadioModule } from "@angular/material/radio";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { MatCardModule } from "@angular/material/card";
import { ToastrModule } from "ngx-toastr";
import { TranslateLocalModule } from "./modules/common/trans-local.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpConfigInterceptor } from "./shared/interceptor/http-interceptor";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { MatIconModule } from "@angular/material/icon";
import { AuthGuard } from "./shared/guard/auth.guard";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { AgmCoreModule } from "@agm/core";
import { SharedService } from "./shared/services/shared.service";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { WalletModule } from "../app/modules/wallet/wallet.module";
@NgModule({
  declarations: [AppComponent, LeftNavBarComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    MatSortModule,
    MatInputModule,
    MatRadioModule,
    NgDynamicBreadcrumbModule,
    MatCardModule,
    WalletModule,
    TranslateLocalModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-center",
    }),
    NgxSpinnerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatMenuModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCAVy4_Po0roPozzpU5rOdxUuGvFaDgSQI",
      libraries: ["places"],
    }),
  ],
  providers: [
    SharedService,
    { provide: APP_BASE_HREF, useValue: "" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    NgxSpinnerService,
    AuthGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
import { MerchantAccessControlDirective } from "./modules/merchants/merchant-role-access.directive";
