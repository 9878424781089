import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServices} from '../../services/auth.service';

@Component({
  selector: 'app-registration-pending-model',
  templateUrl: './registration-pending-model.component.html',
  styleUrls: ['./registration-pending-model.component.scss']
})
export class RegistrationPendingModelComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<RegistrationPendingModelComponent>,
              private router: Router, private activatedRoute: ActivatedRoute,
              private auth: AuthServices) {
  }

  ngOnInit() {
  }

  logout() {
    this.auth.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/login');
  }

}
