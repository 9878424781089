<div class="row">
    <div class="col-md-6">
        <h5 mat-dialog-title>{{data.title}}</h5>
    </div>
    <div class="col-md-6" mat-dialog-actions style="display: flex;place-content: flex-end;">
        <mat-icon class="button-close" data-toggle="tooltip" data-placement="top" title="Close" style="color: #e01515;
    cursor: pointer;
    border: 1px solid red !important;
    border-radius: 15px;
    font-size: 16px;
    display: flex;
    place-content: center;
    align-items: center;" (click)="onDismiss()">close</mat-icon>
    </div>
</div>
<mat-card [ngClass]="data.className" class="example-card">
    <!-- <mat-card-title-group>
        <mat-card-title>{{data.title}}</mat-card-title>
    </mat-card-title-group> -->
    <mat-card-content>
        <div mat-dialog-content class="mt-3 font-size">
            <p>{{data.viewData === "" ? 'No comments available' : data.viewData}}</p>
        </div>
        <!-- <div mat-dialog-actions class="d-flex align-items-center justify-content-end">
            <button class="btn btn-md red mr-4" mat-button (click)="onDismiss()">Close</button>
        </div> -->
    </mat-card-content>
</mat-card>