<div class="container-fluid" style="
    scroll-behavior: smooth;
    overflow-y: auto;
    margin-top: 20px;
    background-color: #fff;
  ">
  <div class="d-flex justify-content-end dialog-close mb-2"></div>
  <!-- <div>
    <app-data-card-split [data]="actionDataCard" [config]="actionDataCardConfig"
      (onClick)="onActionTodoClick($event)"></app-data-card-split>
  </div> -->
  <div class="row" style="margin-top: 15px">
    <div class="card card-bg shadow py-2" style="width: 97% !important; margin-top: 25px; margin-left: 15px">
      <div class="cardbody" style="background-color: #fff">
        <!-- <div class="row" *ngIf="responsedata && responsedata.listTransactionHistory.length > 0">
          <div class="col-md-12">
           
          </div>
        </div> -->
        
        <div class="row" *ngIf="responsedata && responsedata.listTransactionHistory.length > 0 && isAllTransaction">
          <div class="col-md-12">
            <button style="float: right;" mat-raised-button color="warn" (click)="exportAll()">Download All</button>
            <button style="float: right;margin-right:10px" mat-raised-button color="warn" (click)="export()">Download to
              excel</button>
          </div>
        </div>
        <div class="row" *ngIf="responsedata && responsedata.listTransactionHistory.length > 0 && isIncidents">
          <div class="col-md-12">
            <button style="float: right;" mat-raised-button color="warn" (click)="onClickgoBack()">Go to All
              Transactions</button>
            <!-- <button style="float: right;margin-right:10px" mat-raised-button color="warn" (click)="export()">Download to
              excel</button> -->
          </div>
        </div>
        <div class="row" style="margin-top: 5px">
          <div class="form-group col-md-12">
            <div class="transaction-history" style="position: relative; overflow: auto">
              <div style="width: 225em; position: relative">
                <div *ngIf="isAllTransaction">
                  <div class="table-container" *ngIf="
                    responsedata &&
                    responsedata.listTransactionHistory &&
                    transCols.length > 0 &&
                    displayedColumns.length > 0
                  " class="mat-elevation-z2">
                    <app-datatable #table mat-table [data]="dataSource" [cols]="transCols" class="keeperz-table-v1"
                      matSort [showPaginator]="true" [pageSize]="10" [showPageSizeOptions]="true" [length]="totalRows"
                      styleClass="v3" (pageEvent)="onPageEvent($event)" [paginaterAlignLeft]="true">
                    </app-datatable>
                  </div>
                </div>
                <div *ngIf="isIncidents">
                  <app-reversal-incident-list [incidentData]="incidentList"></app-reversal-incident-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isSuccess" class="alert alert-success  alert-dismissible fade show" style="z-index: 999999;
    display: flex;
    position: fixed;
    margin: 25% 8%;
    width: 61%;
    place-content: center;top:30px">
    <mat-icon>check_circle_outline</mat-icon>
    <div style="width: 10px;"></div>
    {{message}}
    <button type="button" class="close" (click)="onSuccessClose()" data-dismiss="alert">&times;</button>
</div>