<div class="mt-5">
  <div class="row justify-content-left">
    <div class="col-xl-12">
      <form *ngIf="form && form.controls" [formGroup]="form">
        <div class="card mb-4 mb-3">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <button [disabled]="isSalesRole" *ngIf="!editMode" (click)="onClickEdit()"
                style="background-color: #fff !important;color: #FF3333;border: 0px;" class="btn btn-primary"
                type="button">
                <mat-icon>drive_file_rename_outline</mat-icon>
              </button>
            </div>
            <!-- <div accessControl role="Sales" style="text-align: right; cursor: pointer" (click)="onClickEdit()"
              *ngIf="!editMode">
              <mat-icon>edit</mat-icon>
            </div> -->
            <div *ngIf="editMode">
              <div class="card mb-4 mb-3">
                <div class="card-body">
                  <div class="row">
                    <h6 class="mt-4 mb-3 col-md-12">Subscription</h6>
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="contractType">Contract Type</label>
                        <select class="form-control" id="contractType" formControlName="contracttype">
                          <option value="" selected>
                            Choose Contract Type
                          </option>
                          <option *ngFor="let item of contractTypeList" [value]="item.textName">
                            {{ item.textName }}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="
                          form.controls.contracttype.errors &&
                          form.controls.contracttype.touched
                        " class="validation-error-label">
                        <div *ngIf="form.controls.contracttype.errors.required" translate>
                          Contract Type is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="sDate">Contract Start Date</label>
                        <div class="col-md-6 p-0">
                          <input id="sDate" class="form-control mr-2" formControlName="contractStartDate"
                            placeholder="Enter start date" [matDatepicker]="startDate" (click)="startDate.open()"
                            readonly />
                          <mat-datepicker #startDate></mat-datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="contractType">Billing Type</label>
                        <select class="form-control" id="billingType" formControlName="billingtype">
                          <option value="" selected>Choose Billing Type</option>
                          <option *ngFor="let item of restaurantList.billingType" [value]="item.billingType">
                            {{ item.billingType }}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="
                          form.controls.billingtype.errors &&
                          form.controls.billingtype.touched
                        " class="validation-error-label">
                        <div *ngIf="form.controls.billingtype.errors.required" translate>
                          Billing Type is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="contractperiod">Contract Period</label>
                        <select class="form-control" formControlName="contractperiod" id="contractperiod">
                          <option value="" selected>
                            Choose Contract Period
                          </option>
                          <option *ngFor="let item of contractPeriod" [value]="item.textId">
                            {{ item.textName }}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="
                          form.controls.contractperiod.errors &&
                          form.controls.contractperiod.touched
                        " class="validation-error-label">
                        <div *ngIf="form.controls.contractperiod.errors.required" translate>
                          Contract Period is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row mt-4">
                    <h6 class="mt-4 mb-3 col-md-12">Transaction Charges</h6>
                    <div class="form-group col-md-12">
                      <div class="d-flex align-items-center">
                        <label class="mb-1 col-md-2" for="inputstandard">Standard (%)</label>
                        <input class="form-control col-md-3" numbers id="inputstandard" type="text"
                          formControlName="standard" placeholder="$" />
                        <label class="col-md-1 mb-1 p-0 justify-content-center" for="disAmt">on</label>
                        <span>{{
                          getStandardChargesType(form.value.billingtype)
                          }}</span>
                      </div>
                      <div *ngIf="
                          form.controls.standard.errors &&
                          form.controls.standard.touched
                        " class="validation-error-label col-md-12 ml-3">
                        <div *ngIf="form.controls.standard.errors.required" translate>
                          Standard Charge is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="d-flex align-items-center">
                        <label class="mb-1 col-md-2" for="inputPremium">Premium (%)</label>
                        <input class="form-control col-md-3" numbers id="inputPremium" type="text"
                          formControlName="premium" placeholder="$" />
                        <label class="col-md-1 mb-1 p-0 justify-content-center" for="disPremAmt">on</label>
                        <span>{{
                          getPremiumChargesType(form.value.billingtype)
                          }}</span>
                      </div>
                      <div *ngIf="
                          form.controls.premium.errors &&
                          form.controls.premium.touched
                        " class="validation-error-label col-md-12 ml-3">
                        <div *ngIf="form.controls.premium.errors.required" translate>
                          premium Charge is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="d-flex align-items-center">
                        <label class="mb-1 col-md-2" for="inputSpecial">Special (%)</label>
                        <input class="form-control col-md-3" numbers id="inputSpecial" type="text"
                          formControlName="special" placeholder="$" />
                        <label class="col-md-1 mb-1 p-0 justify-content-center" for="disSpecAmt">on</label>
                        <select class="form-control col-md-3 normal" id="Designation"
                          formControlName="contractChargeType">
                          <option value="0" disabled selected>Select Contract Charge Type</option>
                          <option *ngFor="let contractType of contractChargeOnTypeList" [value]="contractType.Id">
                            {{contractType.Type}}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="
                          form.controls.special.errors &&
                          form.controls.special.touched
                        " class="validation-error-label col-md-12 ml-3">
                        <div *ngIf="form.controls.special.errors.required" translate>
                          special Charge is required
                        </div>
                      </div>
                    </div>
                    <!--                                                <div class="form-group col-md-4">-->
                    <!--                                                    <label class="small mb-1" for="premium">Premium (%)</label>-->
                    <!--                                                    <input class="form-control" numbers id="premium" type="text"-->

                    <!--                                                           formControlName="premium" placeholder="Enter the Premium %">-->
                    <!--                                                    <div *ngIf="form.controls.premium.errors && form.controls.premium.touched"-->
                    <!--                                                         class="validation-error-label">-->
                    <!--                                                        <div *ngIf="form.controls.premium.errors.required" translate>-->
                    <!--                                                            Premium Charge is required-->
                    <!--                                                        </div>-->
                    <!--                                                    </div>-->
                    <!--                                                </div>-->
                  </div>
                </div>
              </div>

              <div class="card mb-4" style="margin-top: 1.5rem !important">
                <div class="card-header" style="
                    font-weight: 500 !important;
                    background-color: #ffffff;
                    color: #0b0535 !important;
                    border-bottom: 0px !important;
                  ">
                  Features
                </div>
                <div class="card-body">
                  <div class="row col-md-12">
                    <div class="col-md-6">
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="exposure" name="exposure">
                          Exposure
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="loyalty" name="loyalty">
                          Loyalty
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="custacquisition" name="custacquisition">
                          Customer Acquisition
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="revbooster" name="revbooster">
                          Revenue Booster
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="inapp" name="inapp">In-App ads
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-row">
                        <label class="medium font-weight-600 mb-1" for="Designation">Recommendation</label>
                        <select class="form-control normal" id="Designation" formControlName="Recommendation">
                          <option value="0" disabled selected>Select Recommendation</option>
                          <option *ngFor="let recommendation of recommendationList"
                            [value]="recommendation.recommendationLevelId">
                            {{recommendation.recommendationLevelName}}
                          </option>
                        </select>
                        <!-- <small class="form-text text-muted mb-3" *ngIf="hasError('designationId', 'required')">
                            Recommendation is Required.</small> -->
                      </div>
                      <div class="form-row mt-4">
                        <mat-checkbox class="example-margin" formControlName="paymentEnable" name="paymentEnable">
                          Payment Enabled
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-4" style="margin-top: 1.5rem !important">
                <div class="card-body">
                  <div class="form-row p-0">
                    <h5 class="col-md-6" style="margin-bottom: 2rem;">Do you want to enable the Exclude Amount?</h5>
                    <mat-slide-toggle class="example-margin" formControlName="isExcludeAmount">
                    </mat-slide-toggle>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="small mb-1" for="premium">Customer Id(Zoho)</label>
                      <input class="form-control col-md-6" id="CustomerId" type="text" formControlName="customerId" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="">Approved Date</label>
                      <input id="sDate" class="form-control mr-2" formControlName="approveDate"
                        placeholder="Enter start date" [matDatepicker]="approveDate" (click)="approveDate.open()"
                        readonly />
                      <mat-datepicker #approveDate></mat-datepicker>
                    </div>
                    <div class="form-group col-md-6" style="display: flex; place-content: flex-end">
                      <div class="form-group col-md-12" style="float: right">

                        <div class="form-row" style="padding-left: 18vw">
                          <mat-checkbox class="example-margin" formControlName="isVerifiedCon">Verified Contract
                          </mat-checkbox>
                        </div>
                        <div class="form-row" style="padding-left: 18vw">
                          <mat-checkbox class="example-margin" formControlName="isVerifiedLicense">Verified License
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="small mb-1" for="premium">Approved By</label>
                      <input class="form-control col-md-6" id="approveBy" type="text" formControlName="approveBy" />
                    </div>
                  </div>

                </div>
              </div>

              <div>
                <button type="button" [disabled]="!form.valid" class="btn btn-submit btn-lg" (click)="onClickSave()">
                  Save
                </button>
              </div>
            </div>
            <div *ngIf="!editMode">
              <div class="card mb-4 mb-3">
                <div class="card-body">
                  <div class="row">
                    <h6 class="mt-4 mb-3 col-md-12">Subscription</h6>
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="contractType">Contract Type</label>
                        <span>{{
                          restListItem?.subscriptionDetails?.contractType
                          }}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="sDate">Contract Start Date</label>
                        <div class="col-md-8 p-0">
                          <span>{{
                            restListItem?.subscriptionDetails?.contractStartDate
                            }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="contractType">Billing Type</label>
                        <span>{{
                          restListItem?.subscriptionDetails?.billingType
                          }}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="d-flex align-items-center">
                        <label class="col-md-6 font-weight-bold" for="contractperiod">Contract Period</label>
                        <span>{{
                          getContractPeriodTextName(
                          restListItem?.subscriptionDetails?.contractPerriod
                          )
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-row mt-4">
                    <h6 class="mt-4 mb-3 col-md-12">Transaction Charges</h6>
                    <div class="form-group col-md-12">
                      <div class="d-flex align-items-center">
                        <label class="mb-1 col-md-2" for="inputstandard">Standard (%)</label>
                        <span>{{
                          restListItem?.subscriptionDetails
                          ?.contractChargesStandard
                          }}</span>
                        <label class="col-md-1 mb-1 p-0 justify-content-center" for="disAmt">on</label>
                        <span>{{
                          restListItem?.subscriptionDetails
                          ?.contractChargesStandardType
                          }}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="d-flex align-items-center">
                        <label class="mb-1 col-md-2" for="inputPremium">Premium (%)</label>
                        <span>{{
                          restListItem?.subscriptionDetails
                          ?.contractChargesPremium
                          }}</span>
                        <label class="col-md-1 mb-1 p-0 justify-content-center" for="disPremAmt">on</label>
                        <span>{{
                          restListItem?.subscriptionDetails
                          ?.contractChargesPremiumType
                          }}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="d-flex align-items-center">
                        <label class="mb-1 col-md-2" for="inputPremium">Special (%)</label>
                        <span>{{
                          restListItem?.subscriptionDetails
                          ?.contractChargesSpecial
                          }}</span>
                        <label class="col-md-1 mb-1 p-0 justify-content-center" for="disPremAmt">on</label>
                        <span>{{
                          restListItem?.subscriptionDetails
                          ?.contractChargesSpecialType
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-4" style="margin-top: 1.5rem !important">
                <div class="card-header" style="
                    font-weight: 500 !important;
                    background-color: #ffffff;
                    color: #0b0535 !important;
                    border-bottom: 0px !important;
                  ">
                  Features
                </div>
                <div class="card-body">
                  <div class="row col-md-12">
                    <div class="col-md-6">
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="exposure" name="exposure">
                          Exposure
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="loyalty" name="loyalty">
                          Loyalty
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="custacquisition" name="custacquisition">
                          Customer Acquisition
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="revbooster" name="revbooster">
                          Revenue Booster
                        </mat-checkbox>
                      </div>
                      <div class="form-row">
                        <mat-checkbox class="example-margin" formControlName="inapp" name="inapp">In-App ads
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-row">
                        <label class="medium font-weight-600 mb-1" for="Designation">Recommendation</label>
                        <select class="form-control normal" id="Designation" formControlName="Recommendation">
                          <option value="0" disabled selected>--Select Recommendation--</option>
                          <option *ngFor="let recommendation of recommendationList"
                            [value]="recommendation.recommendationLevelId">
                            {{recommendation.recommendationLevelName}}
                          </option>
                        </select>
                        <!-- <small class="form-text text-muted mb-3" *ngIf="hasError('designationId', 'required')">
                            Recommendation is Required.</small> -->
                      </div>
                      <div class="form-row mt-4">
                        <mat-checkbox class="example-margin" formControlName="paymentEnable" name="paymentEnable">
                          Payment Enabled
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ''

              <div class="card mb-4" style="margin-top: 1.5rem !important">
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="">Customer Id(Zoho)</label>
                      <span>{{
                        restListItem?.subscriptionDetails?.CustomerId
                        }}</span>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="">Approved Date</label>
                      <span>{{
                        restListItem?.subscriptionDetails?.approvedOn
                        }}</span>
                    </div>
                    <div class="form-group col-md-6" style="display: flex; place-content: flex-end">
                      <div class="form-group col-md-12" style="float: right">
                        <div class="form-row" style="padding-left: 14vw">
                          <mat-checkbox class="example-margin" formControlName="isExcludeAmount">Exclude Amount
                          </mat-checkbox>
                        </div>
                        <div class="form-row" style="padding-left: 14vw">
                          <mat-checkbox class="example-margin" formControlName="isVerifiedCon">Verified Contract
                          </mat-checkbox>
                        </div>
                        <div class="form-row" style="padding-left: 14vw">
                          <mat-checkbox class="example-margin" formControlName="isVerifiedLicense">Verified License
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="premium">Approved By</label>
                      <span>{{
                        restListItem?.subscriptionDetails?.updatedBy
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>