<form [formGroup]="reversaltxnsForm">

    <div *ngIf="!reversaltxnsPreview" id="walletFields" class="container">
        <!-- <div class="row">
            <mat-card class="mat-card-padding">
                <mat-card-header>
                    <mat-card-subtitle class="d-flex align-items-center">
                        <b>Mobile number</b> :
                        {{ data.mobileNo }}
                    </mat-card-subtitle>
                    <mat-card-subtitle> <span class="name"><b>User name</b> :
                            {{ data.firstName }}
                            {{ data.lastName }}</span>
                    </mat-card-subtitle>
                    <mat-card-subtitle><b>Current balence</b> :
                        {{ currentWalletBalence }}
                    </mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div> -->
        <div style="display: flex;place-content: flex-end;">
            <mat-icon class="button-close" data-toggle="tooltip" data-placement="top" title="Close" style="color: #e01515;
            cursor: pointer;
            border: 1px solid red !important;
            border-radius: 15px;
            font-size: 16px;
            display: flex;
            place-content: center;
            align-items: center;margin: 10px 0px;" (click)="Onclose()">close</mat-icon>
        </div>
        <div class="row">

            <mat-card class="mat-card-padding">
                <mat-card-title>{{txnsText}}</mat-card-title>
                <mat-form-field class="col-md-12" appearance="fill">
                    <mat-label>User Mobile Number</mat-label>
                    <input type="tel" (keydown)="check($event)" (keyup)="validateMobileNumber()" matInput
                        disabled="true" placeholder="Mobile Number" maxlength="12" formControlName="mobileNumber"
                        required autocomplete="off">
                </mat-form-field>
                <!-- <mat-form-field class="col-md-12" appearance="fill">
                    <mat-label>Bill Amount</mat-label>
                    <mat-select  formControlName="topupReason" autocomplete="off">
                        <mat-option *ngFor="let reason of topupReason" [value]="reason.id">
                            {{reason.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <mat-form-field class="col-md-12" appearance="fill">
                    <mat-label>Bill Amount</mat-label>
                    <input type="tel" (keydown)="check($event)" matInput placeholder="Bill Amount" maxlength="10"
                        formControlName="billAmount" autocomplete="off">
                </mat-form-field>
                <mat-form-field class="col-md-12" appearance="fill">
                    <mat-label>Exclusion Amount</mat-label>
                    <input type="tel" (keydown)="check($event)" matInput placeholder="Bill Amount" maxlength="10"
                        formControlName="exclusionAmount" autocomplete="off">
                </mat-form-field>
                <mat-form-field class="col-md-12" *ngIf="reversaltxnsForm.controls.coupenType !== undefined"
                    appearance="fill">
                    <mat-label>Choose Coupon Type</mat-label>
                    <mat-select formControlName="coupenType">
                        <mat-option *ngFor="let coupen of CoupenTypeList" [value]="coupen.couponName">
                            {{coupen.couponName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card>
        </div>
        <!-- <div class="row">
            <mat-card class="mat-card-padding">
                <mat-card-subtitle class="approval-section">
                    Approval Section
                </mat-card-subtitle>
                <mat-form-field  class="col-md-12" appearance="fill">
                    <mat-label>Requested By</mat-label>
                    <input matInput placeholder="requested by" formControlName="requestedBy" required autocomplete="off">
                </mat-form-field>
                <div class="row col-md-6" style="float: right;">
                    <button mat-raised-button color="warn" [disabled]="isApprovalCodeDisabled"  (click)="onClickRequestCode()">Request Approval Code</button>
                </div>
                <mat-form-field *ngIf="isExistApprovalCode" class="col-md-12" appearance="fill">
                    <mat-label>Enter Approval Code</mat-label>
                    <input matInput placeholder="approval code" formControlName="approvalCode" required autocomplete="off">
                </mat-form-field>
            </mat-card>
        </div> -->
        <div class="row col-md-4 float-right mt-2">
            <button [disabled]="!reversaltxnsForm.valid" mat-raised-button color="warn"
                (click)="onClickSubmit()">Create</button>
        </div>
    </div>

    <div *ngIf="reversaltxnsPreview">
        <div style="display: flex;place-content: flex-end;">
            <mat-icon class="button-close" data-toggle="tooltip" data-placement="top" title="Close" style="color: #e01515;
            cursor: pointer;
            border: 1px solid red !important;
            border-radius: 15px;
            font-size: 16px;
            display: flex;
            place-content: center;
            align-items: center;margin: 10px 0px;" (click)="Onclose()">close</mat-icon>
        </div>
        <div class="row">

            <mat-card class="mat-card-padding">
                <mat-card-header>
                    <mat-card-subtitle class="d-flex align-items-center">
                        <b>User Mobile number</b> :
                        {{ reversaltxnsForm.value.mobileNumber }}
                    </mat-card-subtitle>
                    <mat-card-subtitle> <span class="name"><b>Bill Amount</b> :
                            AED {{ reversaltxnsForm.value.billAmount }} </span>
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="reversaltxnsForm.value.exclusionAmount !== ''"><b>Exclusion Amount</b> :
                        AED {{ reversaltxnsForm.value.exclusionAmount }}
                    </mat-card-subtitle>
                    <mat-card-subtitle
                        *ngIf="reversaltxnsForm.value.coupenType !== '' && reversaltxnsForm.value.coupenType !== undefined">
                        <b>Coupon Type </b> :
                        {{ reversaltxnsForm.value.coupenType }}
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-form-field class="col-md-12 p-0" appearance="fill">
                    <mat-label>Select Admin</mat-label>
                    <mat-select (selectionChange)="onChange($event)" formControlName="PhoneNumber" autocomplete="off">
                        <mat-option *ngFor="let adminNum of adminMobileNumList"
                            [value]="adminNum.adminId">
                            {{adminNum.adminName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field class="col-md-12 p-0" appearance="fill">
                    <mat-label>Enter Admin Mobile Number</mat-label>
                    <input matInput type="tel" (keyup)="validatePhoneNumber()" (keydown)="check($event)"  maxlength="12" placeholder="PhoneNumber" formControlName="PhoneNumber" required autocomplete="off">
                </mat-form-field> -->
                <div class="col-md-12 p-0 mb-2" style="float: right;">
                    <button class="fixed-width" mat-raised-button color="warn" [disabled]="!requestOTP"
                        (click)="onClickRequestCode()">Request OTP</button>
                </div>
                <mat-form-field class="col-md-12 p-0" appearance="fill">
                    <mat-label>Enter OTP</mat-label>
                    <input matInput type="tel" (keydown)="check($event)" (keyup)="validateOTP()" [disabled]="!isExistApprovalCode"
                        maxlength="10" placeholder="Enter OTP" formControlName="OTPCode" required autocomplete="off">
                </mat-form-field>
                <div class="col-md-12 p-0" style="float: right;">
                    <button class="fixed-width" mat-raised-button color="warn"
                    [disabled]="!showVerify" (click)="onClickVerify()">Verify</button>
                </div>
            </mat-card>
        </div>
    </div>
</form>