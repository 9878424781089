<div class="p-2" *ngIf="data" style="width: 45em;">
    <div class="d-flex justify-content-between dialog-close">
        <h5 class="pl-4 ml-2 font-weight-bold">Preview</h5>
        <i class="material-icons" (click)="dialogRef.close()">close</i>
    </div>
    <app-details-list-card [dataList]="data"></app-details-list-card>
    <div class="d-flex justify-content-end dialog-close mt-5">
        <div class="d-flex justify-content-end">
            <button class="btn red pr-4 pl-4 pt-2 pb-2 mr-3"
                    (click)="dialogRef.close(true)" type="button">Update</button>
            <button (click)="dialogRef.close()" class="btn inactive pr-4 pl-4 pt-2 pb-2" type="button">Cancel</button>
        </div>
    </div>
</div>
