import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { ColsModel, ColTypeModel } from './datatable.model';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data = [];
  @Input() cols: ColsModel[] = [];
  @Input() styleClass?: 'v1' | 'v2' | 'v3' = 'v1';
  @Input() showSearch = true;
  @Input() showEdit = false;
  @Input() showShadow = true;
  @Input() showPaginator = true;
  @Input() pageSize = 10;
  @Input() currentPage;
  @Input() length: number;
  @Input() pageIndex: number;
  @Input() showPageSizeOptions: boolean;
  @Input() isLoading: boolean;
  @Input() paginaterAlignLeft: boolean;

  @Output() onRowClicked = new EventEmitter<any>();
  @Output() onRowEditClicked = new EventEmitter<any>();
  @Output() onRowTxnsClicked = new EventEmitter<any>();
  @Output() onActionToggle = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<any>();
  @Output() pageEvent = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<any>();
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  sort;

  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort && this.data.length > 0) {
      this.dataSource.sort = this.sort;
    }
  }

  displayedColumns: string[] = [];
  searchCtrl = new FormControl(null);
  colTypeModel = ColTypeModel;

  constructor() { }

  ngOnInit(): void {
    if (this.data && this.data.length > 0) {
      this.dataSource = new MatTableDataSource(this.data);
      this.displayedColumns = this.cols.map((c) => c.column);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.dataSource = new MatTableDataSource(this.data);
      if (this.paginator) {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = this.length || this.data.length;
      }
    }
  }

  onClickEdit(): void {
    this.editClicked.emit(true);
  }

  // tslint:disable-next-line:typedef
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  checkForNan(row: any, col: any): boolean {
    return row && row[col.column] && isNaN(Number(row[col.column]));
  }

  // tslint:disable-next-line:typedef
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filterValue', filterValue);
    this.dataSource.filter = filterValue;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }

    // const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPageEvent(e) {
    this.pageEvent.emit(e);
  }

  // editRowTxnsClicked(column, selectedRow){
  //   this.onRowTxnsClicked.emit({ col: column, row: selectedRow })
  // }

  editRowEditClicked(column, selectedRow) {
    this.onRowEditClicked.emit({ col: column, row: selectedRow })
  }
}
