import pdfMake from "pdfmake/build/pdfmake";
import { MatTableDataSource } from "@angular/material/table";
import {
  ColsModel,
  ColTypeModel,
} from "src/app/shared/datatable/datatable.model";
import { ListTransactionInvoicesModel } from "./models/wallet-transaction-details.model";
import { WalletService } from "./services/wallet.service";
import * as moment from "moment";
import { ReportIncidentDialogComponent, ReportIncidentDialogModel } from "./report-incident-dialog/report-incident-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Router } from '@angular/router';
export abstract class WalletBaseComponent {
  public messageSubject$ = new Subject();
  public emitSuccessText(val) {
    this.messageSubject$.next(val);
    //this.approvalFeatureText$.complete();
  }

  public getSuccessText() {
    return this.messageSubject$.asObservable();
  }
  datewiseList: any[] = [];
  startDate = "";
  endDate = "";
  minDate: Date;
  maxDate: Date;
  isCustom = false;
  minDateTo: Date;
  maxDateTo: Date;
  responsedata: any;

  transCols: ColsModel[] = [
    /** Transaction Details */
    {
      column: 'report',
      title: 'Report',
      type: ColTypeModel.BUTTON,
      btnClass: 'btn btn-sm red',
      btnText: 'Report',
      command: (res) => this.reportClicked(res),
    },
    {
      column: "customerId",
      title: "Customer Id",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionNo",
      title: "Transaction No.",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionType",
      title: "Transaction Type.",
      type: ColTypeModel.TEXT,
    },
    {
      column: "restaurantName",
      title: "Merchant Name",
      type: ColTypeModel.TEXT,
    },
    { column: "inviterId", title: "Inviter ID", type: ColTypeModel.TEXT },
    {
      column: 'userId',
      title: 'User ID',
      type: ColTypeModel.LINKTEXT,
      command: (res) => this.redirectToUserDetails(res),
    },
    ///{ column: "userId", title: "User ID", type: ColTypeModel.TEXT },
    { column: "transactionDate", title: "Date", type: ColTypeModel.TEXT },
    { column: "time", title: "Time", type: ColTypeModel.TEXT },
    { column: "voucherType", title: "Voucher Type", type: ColTypeModel.TEXT },
    {
      column: "billAmount",
      title: "Bill Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "exclusionAmount",
      title: "Exclusion Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    /** User Discount/Redemption Calculation */
    { column: "kPINUsed", title: "Redeemed By", type: ColTypeModel.TEXT },
    {
      column: "discountPercentage",
      title: "Discount Applied(%)",
      type: ColTypeModel.TEXT,
    },

    {
      column: "discountAmount",
      title: "Discount Availed(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "billPaidAmount",
      title: "Final Bill Amount(AED)",
      type: ColTypeModel.TEXT,
    },
    /** Payment Details */
    {
      column: "paymentMethod",
      title: "Payment Method",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentReceivedBy",
      title: "Received by",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentStatus",
      title: "Payment Status",
      type: ColTypeModel.TEXT,
    },
    { column: "paymentRefCode", title: "Ref.Code", type: ColTypeModel.TEXT },
    {
      column: "paymentProvider",
      title: "Payment Provider Id",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentFeesPercentage",
      title: "P/G Fees(%)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "paymentFeesValues",
      title: "P/G Fees(Amount)",
      type: ColTypeModel.TEXT,
    },
    /** Keeperz Fees Calculation */
    { column: "featureType", title: "Transaction Type", type: ColTypeModel.TEXT },
    {
      column: "transactionPercentage",
      title: "Transaction Fees(%)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "transactionCharges",
      title: "Transaction Fees(AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "inviterCashBackValue",
      title: "Inviter Cashback(AED)",
      type: ColTypeModel.TEXT,
    },
    /** User Rewards */
    {
      column: "redeemptionKoinz",
      title: "Redemption Koinz",
      type: ColTypeModel.TEXT,
    },
    {
      column: "creditValue",
      title: "Redemption Cashback (AED)",
      type: ColTypeModel.TEXT,
    },
    {
      column: "inviterCashBackKoinz",
      title: "Inviter Cashback Koinz",
      type: ColTypeModel.TEXT,
    },
    { column: "paymentDoneBy", title: "Payment Done By ", type: ColTypeModel.TEXT },
    { column: "paymentDoneByMobileNo", title: "Mobile No", type: ColTypeModel.TEXT }
  ];

  invoiceCols: ColsModel[] = [
    { column: "invoiceNo", title: "Invoice No", type: ColTypeModel.TEXT },
    { column: "invoiceDate", title: "Invoice Date", type: ColTypeModel.TEXT },
    { column: "description", title: "Description", type: ColTypeModel.TEXT },
    {
      column: "billAmount",
      title: "Bill Amount",
      type: ColTypeModel.MONEY,
      currencyType: "AED",
      format: "1.0-0",
    },
    {
      column: "transactionCharge",
      title: "Transaction Charges",
      type: ColTypeModel.MONEY,
      currencyType: "AED",
      format: "1.0-0",
    },
    {
      column: "netAmount",
      title: "Net Amount",
      type: ColTypeModel.MONEY,
      currencyType: "AED",
      format: "1.0-0",
    },
    { column: "status", title: "Status", type: ColTypeModel.TEXT },
    
    {
      column: "action",
      title: "",
      type: ColTypeModel.BUTTON,
      btnClass: "btn btn-sm red pr-2 pl-2",
      btnText: "PDF",
      command: (res: ListTransactionInvoicesModel) => this.generatePdf(res),
    },
  ];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  isSuccess: any;
  message: any;
  constructor(protected walletService: WalletService,
    protected dialog: MatDialog,
    protected router : Router) { }

  abstract loadData(fromDate, toDate);

  async generatePdf(pdfRes: ListTransactionInvoicesModel) {
    this.loadData("2021-01-01", "2022-12-31").subscribe(async (response) => {
      const TransactionList = [];
      TransactionList.push([
        {
          text: "Trans No",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "Date",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "Voucher",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "Bill Amount",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "Discount",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "Transaction Charges",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "Transaction Percentage",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
        {
          text: "KPIN",
          border: [false, true, false, true],
          alignment: "left",
          fillColor: "#eaf2f5",
          margin: [0, 5, 0, 5],
          fontSize: 8,
          bold: true,
        },
      ]);
      if (response.data) {
        if (response.data.listTransactionHistory) {
          response.data.listTransactionHistory.forEach((item) => {
            TransactionList.push([
              {
                text: item.transactionNo,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.transactionDate + " " + item.time,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.voucherType,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.billAmount,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.discountAmount,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.transactionCharges,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.transactionPercentage,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
              {
                text: item.kPINUsed,
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
                alignment: "left",
                fontSize: 8,
              },
            ]);
          });
        }
      }
      const res = await this.getBase64ImageFromURL(
        "./../../assets/images/invoice-image.png"
      );
      const foot = await this.getBase64ImageFromURL(
        "./../../assets/images/footers.png"
      );

      const docDefinition = {
        header: {
          columns: [
            {
              image: res,
              width: 600,
            },
          ],
        },
        footer: {
          fillColor: "#eaf2f5",
          columns: [
            {
              image: foot,
              width: 599,
              height: 40,
            },
          ],
        },

        content: [
          "\n\n",
          "\n\n",
          "\n\n",
          {
            columns: [
              [
                {
                  stack: [
                    {
                      columns: [
                        {
                          text: "",
                          color: "#aaaaab",
                          bold: true,
                          width: 360,
                          fontSize: 12,
                          alignment: "left",
                        },
                        {
                          text: "Receipt No.",
                          color: "#aaaaab",
                          bold: true,
                          width: 70,
                          fontSize: 12,
                          alignment: "left",
                        },
                        {
                          text: pdfRes.invoiceNo,
                          bold: true,
                          color: "#333333",
                          fontSize: 12,
                          alignment: "left",
                          width: 70,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: "",
                          color: "#aaaaab",
                          bold: true,
                          width: 360,
                          fontSize: 12,
                          alignment: "left",
                        },
                        {
                          text: "Invoice Date",
                          color: "#aaaaab",
                          bold: true,
                          width: 70,
                          fontSize: 12,
                          alignment: "left",
                        },
                        {
                          text: pdfRes.invoiceDate,
                          bold: true,
                          color: "#333333",
                          fontSize: 12,
                          alignment: "left",
                          width: 70,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: "",
                          color: "#aaaaab",
                          bold: true,
                          width: 360,
                          fontSize: 12,
                          alignment: "left",
                        },
                        {
                          text: "Status",
                          color: "#aaaaab",
                          bold: true,
                          fontSize: 12,
                          alignment: "left",
                          width: 70,
                        },
                        {
                          text: pdfRes.status,
                          bold: true,
                          fontSize: 14,
                          alignment: "left",
                          // color: 'green',
                          width: 70,
                        },
                      ],
                    },
                  ],
                },
              ],
            ],
          },
          {
            columns: [
              {
                text: "To",
                color: "#aaaaab",
                bold: true,
                fontSize: 14,
                alignment: "left",
                margin: [0, 20, 0, 5],
              },
            ],
          },
          {
            columns: [
              {
                text: pdfRes.restaurantName,
                bold: true,
                color: "#333333",
                alignment: "left",
              },
            ],
          },
          {
            columns: [
              {
                text: pdfRes.restaurantLocation,
                style: "restaurantLocation",
              },
            ],
          },
          "\n\n",
          {
            width: "100%",
            alignment: "center",
            text: "Summary",
            bold: true,
            margin: [0, 10, 0, 10],
            fontSize: 15,
          },
          {
            layout: {
              defaultBorder: false,
              hLineWidth: () => {
                return 1;
              },
              vLineWidth: () => {
                return 1;
              },
              hLineColor: (i) => {
                if (i === 1 || i === 0) {
                  return "#bfdde8";
                }
                return "#eaeaea";
              },
              vLineColor: () => {
                return "#eaeaea";
              },
              hLineStyle: () => {
                return null;
              },
              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
              paddingLeft: () => {
                return 10;
              },
              paddingRight: () => {
                return 10;
              },
              paddingTop: () => {
                return 2;
              },
              paddingBottom: () => {
                return 2;
              },
              fillColor: () => {
                return "#fff";
              },
            },
            table: {
              headerRows: 1,
              widths: ["*", 80],
              body: [
                [
                  {
                    text: "Invoice No",
                    fillColor: "#eaf2f5",
                    border: [false, true, false, true],
                    margin: [0, 5, 0, 5],
                    textTransform: "uppercase",
                  },
                  {
                    text: "Bill Amount",
                    fillColor: "#eaf2f5",
                    border: [false, true, false, true],
                    margin: [0, 5, 0, 5],
                    textTransform: "uppercase",
                    alignment: "right",
                  },
                ],
                [
                  {
                    text: pdfRes.invoiceNo,
                    border: [false, false, false, true],
                    margin: [0, 5, 0, 5],
                    alignment: "left",
                  },
                  {
                    border: [false, false, false, true],
                    text: pdfRes.billAmount,
                    fillColor: "#f5f5f5",
                    alignment: "right",
                    margin: [0, 5, 0, 5],
                  },
                ],
              ],
            },
          },
          "\n",
          {
            layout: {
              defaultBorder: false,
              hLineWidth: () => {
                return 1;
              },
              vLineWidth: () => {
                return 1;
              },
              hLineColor: () => {
                return "#eaeaea";
              },
              vLineColor: () => {
                return "#eaeaea";
              },
              hLineStyle: () => {
                return null;
              },
              // vLineStyle: function () { return {dash: { length: 10, space: 4 }}; },
              paddingLeft: () => {
                return 10;
              },
              paddingRight: () => {
                return 10;
              },
              paddingTop: () => {
                return 3;
              },
              paddingBottom: () => {
                return 3;
              },
              fillColor: () => {
                return "#fff";
              },
            },
            table: {
              headerRows: 1,
              widths: ["*", 80],
              body: [
                [
                  {
                    text: "VAT",
                    border: [false, true, false, true],
                    alignment: "right",
                    margin: [0, 5, 0, 5],
                  },
                  {
                    border: [false, true, false, true],
                    text: pdfRes.transactionCharge,
                    alignment: "right",
                    fillColor: "#f5f5f5",
                    margin: [0, 5, 0, 5],
                  },
                ],
                [
                  {
                    text: "Net Amount",
                    border: [false, false, false, true],
                    alignment: "right",
                    margin: [0, 5, 0, 5],
                  },
                  {
                    text: pdfRes.netAmount,
                    border: [false, false, false, true],
                    fillColor: "#f5f5f5",
                    alignment: "right",
                    margin: [0, 5, 0, 5],
                  },
                ],
              ],
            },
          },
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",
          "\n\n",

          {
            text: "Description",
            style: "notesText",
          },
          {
            text: pdfRes.description,
            style: "notesText",
          },
          "\n\n",
          "\n\n",
          "\n\n",

          "\n\n",
          {
            width: "100%",
            alignment: "center",
            text: "Transaction",
            bold: true,
            margin: [0, 10, 0, 10],
            fontSize: 15,
          },
          "\n\n",
          {
            layout: {
              defaultBorder: false,
              hLineWidth: () => {
                return 1;
              },
              vLineWidth: () => {
                return 1;
              },
              hLineColor: () => {
                return "#eaeaea";
              },
              vLineColor: () => {
                return "#eaeaea";
              },
              hLineStyle: () => {
                return null;
              },
              // vLineStyle: function () { return {dash: { length: 10, space: 4 }}; },
              paddingLeft: () => {
                return 10;
              },
              paddingRight: () => {
                return 10;
              },
              paddingTop: () => {
                return 3;
              },
              paddingBottom: () => {
                return 3;
              },
              fillColor: () => {
                return "#fff";
              },
            },
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*", "*", "*", "*"],

              body: TransactionList,
            },
          },
        ],

        styles: {
          notesTitle: {
            fontSize: 10,
            bold: true,
            margin: [0, 50, 0, 3],
          },
          notesText: {
            fontSize: 10,
          },
        },
        defaultStyle: {
          columnGap: 20,
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download(
          pdfRes.invoiceNo + "_" + moment(new Date()).format("DDMMYYYYHHmm")
        );
    });
  }

  customSearch() {
    if (
      this.startDate != null &&
      this.endDate != null &&
      this.endDate >= this.startDate
    ) {
      this.ChartdaysDetails(
        moment(new Date(this.startDate)).format("YYYY-MM-DD"),
        moment(new Date(this.endDate)).format("YYYY-MM-DD")
      );
      this.isCustom = false;
      this.startDate = "";
      this.endDate = "";
    }
  }

  async ChartdaysDetails(fromDate, todate) {
    this.loadData(fromDate, todate).subscribe((response) => {
      this.responsedata = response.data;
    });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.setAttribute("Access-Control-Allow-Origin", "*");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  async changeDate(type) {
    let todate = null;
    let fromdate = null;
    this.isCustom = false;
    if (type === "1") {
      fromdate = "2021-01-01";
      todate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ).format("YYYY-MM-DD");
    }
    if (type === "2") {
      fromdate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        )
      ).format("YYYY-MM-DD");
      todate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ).format("YYYY-MM-DD");
    }
    if (type === "3") {
      fromdate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 14
        )
      ).format("YYYY-MM-DD");
      todate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    if (type === "4") {
      fromdate = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 30
        )
      ).format("YYYY-MM-DD");
      todate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    if (type === "5") {
      todate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      this.isCustom = true;
      this.startDate = "";
      this.endDate = "";
    }
    if (type !== "5") {
      this.ChartdaysDetails(fromdate, todate);
    }
  }

  async reportClicked(selectedRow) {
    console.log(selectedRow);
    const message = "Are you sure you want to report this transaction?";
    const dialogData = new ReportIncidentDialogModel('Confirm', message, selectedRow);
    const dialogRef = this.dialog.open(ReportIncidentDialogComponent, {
      width: '40%',
      data: dialogData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.isSucces) {
        this.isSuccess = res.isSucces;
        this.message = res.message;
        this.emitSuccessText({
          isSuccess: res.isSucces,
          message: res.message
        })
      }
    });
  }


  redirectToUserDetails(selectedrow) {
    this.router.navigate(['users', 'user-profile', selectedrow.userId, 'details']);
  }
}