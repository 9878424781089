import { Injectable } from "@angular/core";
import { RegistrationDetailsModel } from "../model/user.model";
import { environment } from "../../../environments/environment";
import { PremiumDiscountModel } from "src/app/modules/merchants/models/merchant-details.model";
import { MerchantModel } from "src/app/modules/merchants/models/merchants.model";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor() {}
  public merchantRegistrationId: number;
  public restaurantId: number;
  public branchId: string;
  public selectedRes: any;
  public premiumDiscount: PremiumDiscountModel;
  public premiumDiscountId: number;
  public loyaltyDiscountId: string;
  public selectedMerchant: MerchantModel;

  public role: boolean;

  set setRole(val: boolean) {
    this.role = val;
  }

  get getRole(): boolean {
    return this.role;
  }

  public logo: string;

  set setLogo(val: string) {
    this.logo = val;
  }

  get getLogo(): string {
    return this.users && this.users.restaurantList[0]
      ? environment.baseUrl +
          "/api/" +
          this.users.restaurantList[0].logoImageURL
      : "";
  }

  public banner: string;

  set setBanner(val: string) {
    this.banner = val;
  }

  get getBanner(): string {
    return this.banner;
  }

  public users: RegistrationDetailsModel;

  setUser(val) {
    localStorage.setItem("user", JSON.stringify(val));
    this.users = val;
  }

  getUser(): RegistrationDetailsModel {
    const userLocal = localStorage.getItem("user");
    if (userLocal) {
      this.users = JSON.parse(userLocal);
      return this.users;
    }
  }

  public dashboardDetails: any;

  set setDashboard(val: any) {
    this.dashboardDetails = val;
  }

  get getDashboard(): any {
    return this.dashboardDetails;
  }

  public token: string;

  set setToken(val: string) {
    localStorage.setItem("token", val);
    this.token = val;
  }

  get getToken(): string {
    this.token = localStorage.getItem("token");
    return this.token;
  }

  public sessionToken: string;

  set SetSession(val: string) {
    this.sessionToken = val;
  }

  get getSession(): string {
    return this.sessionToken;
  }

  public recoveryResponse: any;

  set setResponse(val: any) {
    this.recoveryResponse = val;
  }

  get getResponse(): any {
    return this.recoveryResponse;
  }

  public oktaid: any;

  set setOktaId(val: any) {
    this.oktaid = val;
  }

  get getOktaId(): any {
    return this.oktaid;
  }

  public restaurantList: any;

  set SetrestaurantList(val: any) {
    this.restaurantList = val;
  }

  get getrestaurantList(): any {
    return this.restaurantList;
  }

  public merchantDetailsList: any;

  set setMerchantDetails(val: any) {
    this.merchantDetailsList = val;
  }

  get getMerchantDetails(): any {
    return this.merchantDetailsList;
  }

  public standardList: any;

  set setStandardList(val: any) {
    this.standardList = val;
  }

  get getStandardList(): any {
    return this.standardList;
  }

  public merchantDetailsListTemp: any;

  set setMerchantDetailsTemp(val: any) {
    this.merchantDetailsListTemp = val;
  }

  get getMerchantDetailsTemp(): any {
    return this.merchantDetailsListTemp;
  }

  public itemDetails: any;

  set setitemDetails(val: any) {
    this.itemDetails = val;
  }

  get getitemDetails(): any {
    return this.itemDetails;
  }

  public userProfile: any;

  set SetProfile(val: any) {
    this.userProfile = val;
  }

  get getProfile(): any {
    return this.userProfile;
  }

  public error: string;

  set setError(val: string) {
    this.error = val;
  }

  get getError(): string {
    return this.error;
  }

  isSalesRole(){
    JSON.parse(localStorage.getItem('user')).role === 'Sales';
  }
}
