<div>
  <h6 class="header" *ngIf="config && config.header" [innerHTML]="config.header"></h6>
</div>

<div class="generate-pin-btn" *ngIf="displayPin">
  <button [disabled]="isSalesRole" mat-raised-button color="warn" *ngIf="pinExists !== undefined && !pinExists" (click)="generatePin()">
    GENERATE PIN
  </button>
  <div *ngIf="pinExists">
    <span>PIN : </span>
    <span style="
      background: lightcoral;
      color: white;
      padding: 6px;
      border-radius: 2px;
      margin-right: 5px;
    ">{{ pin }}
    </span>
    <span *ngIf="pinExists && remainingHours" style="padding: 6px; border-radius: 2px" class="blink_me">{{
      remainingHours
      }} left !!</span>
  </div>
</div>
<div class="badges d-flex flex-sm-row flex-column flex-wrap">
  <mat-card *ngFor="let det of data" (click)="onClick.emit(det)"
    [style.width]="config && config.width ? config.width : '10em'"
    [ngClass]="config && config.customClass ? config.customClass : ''">
    <mat-card-header>
      <mat-card-title>
        <p class="spend">{{ det?.value ? det?.value : 0 }}</p>
      </mat-card-title>
    </mat-card-header>
    <mat-card-subtitle><span class="title">{{ det?.bottomTitle }}</span></mat-card-subtitle>
    <ng-container *ngIf="det.isSend">
      <button mat-raised-button color="warn" (click)="onClickSend.emit(det)" class="badge badge-warn status-button">
       Send
      </button>
    </ng-container>
    <ng-container *ngIf="det.isCheckStatus">
      <button mat-raised-button color="warn" (click)="onClickCheckStatus.emit(det)" class="badge badge-warn status-button">
       Check Status
      </button>
    </ng-container>
  </mat-card>

</div>