import {Component, Input, OnInit} from '@angular/core';
import {ChartV2Config} from '../../charts/charts.component';

export interface ChartCardDetails {
  dataset: Chart.ChartDataSets[];
  labels?: string[];
  chartConfig?: ChartV2Config;
}

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() graphTitle: string;
  @Input() chartCardDetails: ChartCardDetails;

  constructor() { }

  ngOnInit(): void {
  }

}
