import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { RestaurentMasterModel } from './reastaurent-master.model';
import { AddPostModelRes } from '../../../modules/merchants/merchant-mod/merchant-profile-mod/models/add-employee.model';
import { MerchantDetailsDataModel } from '../../../modules/merchants/models/merchant-details.model';

@Injectable({
    providedIn: 'root'
})
export class RestaurantMasterService {
    restaurantMasterData: RestaurentMasterModel;
    dataUpdated = new EventEmitter<RestaurentMasterModel>();

    constructor(private apiService: ApiService) {
    }

    init() {
        this.apiService.RestaurantList().subscribe(res => {
            this.restaurantMasterData = this.setFilterData(res.data);
            this.dataUpdated.emit(res.data);
        });
    }

    setFilterData(resData: RestaurentMasterModel): RestaurentMasterModel {
        resData.restaurantServices = resData.restaurantServices.map(ser => {
            ser.item_id = ser.RestaurantServicesId;
            ser.item_text = ser.RestaurantServicesName;
            return ser;
        });
        resData.restaurantTypes = resData.restaurantTypes.map(ser => {
            ser.item_id = ser.RestaurantTypeId;
            ser.item_text = ser.RestaurantTypeName;
            return ser;
        });
        resData.cusineDetails = resData.cusineDetails.map(ser => {
            ser.item_id = ser.cusineId;
            ser.item_text = ser.cusineName;
            return ser;
        });
        resData.specialityDetails = resData.specialityDetails.map(ser => {
            ser.item_id = ser.specialityId;
            ser.item_text = ser.specialityName;
            return ser;
        });
        resData.ambienceDetails = resData.ambienceDetails.map(ser => {
            ser.item_id = ser.ambienceId;
            ser.item_text = ser.ambienceName;
            return ser;
        });
        resData.suiteDetails = resData.suiteDetails.map(ser => {
            ser.item_id = ser.suiteId;
            ser.item_text = ser.suiteName;
            return ser;
        });
        resData.itemTagDetails = resData.itemTagDetails.map(ser => {
            ser.item_id = ser.ItemTagId;
            ser.item_text = ser.ItemTagName;
            return ser;
        });
        resData.paymentDetails = resData.paymentDetails.map(ser => {
            ser.item_id = ser.paymentId;
            ser.item_text = ser.paymentName;
            return ser;
        });
        resData.facilityDetails = resData.facilityDetails.map(ser => {
            ser.item_id = ser.facilityId;
            ser.item_text = ser.facilityName;
            return ser;
        });
        return resData;
    }

    getCity(cityId) {
        if (this.restaurantMasterData) {
            const findObj = this.restaurantMasterData.cityDetails.find(c => c.cityId === cityId);
            return findObj && findObj.cityName ? findObj.cityName : cityId;
        }
    }

    getLocality(localityId) {
        if (this.restaurantMasterData) {
            const findObj = this.restaurantMasterData.localityDetails.find(c => c.localityId === localityId);
            return findObj && findObj.localityName ? findObj.localityName : localityId;
        }
    }

    getCountry(countryId) {
        if (this.restaurantMasterData && this.restaurantMasterData.countryDetails) {
            const findObj = this.restaurantMasterData.countryDetails.find(c => c.countryId === countryId);
            return findObj && findObj.countryName ? findObj.countryName : countryId;
        }
    }

    getTagName(tagId) {
        if (this.restaurantMasterData && this.restaurantMasterData.itemTagDetails) {
            const findObj = this.restaurantMasterData.itemTagDetails.find(c => c.ItemTagId === tagId);
            return findObj && findObj.ItemTagName ? findObj.ItemTagName : tagId;
        }
    }

    getItemCategoryName(catId) {
        if (this.restaurantMasterData && this.restaurantMasterData.itemCategoryDetails) {
            const findObj = this.restaurantMasterData.itemCategoryDetails.find(c => c.ItemCategoryId === catId);
            return findObj && findObj.ItemCategoryName ? findObj.ItemCategoryName : catId;
        }
    }


    getCategoryName(catId) {
        if (this.restaurantMasterData && this.restaurantMasterData.cusineDetails) {
            const findObj = this.restaurantMasterData.cusineDetails.find(c => c.cusineId === catId);
            return findObj && findObj.cusineName ? findObj.cusineName : catId;
        }
    }

}
