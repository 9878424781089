import {Injectable} from '@angular/core';
import {SharedService} from './shared.service';

@Injectable()
export class AuthServices {
    userData: Array<{}>;
    token: Number = 0;
    tokenDetails: String;

    constructor(public shared: SharedService) {
    }

    settoken() {
        this.token = new Date().getTime();
        localStorage.setItem('token', JSON.stringify(this.token));
    }

    validateUsertoken() {
        this.tokenDetails = localStorage.getItem('token');
        //let _token = JSON.parse
        if (this.tokenDetails != null) {
            return true;
        } else {
            return false;
        }

    }

    //it should be from backend
    validateUserDetails(user) {
        let _userList = this.userData;
        let users: any = this.shared.getUser;
        if (user.mobileNumber && user.password) {
            //if (user.mobileNumber.toLowerCase() == users.mobileNumber.toLowerCase() && user.password == users.password) {
            return true;
            //}
        }
    }

    logout() {
        localStorage.clear();
    }
}
