<div class="row" style="margin-top: 5px">
    <div class="form-group col-md-12">
        <button mat-icon-button color="warn" class="float-right" (click)="matDialogRef.close()" aria-label="Example icon-button with a heart icon">
            <mat-icon>close</mat-icon>
        </button>
        <div class="transaction-history" style="position: relative; overflow: auto">
            <div style="width: 225em; position: relative">
                <app-datatable #table mat-table [data]="datasource" [cols]="dataColumns" class="keeperz-table-v1"
                    matSort [showPaginator]="false" [pageSize]="10"
                    [showPageSizeOptions]="false" [length]="totalRows" styleClass="v3"
                    [paginaterAlignLeft]="false">
                </app-datatable>
            </div>
        </div>
    </div>
</div>






<!-- <div class="row" style="margin-top: 5px">
    <div class="form-group col-md-12">
        <div class="transaction-history" style="position: relative; overflow: auto">
            <div class="table-container" class="mat-elevation-z2">

            </div>
        </div>
    </div>
</div> -->